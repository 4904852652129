import BadgeIcon from '@mui/icons-material/Badge';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion, AccordionDetails, AccordionSummary, Button, MenuItem, TextField, Typography,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SaveUserDto, User, UserGender, useSaveUserMutation,
} from '../../../../../../graphql/index.ts';
import useReactiveUserConnected from '../../../../../../hooks/data/useReactiveUserConnected.ts';
import useValidateSaveUserDto from '../../../../../../hooks/data/validations/useValidateSaveUserDto.ts';
import HStack from '../../../../../ui-kit/layout/HStack.tsx';
import VStack from '../../../../../ui-kit/layout/VStack.tsx';

function createDto(user?: User): SaveUserDto {
  return {
    id: user?.id || 0,
    email: user?.email || '',
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    gender: user?.gender || UserGender.Mr,
    phoneNumber: user?.phoneNumber || '',
  };
}

interface Props {
  onLoading: (loading: boolean) => void,
}

const MyInformationAccordion = ({ onLoading }: Props) => {
  const { t } = useTranslation();
  const { user, refetchMe } = useReactiveUserConnected();
  const [ dto, setDto ] = useState<SaveUserDto>(createDto());
  const [ isPushing, setIsPushing ] = useState(false);
  const isDtoValid = useValidateSaveUserDto(dto);
  const canSave = useMemo(
    () => !isPushing && isDtoValid,
    [ isPushing, isDtoValid ],
  );
  const [ saveUser ] = useSaveUserMutation();

  const handleSave = async () => {
    if (!canSave) {
      return;
    }

    setIsPushing(true);

    await saveUser({ variables: { dto } });
    await refetchMe();

    setIsPushing(false);
  };

  useEffect(() => {
    setDto(user ? createDto(user) : createDto());
  }, [ user ]);

  useEffect(() => {
    onLoading(isPushing);
  }, [ isPushing ]);

  return (
    <Accordion sx={{ maxWidth: 800 }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <HStack gap={1} alignItems="center">
          <BadgeIcon />
          {t('my-parameters.my-profile.my-personal-information')}
        </HStack>
      </AccordionSummary>
      <AccordionDetails>
        <HStack className="form-container" gap={2}>
          <VStack className="input-container" flex={1}>
            <Typography fontWeight="bolder">
              {t('form.labels.lastname')}
              <span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              sx={{ width: '100%', mb: 2 }}
              value={dto.lastName}
              onChange={({ target: { value: lastName } }) => setDto({ ...dto, lastName })}
            />
          </VStack>

          <VStack className="input-container" flex={1}>
            <Typography fontWeight="bolder">
              {t('form.labels.firstname')}
              <span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              sx={{ width: '100%', mb: 2 }}
              value={dto.firstName}
              onChange={({ target: { value: firstName } }) => setDto({ ...dto, firstName })}
            />
          </VStack>
        </HStack>

        <HStack className="form-container" gap={2}>
          <VStack className="input-container" flex={1}>
            <Typography fontWeight="bolder">
              {t('form.labels.email')}
              <span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              sx={{ width: '100%', mb: 2 }}
              value={dto.email}
              onChange={({ target: { value: email } }) => setDto({ ...dto, email: email.trim() })}
            />
          </VStack>
        </HStack>

        <HStack className="form-container" gap={2}>
          <VStack className="input-container" flex={1}>
            <Typography fontWeight="bolder">
              {t('form.labels.phone-number')}
              <span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              sx={{ width: '100%', mb: 2 }}
              value={dto.phoneNumber}
              onChange={({ target: { value: phoneNumber } }) => setDto({ ...dto, phoneNumber: phoneNumber.trim() })}
            />
          </VStack>

          <VStack className="input-container" flex={1}>
            <Typography fontWeight="bolder">
              {t('form.labels.civility')}
              <span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextField
              select
              variant="outlined"
              size="small"
              sx={{ width: '100%', mb: 2 }}
              value={dto.gender}
              onChange={({ target: { value: gender } }) => setDto({ ...dto, gender: gender as UserGender })}
            >
              {
                [ UserGender.Mr, UserGender.Mrs, UserGender.Miss ].map((gender) => (
                  <MenuItem value={gender} key={gender}>{ gender }</MenuItem>
                ))
              }
            </TextField>
          </VStack>
        </HStack>

        <HStack justifyContent="flex-end">
          <Button color="primary" variant="outlined" onClick={handleSave} disabled={!canSave}>{t('buttons.save')}</Button>
        </HStack>
      </AccordionDetails>
    </Accordion>
  );
};

export default MyInformationAccordion;
