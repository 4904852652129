import { useReactiveVar } from '@apollo/client';
import { Typography } from '@mui/material';
import { useEffect } from 'react';
import { notificationsFeedVar, useMarkInAppNotificationsAsReadMutation } from '../../../../graphql';
import VStack from '../../../ui-kit/layout/VStack.tsx';
import AcceptedOfferNotification from './AcceptedOfferNotification.tsx';
import CreatedOfferNotification from './CreatedOfferNotification.tsx';
import DeclinedOfferNotification from './DeclinedOfferNotification.tsx';
import UpdatedOfferNotification from './UpdatedOfferNotification.tsx';

const NotificationFeed = () => {
  const notificationsFeed = useReactiveVar(notificationsFeedVar);
  const [ markNotificationsAsRead ] = useMarkInAppNotificationsAsReadMutation();

  useEffect(() => {
    markNotificationsAsRead();

    (document.querySelector('link[type="image/x-icon"]')! as HTMLLinkElement).href = '/assets/images/favicon.png';
  }, []);

  return (
    <>
      <Typography variant="h5" fontWeight="bold" mb={1} px={2}>Notifications</Typography>
      <VStack>
        {
          notificationsFeed.results.map((notification) => {
            if (notification.type === 'you_got_new_offer') {
              return <CreatedOfferNotification key={notification.id} notification={notification} />;
            }

            if (notification.type === 'your_offer_is_accepted') {
              return <AcceptedOfferNotification key={notification.id} notification={notification} />;
            }

            if (notification.type === 'your_offer_is_declined') {
              return <DeclinedOfferNotification key={notification.id} notification={notification} />;
            }

            if (notification.type === 'an_offer_has_been_updated') {
              return <UpdatedOfferNotification key={notification.id} notification={notification} />;
            }

            return <div key={notification.id} />;
          })
        }
      </VStack>
    </>
  );
};

export default NotificationFeed;
