import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { useMemo } from 'react';
import HStack from '../../../ui-kit/layout/HStack.tsx';
import './Pagination.scss';

interface Props {
  currentPage: number,
  totalPages: number,
  onNavigate: (page: number) => void,
}

const Pagination = ({ currentPage, totalPages, onNavigate }: Props) => {
  const pages = useMemo(() => {
    let pagesToShow: number[] = [];

    for (let i = currentPage; i >= 1 && i >= currentPage - 2; i--) {
      pagesToShow = [ i, ...pagesToShow ];
    }

    for (let i = currentPage; i <= totalPages && i <= currentPage + 2; i++) {
      pagesToShow = !pagesToShow.includes(i) ? [ ...pagesToShow, i ] : pagesToShow;
    }

    return pagesToShow;
  }, [ currentPage, totalPages ]);

  return (
    <HStack gap={1} justifyContent="center" mt={5} className="pagination">
      {
         totalPages > 3 && currentPage > 1 && (
           <>
             <HStack onClick={() => onNavigate(1)}>
               <KeyboardDoubleArrowLeftIcon />
             </HStack>
             <HStack onClick={() => onNavigate(currentPage - 1)}>
               <KeyboardArrowLeftIcon />
             </HStack>
           </>
         )
      }
      {
        pages.map((value) => (
          <HStack
            className={value === currentPage ? 'active' : ''}
            key={value}
            onClick={() => {
              if (value !== currentPage) {
                onNavigate(value);
              }
            }}
          >
            { value }
          </HStack>
        ))
      }
      {
        totalPages > 3 && currentPage < totalPages && (
          <>
            <HStack onClick={() => onNavigate(currentPage + 1)}>
              <KeyboardArrowRightIcon />
            </HStack>
            <HStack onClick={() => onNavigate(totalPages)}>
              <KeyboardDoubleArrowRightIcon />
            </HStack>
          </>
        )
      }
    </HStack>
  );
};

export default Pagination;
