import { Dialog } from '@mui/material';
import { useContext } from 'react';
import { OfflineDialogsContext } from '../../../../providers/OfflineDialogsContext.ts';
import VStack from '../../../../ui-kit/layout/VStack.tsx';
import ForgottenPasswordForm from './ForgottenPasswordForm.tsx';

interface Props {
  onClose: () => void,
}

const ForgottenPasswordDialog = ({
  onClose,
}: Props) => {
  const { openLoginDialog } = useContext(OfflineDialogsContext);

  return (
    <Dialog
      id="forgotten-password-dialog"
      onClose={onClose}
      open
      scroll="body"
      // @ts-ignore
      maxWidth="md"
      disableEscapeKeyDown
    >
      <VStack width={550} p={5}>
        <ForgottenPasswordForm
          onCancel={() => {
            onClose();
            openLoginDialog();
          }}
          onLoggedIn={onClose}
        />
      </VStack>
    </Dialog>
  );
};

export default ForgottenPasswordDialog;
