import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { colors } from '../../../../config/theming.ts';
import { UserSubscriptionType } from '../../../../graphql';
import HStack from '../../../ui-kit/layout/HStack.tsx';
import VStack from '../../../ui-kit/layout/VStack.tsx';

interface Props {
  selectedSubscriptions: UserSubscriptionType[],
  setSelectedSubscriptions: (subscriptions: UserSubscriptionType[]) => void,
}

const RegisterFormSubscriptions = ({ selectedSubscriptions, setSelectedSubscriptions }: Props) => {
  const { t } = useTranslation();

  return (
    <HStack className="prices" justifyContent="center" mt={5} px={5} gap={2} flexWrap="wrap">
      <Paper
        className="price-paper"
        onClick={() => setSelectedSubscriptions([ UserSubscriptionType.Client ])}
        elevation={selectedSubscriptions.length === 1 && selectedSubscriptions[0] === UserSubscriptionType.Client ? 8 : 3}
        sx={{ width: 300, cursor: 'pointer', border: selectedSubscriptions.length === 1 && selectedSubscriptions[0] === UserSubscriptionType.Client ? 'thin solid red' : '' }}
      >
        <VStack alignItems="center" p={4}>
          <ShoppingCartIcon sx={{ fontSize: 40, color: selectedSubscriptions.length === 1 && selectedSubscriptions[0] === UserSubscriptionType.Client ? colors.secondary.main : colors.primary.main, marginBottom: '10px' }} />
          <Typography variant="h4" fontWeight="bold">{t('dictionary.buyer')}</Typography>
          <Typography variant="h6" color={colors.secondary.main} fontWeight="bold">
            1497€
            {t('dictionary.vat-excluded')}
          </Typography>
        </VStack>
      </Paper>
      <Paper
        className="price-paper"
        onClick={() => setSelectedSubscriptions([ UserSubscriptionType.Client, UserSubscriptionType.Seller ])}
        elevation={selectedSubscriptions.length === 2 ? 8 : 3}
        sx={{ width: 300, cursor: 'pointer', border: selectedSubscriptions.length === 2 ? 'thin solid red' : '' }}
      >
        <VStack alignItems="center" p={4}>
          <HStack gap={1} alignItems="center">
            <ShoppingCartIcon sx={{ fontSize: 40, color: selectedSubscriptions.length === 2 ? colors.secondary.main : colors.primary.main }} />
            <Typography variant="h4" fontWeight="bold">+</Typography>
            <EmojiTransportationIcon sx={{ fontSize: 50, color: selectedSubscriptions.length === 2 ? colors.secondary.main : colors.primary.main }} />
          </HStack>
          <Typography variant="h4" fontWeight="bold">{t('dictionary.duo')}</Typography>
          <Typography variant="h6" color={colors.secondary.main} fontWeight="bold">
            2495€
            {t('dictionary.vat-excluded')}
          </Typography>
        </VStack>
      </Paper>
      <Paper
        className="price-paper"
        onClick={() => setSelectedSubscriptions([ UserSubscriptionType.Seller ])}
        elevation={selectedSubscriptions.length === 1 && selectedSubscriptions[0] === UserSubscriptionType.Seller ? 8 : 3}
        sx={{ width: 300, cursor: 'pointer', border: selectedSubscriptions.length === 1 && selectedSubscriptions[0] === UserSubscriptionType.Seller ? 'thin solid red' : '' }}
      >
        <VStack alignItems="center" p={4}>
          <EmojiTransportationIcon sx={{ fontSize: 50, color: selectedSubscriptions.length === 1 && selectedSubscriptions[0] === UserSubscriptionType.Seller ? colors.secondary.main : colors.primary.main }} />
          <Typography variant="h4" fontWeight="bold" color={colors.blue}>{t('dictionary.seller')}</Typography>
          <Typography variant="h6" color={colors.secondary.main} fontWeight="bold">
            1497€
            {t('dictionary.vat-excluded')}
          </Typography>
        </VStack>
      </Paper>
    </HStack>
  );
};

export default RegisterFormSubscriptions;
