import { useReactiveVar } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInterval } from 'usehooks-ts';
import {
  attributeCategoriesVar,
  attributesVar,
  brandsVar,
  damageLocationsVar,
  damagesVar,
  equipmentsVar,
  langVar,
  loggedIn,
  useGetAllAttributeCategoriesLazyQuery,
  useGetAllAttributesLazyQuery,
  useGetAllBrandsLazyQuery,
  useGetAllDamageLocationsLazyQuery,
  useGetAllDamagesLazyQuery,
  useGetAllEquipmentsLazyQuery,
  useMeLazyQuery,
  User,
  userConnected,
  UserSubscriptionStatus,
} from '../graphql';
import i18n from '../i18n.ts';
import useLocalStorage from './useLocalStorage.ts';
import useUiToast from './useUiToast.ts';

export default function useAppInitialization(reloadOnTabChange = true) {
  const { t } = useTranslation();
  const [ isAppInitialized, setIsAppInitialized ] = useState(false);
  const [ fetchAllAttributes ] = useGetAllAttributesLazyQuery();
  const [ fetchAllAttributeCategories ] = useGetAllAttributeCategoriesLazyQuery();
  const [ fetchAllBrands ] = useGetAllBrandsLazyQuery();
  const [ fetchAllEquipments ] = useGetAllEquipmentsLazyQuery();
  const [ fetchAllDamages ] = useGetAllDamagesLazyQuery();
  const [ fetchAllDamageLocations ] = useGetAllDamageLocationsLazyQuery();
  const [ fetchUser, { data: loggedUserData } ] = useMeLazyQuery();
  const { error: errorToast } = useUiToast();
  const isLoggedIn = useReactiveVar(loggedIn);
  const { get } = useLocalStorage();
  const [ alreadyShownSubscriptionMissingError, setAlreadyShownSubscriptionMissingError ] = useState(false);
  const [ isLanguageSet, setIsLanguageSet ] = useState(false);

  const checkUserHasSubscription = (user: User) => user
    && user.latestSellerSubscription
    && user.latestSellerSubscription.status !== UserSubscriptionStatus.Active
    && user.latestClientSubscription
    && user.latestClientSubscription.status !== UserSubscriptionStatus.Active;

  const notifyMissingSubscription = () => {
    if (alreadyShownSubscriptionMissingError) {
      return;
    }

    setAlreadyShownSubscriptionMissingError(true);
    errorToast(t('errors.you-do-not-have-any-subscription'));
  };

  const initializeApplication = async () => {
    const { data: userData } = await fetchUser();

    if (userData && checkUserHasSubscription(userData.getLoggedUser as User) && !alreadyShownSubscriptionMissingError) {
      notifyMissingSubscription();
    }

    if (userData) {
      userConnected(userData?.getLoggedUser as User || null);
    }

    const { data: attributesData } = await fetchAllAttributes();
    const { data: attributeCategoriesData } = await fetchAllAttributeCategories();
    const { data: brandsData } = await fetchAllBrands();

    attributesVar(attributesData?.allAttributes || []);
    attributeCategoriesVar(attributeCategoriesData?.allAttributeCategories || []);
    brandsVar(brandsData?.allBrands || []);

    void (async () => {
      const { data: equipmentsData } = await fetchAllEquipments();
      const { data: damagesData } = await fetchAllDamages();
      const { data: damageLocationsData } = await fetchAllDamageLocations();

      equipmentsVar(equipmentsData?.allEquipments || []);
      damagesVar(damagesData?.allDamages || []);
      damageLocationsVar(damageLocationsData?.allDamageLocations || []);
    })();

    if (!isAppInitialized) {
      setIsAppInitialized(true);
    }
  };

  const onTabChange = () => {
    if (!document.hidden) {
      initializeApplication();
    }
  };

  useInterval(() => {
    (async () => {
      if (!isLoggedIn || !get('bearer-token', null)) {
        return;
      }

      const { data: userData, error } = await fetchUser();

      if (!userData || error?.message === 'Unauthorized') {
        return;
      }

      userConnected(userData.getLoggedUser as User || null);
      loggedIn(true);
    })();
  }, 60000);

  useEffect(() => {
    if (!reloadOnTabChange || !get('bearer-token', null)) {
      return () => document.removeEventListener('visibilitychange', onTabChange);
    }

    document.removeEventListener('visibilitychange', onTabChange);
    document.addEventListener('visibilitychange', onTabChange);

    return () => document.removeEventListener('visibilitychange', onTabChange);
  }, [ alreadyShownSubscriptionMissingError ]);

  useEffect(() => {
    if (loggedUserData && !isLanguageSet) {
      langVar(loggedUserData.getLoggedUser.language.toLowerCase());
      i18n.changeLanguage(loggedUserData.getLoggedUser.language.toLowerCase());
      setIsLanguageSet(true);
    }
  }, [ loggedUserData ]);

  return { initializeApplication, isAppInitialized };
}
