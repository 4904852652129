import { MenuItem, TextField } from '@mui/material';

interface Item {
  value: any,
  label: any,
}

interface Props {
  labelFrom: string,
  labelTo: string,
  fromItems: Item[],
  toItems: Item[],
  onFromChange: (value: string) => void,
  onToChange: (value: string) => void,
}

const RangeFilter = ({
  labelFrom, labelTo, fromItems, toItems, onFromChange, onToChange,
}: Props) => (
  <>
    <TextField
      select
      size="small"
      defaultValue="-1"
      sx={{ minWidth: 120 }}
      onChange={({ target: { value } }) => onFromChange(value)}
    >
      <MenuItem value="-1">{ labelFrom }</MenuItem>
      { fromItems.map((item) => <MenuItem value={item.value} key={item.value}>{ item.label }</MenuItem>) }
    </TextField>
    <TextField
      select
      size="small"
      defaultValue="-1"
      sx={{ minWidth: 120 }}
      onChange={({ target: { value } }) => onToChange(value)}
    >
      <MenuItem value="-1">{ labelTo }</MenuItem>
      { toItems.map((item) => <MenuItem value={item.value} key={item.value}>{ item.label }</MenuItem>) }
    </TextField>
  </>
);

export default RangeFilter;
