import { MenuItem, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddressType, Country, SaveDealerAddressDto } from '../../../../graphql';
import VStack from '../../../ui-kit/layout/VStack.tsx';

function createDto(address?: SaveDealerAddressDto): SaveDealerAddressDto {
  return {
    id: address?.id || null,
    type: address?.type || AddressType.Headquarter,
    street: address?.street || '',
    number: address?.number || '',
    city: address?.city || '',
    zip: address?.zip || '',
    country: address?.country || Country.Be,
  };
}

interface Props {
  onChange: (dto: SaveDealerAddressDto) => void,
}

const RegisterFormDealerAddress = ({ onChange }: Props) => {
  const { t } = useTranslation();
  const [ dto, setDto ] = useState<SaveDealerAddressDto>(createDto());

  useEffect(() => {
    onChange(dto);
  }, [ dto ]);

  return (
    <VStack alignItems="center" mt={5} px={5}>
      <VStack width="100%" maxWidth={425} mt={2}>
        <Typography fontWeight="bolder">
          {t('form.labels.street')}
          <span style={{ color: 'red' }}>*</span>
        </Typography>
        <TextField
          variant="outlined"
          size="small"
          sx={{ width: '100%' }}
          value={dto.street}
          onChange={({ target: { value: street } }) => setDto({ ...dto, street })}
        />
      </VStack>

      <VStack width="100%" maxWidth={425} mt={2}>
        <Typography fontWeight="bolder">
          {t('form.labels.number')}
          <span style={{ color: 'red' }}>*</span>
        </Typography>
        <TextField
          variant="outlined"
          size="small"
          sx={{ width: '100%' }}
          value={dto.number}
          onChange={({ target: { value: number } }) => setDto({ ...dto, number })}
        />
      </VStack>

      <VStack width="100%" maxWidth={425} mt={2}>
        <Typography fontWeight="bolder">
          {t('form.labels.city')}
          <span style={{ color: 'red' }}>*</span>
        </Typography>
        <TextField
          variant="outlined"
          size="small"
          sx={{ width: '100%' }}
          value={dto.city}
          onChange={({ target: { value: city } }) => setDto({ ...dto, city })}
        />
      </VStack>

      <VStack width="100%" maxWidth={425} mt={2}>
        <Typography fontWeight="bolder">
          {t('form.labels.zip')}
          <span style={{ color: 'red' }}>*</span>
        </Typography>
        <TextField
          variant="outlined"
          size="small"
          sx={{ width: '100%' }}
          value={dto.zip}
          onChange={({ target: { value: zip } }) => setDto({ ...dto, zip })}
        />
      </VStack>

      <VStack width="100%" maxWidth={425} mt={2}>
        <Typography fontWeight="bolder">
          {t('form.labels.country')}
          <span style={{ color: 'red' }}>*</span>
        </Typography>
        <TextField
          select
          variant="outlined"
          size="small"
          sx={{ width: '100%' }}
          value={dto.country}
          onChange={({ target: { value: country } }) => setDto({ ...dto, country: country as Country })}
        >
          {
            [ Country.Be, Country.Fr, Country.Lu, Country.De, Country.Nl, Country.It, Country.Es, Country.Gr ].map((country) => (
              <MenuItem value={country} key={country}>{t(`enums.countries.${country}`)}</MenuItem>
            ))
          }
        </TextField>
      </VStack>
    </VStack>
  );
};

export default RegisterFormDealerAddress;
