import { Alert, Button, Typography } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useValidateUserAccountMutation } from '../../../../graphql';
import useReactiveUserConnected from '../../../../hooks/data/useReactiveUserConnected.ts';
import useLogout from '../../../../hooks/useLogout.ts';
import HStack from '../../../ui-kit/layout/HStack.tsx';
import PageOffline from '../../../ui-kit/layout/PageOffline.tsx';
import VStack from '../../../ui-kit/layout/VStack.tsx';
import WheelLoader from '../../../ui-kit/utils/WheelLoader.tsx';

const ValidateAccount = () => {
  const { t } = useTranslation();
  const { userId } = useParams<{ userId?: string, }>();
  const searchParams = useSearchParams();
  const { user } = useReactiveUserConnected();
  const navigate = useNavigate();
  const logout = useLogout();
  const code = useMemo(
    () => (searchParams.length ? searchParams[0]!.get('code') : null),
    [ searchParams ],
  );
  const [ validateUserAccount, {
    data, error, called, loading: isValidating,
  } ] = useValidateUserAccountMutation();
  const isValidated = useMemo(
    () => (user && user.validatedAt) || (data && data.validateUser),
    [ user, data ],
  );

  useEffect(() => {
    if (!userId || !code) {
      return;
    }

    (async () => {
      await validateUserAccount({ variables: { dto: { userId: Number(userId!), code } } });
    })();
  }, [ userId, code ]);

  return (
    <PageOffline>
      <VStack width="100%">
        <HStack alignItems="center" justifyContent="center" mt={4} mb={0}>
          {
            isValidating && (
              <WheelLoader width={40} height={40} showText={false} />
            )
          }
          <Typography variant="h3" fontWeight="bold">{t('offline.validate-account.title')}</Typography>
        </HStack>

        <VStack alignItems="center" mt={4} mb={0}>
          {
            error && error.message === 'Account is already validated' && (
              <>
                <Alert severity="error" sx={{ width: '100%', maxWidth: 600, mb: 5 }}>
                  <strong>{t('offline.validate-account.account-already-validated')}</strong>
                </Alert>

                <Button variant="outlined" onClick={() => navigate('/')}>{t('buttons.back-to-homepage')}</Button>
              </>
            )
          }

          {
            error && error.message === 'Validation code is invalid' && (
              <>
                <Alert severity="error" sx={{ width: '100%', maxWidth: 600, mb: 5 }}>
                  {/* eslint-disable-next-line react/no-danger */}
                  <div dangerouslySetInnerHTML={{ __html: t('offline.validate-account.validation-code-is-invalid') }} />
                </Alert>

                <Button variant="outlined" onClick={() => navigate('/')}>{t('buttons.back-to-homepage')}</Button>
              </>
            )
          }

          {
            !error && ((!userId || !code) || (userId && code && !isValidating && called)) && !isValidated && (
              <>
                <Alert severity="error" sx={{ width: '100%', maxWidth: 600, mb: 5 }}>
                  {/* eslint-disable-next-line react/no-danger */}
                  <div dangerouslySetInnerHTML={{ __html: t('offline.validate-account.account-not-validated') }} />
                </Alert>

                <HStack gap={2}>
                  <Button variant="outlined" onClick={() => navigate('/')}>{t('buttons.back-to-homepage')}</Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      logout();
                    }}
                  >
                    {t('buttons.logout')}
                  </Button>
                </HStack>
              </>
            )
          }

          {
            !isValidating && called && isValidated && (
              <>
                <Alert severity="success" sx={{ width: '100%', maxWidth: 600, mb: 5 }}>
                  <strong>{t('offline.validate-account.congratulations')}</strong>
                </Alert>

                <Button variant="contained" onClick={() => navigate(user ? '/online/marketplace' : '/login')}>{t('buttons.login')}</Button>
              </>
            )
          }
        </VStack>
      </VStack>
    </PageOffline>
  );
};

export default ValidateAccount;
