import VStack from '../layout/VStack.tsx';
import WheelLoader from './WheelLoader.tsx';

const PageLoader = () => (
  <VStack position="fixed" alignItems="center" justifyContent="center" width="100%" height="100vh" top={0} left={0} bgcolor="#030b215c" zIndex={5000}>
    <WheelLoader bgColor="white" />
  </VStack>
);

export default PageLoader;
