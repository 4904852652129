import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import BallotIcon from '@mui/icons-material/Ballot';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import FavoriteIcon from '@mui/icons-material/Favorite';
import GarageOutlinedIcon from '@mui/icons-material/GarageOutlined';
import HistoryIcon from '@mui/icons-material/History';
import StarIcon from '@mui/icons-material/StarPurple500';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import className from '../../../helpers/className.ts';
import useReactiveUserConnected from '../../../hooks/data/useReactiveUserConnected.ts';
import HStack from './HStack.tsx';
import VStack from './VStack.tsx';

const LeftMenu = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { isSeller, isClient, isConnected } = useReactiveUserConnected();

  return (
    <VStack className="left-menu" width="20%" alignItems="center" gap={2} position="sticky" top="116px">
      {
        isClient && (
          <>
            <HStack
              padding={2}
              width="70%"
              borderRadius={2}
              classes={{ active: true }}
              alignItems="center"
              className={className('left-menu-button', { active: location.pathname === '/online/marketplace' })}
              onClick={() => navigate('/')}
            >
              <DirectionsCarIcon sx={{ color: '#cf0e10', mr: 2 }} />
              <Typography color="white">
                {t('menu.marketplace')}
              </Typography>
            </HStack>
            <HStack
              padding={2}
              width="70%"
              borderRadius={2}
              alignItems="center"
              className={className('left-menu-button', { active: location.pathname === '/offers' })}
              onClick={() => navigate('/offers')}
            >
              <BallotIcon sx={{ color: '#cf0e10', mr: 2 }} />
              <Typography color="white">
                {t('menu.my-offers')}
              </Typography>
            </HStack>
            <HStack
              padding={2}
              width="70%"
              borderRadius={2}
              alignItems="center"
              className={className('left-menu-button', { active: location.pathname === '/favorites' })}
              onClick={() => navigate('/favorites')}
            >
              <FavoriteIcon sx={{ color: '#cf0e10', mr: 2 }} />
              <Typography color="white">
                {t('menu.my-favorites')}
              </Typography>
            </HStack>
            <HStack
              padding={2}
              width="70%"
              borderRadius={2}
              alignItems="center"
              className={className('left-menu-button', { active: location.pathname === '/history' })}
              onClick={() => navigate('/history')}
            >
              <HistoryIcon sx={{ color: '#cf0e10', mr: 2 }} />
              <Typography color="white">
                {t('menu.my-history')}
              </Typography>
            </HStack>
          </>
        )
      }
      {
        isSeller && (
          <>
            <HStack
              padding={2}
              width="70%"
              borderRadius={2}
              alignItems="center"
              className={className('left-menu-button', { active: location.pathname === '/my-vehicles/add' })}
              onClick={() => navigate('/my-vehicles/add')}
            >
              <AddBoxOutlinedIcon sx={{ color: '#cf0e10', mr: 2 }} />
              <Typography color="white">{t('menu.add-vehicle')}</Typography>
            </HStack>
            <HStack
              padding={2}
              width="70%"
              borderRadius={2}
              alignItems="center"
              className={className('left-menu-button', { active: location.pathname === '/my-vehicles' })}
              onClick={() => navigate('/my-vehicles')}
            >
              <GarageOutlinedIcon sx={{ color: '#cf0e10', mr: 2 }} />
              <Typography color="white">
                {t('menu.my-vehicles')}
              </Typography>
            </HStack>
          </>
        )
      }
      {
        !isClient && (
          <HStack
            padding={2}
            width="70%"
            borderRadius={2}
            alignItems="center"
            className={className('left-menu-button', { active: location.pathname === '/my-vehicles/add' })}
            onClick={() => navigate(isConnected ? '/parameters/profile' : '/register')}
          >
            <StarIcon sx={{ color: 'gold', mr: 2 }} />
            <Typography color="white">{t('menu.become-client')}</Typography>
          </HStack>
        )
      }
      {
        !isSeller && (
          <HStack
            padding={2}
            width="70%"
            borderRadius={2}
            alignItems="center"
            className={className('left-menu-button', { active: location.pathname === '/my-vehicles/add' })}
            onClick={() => navigate(isConnected ? '/parameters/profile' : '/register')}
          >
            <StarIcon sx={{ color: 'gold', mr: 2 }} />
            <Typography color="white">{t('menu.become-dealer')}</Typography>
          </HStack>
        )
      }
    </VStack>
  );
};

export default LeftMenu;
