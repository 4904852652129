import { useState } from 'react';

export default function usePagination(defaultNumberPerPage: number = 10, defaultCurrentPage: number = 0) {
  const [ numberPerPage, setNumberPerPage ] = useState(defaultNumberPerPage);
  const [ currentPage, setCurrentPage ] = useState(defaultCurrentPage);
  const [ total, setTotal ] = useState(0);

  return {
    numberPerPage,
    currentPage,
    total,
    setNumberPerPage,
    setCurrentPage,
    setTotal,
  };
}
