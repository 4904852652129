import { useEffect, useState } from 'react';

export default function useLocationQueryParameters() {
  const [ parameters, setParameters ] = useState<Array<{ key: string, value: string|undefined, }>>([]);

  useEffect(() => {
    setParameters((document.location.href.split('?')[1]?.split('&') || []).map((query) => ({ key: query.split('=')[0], value: query.split('=')[1] })));
  }, []);

  return {
    parameters,
    getQueryParamByKey(key: string): string|undefined {
      return parameters.find((query) => query.key === key)?.value;
    },
  };
}
