import { useReactiveVar } from '@apollo/client';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Badge, IconButton, Menu } from '@mui/material';
import { MouseEvent, useMemo, useState } from 'react';
import { notificationsFeedVar } from '../../../../graphql';
import NotificationFeed from '../notifications/NotificationFeed.tsx';

const HeaderNotificationMenu = () => {
  const notificationsFeed = useReactiveVar(notificationsFeedVar);
  const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const countOfUnreadNotifications = useMemo(() => notificationsFeed
    .results.filter((notification) => notification.status === 'SENT').length, [ notificationsFeed ]);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        onClick={handleClick}
        size="small"
        sx={{ ml: 2 }}
        aria-controls={open ? 'notifications-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        {
          countOfUnreadNotifications > 0 && (
            <Badge color="secondary" badgeContent={countOfUnreadNotifications > 9 ? '+9' : countOfUnreadNotifications} invisible={false}>
              <NotificationsIcon sx={{ color: 'white', fontSize: 35 }} />
            </Badge>
          )
        }

        { countOfUnreadNotifications === 0 && (<NotificationsIcon sx={{ color: 'white', fontSize: 35 }} />) }
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        id="languages-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            width: '100%',
            left: 0,
            maxWidth: 320,
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            overflowY: 'auto',
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
      >
        { open && <NotificationFeed /> }
      </Menu>
    </>
  );
};

export default HeaderNotificationMenu;
