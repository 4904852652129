import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  loggedIn, useLoginLazyQuery, useMeLazyQuery, User, userConnected,
} from '../graphql';
import useAppInitialization from './useAppInitialization.ts';
import useLocalStorage from './useLocalStorage.ts';
import useUiToast from './useUiToast.ts';

export default function useLogin() {
  const { t } = useTranslation();
  const { set } = useLocalStorage();
  const [ hasNoAccount, setHasNoAccount ] = useState(false);
  const [ wrongPassword, setWrongPassword ] = useState(false);
  const [ isFullLogging, setIsFullLogging ] = useState(false);
  const [ authenticateUser, { loading: isLogging } ] = useLoginLazyQuery();
  const [ fetchCurrentUser ] = useMeLazyQuery();
  const { error: errorToast } = useUiToast();
  const { initializeApplication } = useAppInitialization(true);

  const login = async (email: string, password: string) => {
    setHasNoAccount(false);
    setWrongPassword(false);

    const { data, error } = await authenticateUser({ variables: { email, password } });

    if (error && error.message.includes('User not found')) {
      setHasNoAccount(true);
    }

    if (error && error.message.includes('Invalid password')) {
      setWrongPassword(true);
    }

    if (!data) {
      return;
    }

    set('bearer-token', data.login);

    return { data, error };
  };

  const fullLogin = async (email: string, password: string) => {
    setIsFullLogging(true);

    await login(email, password);

    const { data: fetchedCurrentUser } = await fetchCurrentUser();

    if (
      fetchedCurrentUser
      && (!fetchedCurrentUser.getLoggedUser.latestSellerSubscription || fetchedCurrentUser.getLoggedUser.latestSellerSubscription.status !== 'ACTIVE')
      && (!fetchedCurrentUser.getLoggedUser.latestClientSubscription || fetchedCurrentUser.getLoggedUser.latestClientSubscription.status !== 'ACTIVE')
    ) {
      errorToast(t('errors.you-do-not-have-any-subscription'));

      setIsFullLogging(false);

      return false;
    }

    if (!fetchedCurrentUser) {
      setIsFullLogging(false);

      return false;
    }

    userConnected(fetchedCurrentUser.getLoggedUser as User);

    await initializeApplication();

    loggedIn(true);

    setIsFullLogging(false);

    return true;
  };

  return {
    login,
    fullLogin,
    isFullLogging,
    hasNoAccount,
    wrongPassword,
    isLogging,
  };
}
