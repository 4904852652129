import AirlineSeatReclineExtraIcon from '@mui/icons-material/AirlineSeatReclineExtra';
import CarCrashIcon from '@mui/icons-material/CarCrash';
import CategoryIcon from '@mui/icons-material/Category';
import PeopleIcon from '@mui/icons-material/People';
import SellIcon from '@mui/icons-material/Sell';
import { Card } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useRefreshPublishedVehiclesInCacheMutation } from '../../../../graphql';
import HStack from '../../../ui-kit/layout/HStack.tsx';
import Page from '../../../ui-kit/layout/Page.tsx';

const AdministrationDashboardPage = () => {
  const navigate = useNavigate();
  const [ refreshPublishedVehiclesInCache ] = useRefreshPublishedVehiclesInCacheMutation();

  return (
    <Page>
      <h1>Administration</h1>
      <HStack gap={2}>
        {/* <Card sx={{
          padding: 2, cursor: 'pointer', alignItems: 'center', display: 'flex', gap: 1,
        }}
        >
          <GroupIcon />
          Gestion des utilisateurs
        </Card> */}
        <Card
          sx={{
            padding: 2, cursor: 'pointer', alignItems: 'center', display: 'flex', gap: 1,
          }}
          onClick={() => navigate('/admin/brands')}
        >
          <SellIcon />
          Gestion des marques
        </Card>
        <Card
          sx={{
            padding: 2, cursor: 'pointer', alignItems: 'center', display: 'flex', gap: 1,
          }}
          onClick={() => navigate('/admin/attributes')}
        >
          <CategoryIcon />
          Gestion des attributs
        </Card>
        <Card
          sx={{
            padding: 2, cursor: 'pointer', alignItems: 'center', display: 'flex', gap: 1,
          }}
          onClick={() => navigate('/admin/equipments')}
        >
          <AirlineSeatReclineExtraIcon />
          Gestion des équipements
        </Card>
        <Card
          sx={{
            padding: 2, cursor: 'pointer', alignItems: 'center', display: 'flex', gap: 1,
          }}
          onClick={() => navigate('/admin/damages')}
        >
          <CarCrashIcon />
          Gestion des dégâts
        </Card>
        <Card
          sx={{
            padding: 2, cursor: 'pointer', alignItems: 'center', display: 'flex', gap: 1,
          }}
          onClick={() => navigate('/admin/users')}
        >
          <PeopleIcon />
          Gestion des utilisateurs
        </Card>
        <Card
          sx={{
            padding: 2, cursor: 'pointer', alignItems: 'center', display: 'flex', gap: 1,
          }}
          onClick={() => refreshPublishedVehiclesInCache()}
        >
          Rafraîchir le cache
        </Card>
        {/* <Card sx={{
          padding: 2, cursor: 'pointer', alignItems: 'center', display: 'flex', gap: 1,
        }}
        >
          <DirectionsCarIcon />
          Gestion des véhicules
        </Card> */}
      </HStack>
    </Page>
  );
};

export default AdministrationDashboardPage;
