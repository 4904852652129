import CloseIcon from '@mui/icons-material/Close';
import {
  Box, Button, Dialog, TextField, Tooltip, Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ManageUserSubscriptionDto,
  useManageUserSubscriptionMutation,
  User,
  UserSubscription,
  UserSubscriptionStatus,
  UserSubscriptionType,
} from '../../../../../../graphql';
import useUiToast from '../../../../../../hooks/useUiToast.ts';
import HStack from '../../../../../ui-kit/layout/HStack.tsx';
import VStack from '../../../../../ui-kit/layout/VStack.tsx';

function createDto(userId: number, type: UserSubscriptionType, userSubscription?: UserSubscription): ManageUserSubscriptionDto {
  return {
    id: userSubscription?.id || null,
    userId,
    type: userSubscription?.type || type,
    status: userSubscription?.status || UserSubscriptionStatus.Disabled,
    startAt: userSubscription ? userSubscription.startAt : new Date(),
    endAt: userSubscription ? userSubscription.endAt : null,
  };
}

interface Props {
  user: User,
  onClose: () => void,
  onSave: () => void,
}

const AdminUsersSubscriptionsDialog = ({ user, onClose, onSave }: Props) => {
  const { t } = useTranslation();
  const [ clientSubscriptionDto, setClientSubscriptionDto ] = useState<ManageUserSubscriptionDto>(
    createDto(user.id, UserSubscriptionType.Client, user.latestClientSubscription as UserSubscription|undefined),
  );
  const [ sellerSubscriptionDto, setSellerSubscriptionDto ] = useState<ManageUserSubscriptionDto>(
    createDto(user.id, UserSubscriptionType.Seller, user.latestSellerSubscription as UserSubscription|undefined),
  );
  const [ manageUserSubscription, { loading: isPushing } ] = useManageUserSubscriptionMutation();
  const { success, error } = useUiToast();

  const handleSave = async () => {
    if (clientSubscriptionDto.status === 'ACTIVE' && clientSubscriptionDto.startAt === null && clientSubscriptionDto.endAt === null) {
      error('L\'abonnement acheteur doit contenir une date de début et une date de fin');

      return;
    }

    if (sellerSubscriptionDto.status === 'ACTIVE' && sellerSubscriptionDto.startAt === null && sellerSubscriptionDto.endAt === null) {
      error('L\'abonnement vendeur doit contenir une date de début et une date de fin');

      return;
    }

    await manageUserSubscription({ variables: { dto: clientSubscriptionDto } });
    await manageUserSubscription({ variables: { dto: sellerSubscriptionDto } });

    success('Abonnements mis-à-jour');

    onSave();
  };

  const hanbleChangeSubscriptionStatus = (type: UserSubscriptionType, status: UserSubscriptionStatus) => {
    if (type === UserSubscriptionType.Client) {
      setClientSubscriptionDto({ ...clientSubscriptionDto, status });
    }

    if (type === UserSubscriptionType.Seller) {
      setSellerSubscriptionDto({ ...sellerSubscriptionDto, status });
    }
  };

  const getBulletColorOnStatusBasis = (status?: UserSubscriptionStatus) => {
    if (status === UserSubscriptionStatus.Active) {
      return 'limegreen';
    }

    if (status === UserSubscriptionStatus.Waiting) {
      return 'orange';
    }

    return 'red';
  };

  const getTooltipTitleOnStatusBasis = (status?: UserSubscriptionStatus) => {
    if (status === UserSubscriptionStatus.Active) {
      return 'Abonnement actif';
    }

    if (status === UserSubscriptionStatus.Waiting) {
      return 'Abonnement en attente';
    }

    return 'Aucun abonnement actif';
  };

  useEffect(() => {
    if (clientSubscriptionDto.endAt && clientSubscriptionDto.status === UserSubscriptionStatus.Waiting) {
      setClientSubscriptionDto({ ...clientSubscriptionDto, status: UserSubscriptionStatus.Active });
    }

    if (sellerSubscriptionDto.endAt && sellerSubscriptionDto.status === UserSubscriptionStatus.Waiting) {
      setSellerSubscriptionDto({ ...sellerSubscriptionDto, status: UserSubscriptionStatus.Active });
    }
  }, [ clientSubscriptionDto.endAt, sellerSubscriptionDto.endAt ]);

  useEffect(() => {
    if (clientSubscriptionDto.status === UserSubscriptionStatus.Disabled) {
      setClientSubscriptionDto({ ...clientSubscriptionDto, startAt: null, endAt: null });
    }

    if (sellerSubscriptionDto.status === UserSubscriptionStatus.Disabled) {
      setSellerSubscriptionDto({ ...sellerSubscriptionDto, startAt: null, endAt: null });
    }
  }, [ clientSubscriptionDto.status, sellerSubscriptionDto.status ]);

  return (
    <Dialog
      onClose={onClose}
      open
      maxWidth="md"
    >
      <Box p={5} width={600}>
        <HStack justifyContent="space-between" alignItems="center">
          <h2>Gérer les abonnements</h2>
          <Button size="small" onClick={onClose}>
            <CloseIcon fontSize="small" sx={{ top: -1, position: 'relative' }} />
            {' '}
            { t('buttons.close') }
          </Button>
        </HStack>
        <VStack justifyContent="center" mt={2}>
          <HStack alignItems="center" justifyContent="space-between">
            <Typography variant="h6" display="flex" alignItems="center" gap={1}>
              <Tooltip title={getTooltipTitleOnStatusBasis(clientSubscriptionDto.status)}>
                <Box
                  component="span"
                  display="inline-flex"
                  bgcolor={getBulletColorOnStatusBasis(clientSubscriptionDto.status)}
                  width={15}
                  height={15}
                  borderRadius="100%"
                />
              </Tooltip>
              Abonnement acheteur
            </Typography>

            {
              clientSubscriptionDto.status !== 'DISABLED' && (
                <Button size="small" variant="outlined" onClick={() => hanbleChangeSubscriptionStatus(UserSubscriptionType.Client, UserSubscriptionStatus.Disabled)}>Annuler l'abonnement</Button>
              )
            }
            {
              clientSubscriptionDto.status === 'DISABLED' && (
                <Button size="small" variant="outlined" onClick={() => hanbleChangeSubscriptionStatus(UserSubscriptionType.Client, UserSubscriptionStatus.Active)}>Créer l'abonnement</Button>
              )
            }
          </HStack>

          {
            clientSubscriptionDto.status !== 'DISABLED' && (
              <HStack gap={4} mt={2}>
                <VStack>
                  <Typography variant="body1">Date de début</Typography>
                  <TextField
                    type="date"
                    variant="outlined"
                    size="small"
                    sx={{ width: '100%', mb: 2 }}
                    value={clientSubscriptionDto.startAt ? dayjs(clientSubscriptionDto.startAt).format('YYYY-MM-DD') : ''}
                    onChange={({ target: { value } }) => setClientSubscriptionDto({ ...clientSubscriptionDto, startAt: dayjs(value).hour(12).toISOString() })}
                  />
                </VStack>
                <VStack>
                  <Typography variant="body1">Date de fin</Typography>
                  <TextField
                    type="date"
                    variant="outlined"
                    size="small"
                    sx={{ width: '100%', mb: 2 }}
                    value={clientSubscriptionDto.endAt ? dayjs(clientSubscriptionDto.endAt).format('YYYY-MM-DD') : ''}
                    onChange={({ target: { value } }) => setClientSubscriptionDto({ ...clientSubscriptionDto, endAt: dayjs(value).hour(12).toISOString() })}
                  />
                </VStack>
              </HStack>
            )
          }

          <HStack alignItems="center" justifyContent="space-between" mt={4}>
            <Typography variant="h6" display="flex" alignItems="center" gap={1}>
              <Tooltip title={getTooltipTitleOnStatusBasis(sellerSubscriptionDto.status)}>
                <Box
                  component="span"
                  display="inline-flex"
                  bgcolor={getBulletColorOnStatusBasis(sellerSubscriptionDto.status)}
                  width={15}
                  height={15}
                  borderRadius="100%"
                />
              </Tooltip>
              Abonnement vendeur
            </Typography>

            {
              sellerSubscriptionDto.status !== 'DISABLED' && (
                <Button size="small" variant="outlined" onClick={() => hanbleChangeSubscriptionStatus(UserSubscriptionType.Seller, UserSubscriptionStatus.Disabled)}>Annuler l'abonnement</Button>
              )
            }
            {
              sellerSubscriptionDto.status === 'DISABLED' && (
                <Button size="small" variant="outlined" onClick={() => hanbleChangeSubscriptionStatus(UserSubscriptionType.Seller, UserSubscriptionStatus.Active)}>Créer l'abonnement</Button>
              )
            }
          </HStack>

          {
            sellerSubscriptionDto.status !== 'DISABLED' && (
              <HStack gap={4} mt={2}>
                <VStack>
                  <Typography variant="body1">Date de début</Typography>
                  <TextField
                    type="date"
                    variant="outlined"
                    size="small"
                    sx={{ width: '100%', mb: 2 }}
                    value={sellerSubscriptionDto.startAt ? dayjs(sellerSubscriptionDto.startAt).format('YYYY-MM-DD') : ''}
                    onChange={({ target: { value } }) => setSellerSubscriptionDto({ ...sellerSubscriptionDto, startAt: dayjs(value).hour(12).toISOString() })}
                  />
                </VStack>
                <VStack>
                  <Typography variant="body1">Date de fin</Typography>
                  <TextField
                    type="date"
                    variant="outlined"
                    size="small"
                    sx={{ width: '100%', mb: 2 }}
                    value={sellerSubscriptionDto.endAt ? dayjs(sellerSubscriptionDto.endAt).format('YYYY-MM-DD') : ''}
                    onChange={({ target: { value } }) => setSellerSubscriptionDto({ ...sellerSubscriptionDto, endAt: dayjs(value).hour(12).toISOString() })}
                  />
                </VStack>
              </HStack>
            )
          }

          <Button sx={{ alignSelf: 'flex-end', mt: 2 }} onClick={handleSave} disabled={isPushing}>
            { t('buttons.save') }
          </Button>
        </VStack>
      </Box>
    </Dialog>
  );
};

export default AdminUsersSubscriptionsDialog;
