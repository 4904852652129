import {
  Button, Dialog, MenuItem, TextField, Typography,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Address,
  AddressType, Country, SaveDealerAddressDto, useSaveDealerAddressMutation,
} from '../../../../../../graphql';
import useReactiveUserConnected from '../../../../../../hooks/data/useReactiveUserConnected.ts';
import CloseButton from '../../../../../ui-kit/buttons/CloseButton.tsx';
import DialogTitle from '../../../../../ui-kit/dialog/DialogTitle.tsx';
import HStack from '../../../../../ui-kit/layout/HStack.tsx';
import VStack from '../../../../../ui-kit/layout/VStack.tsx';

function createDto(address?: SaveDealerAddressDto): SaveDealerAddressDto {
  return {
    id: address?.id || null,
    type: address?.type || AddressType.Headquarter,
    street: address?.street || '',
    number: address?.number || '',
    city: address?.city || '',
    zip: address?.zip || '',
    country: address?.country || Country.Be,
  };
}

interface Props {
  address?: Address,
  onClose: () => void,
  onSaved: () => void,
}

const AddDealerAddressDialog = ({ address, onClose, onSaved }: Props) => {
  const { t } = useTranslation();
  const { refetchMe } = useReactiveUserConnected();
  const [ dto, setDto ] = useState<SaveDealerAddressDto>(createDto());
  const [ isPushing, setIsPushing ] = useState(false);
  const canSave = useMemo(
    () => !isPushing
      && dto.street !== ''
      && dto.number !== ''
      && dto.city !== ''
      && dto.zip !== '',
    [ dto, isPushing ],
  );
  const [ saveDealerAddress ] = useSaveDealerAddressMutation();

  const handleSave = async () => {
    if (!canSave) {
      return;
    }

    setIsPushing(true);

    await saveDealerAddress({ variables: { dto } });
    await refetchMe();

    setIsPushing(false);

    onSaved();
  };

  useEffect(() => {
    setDto(address ? createDto(address) : createDto());
  }, [ address ]);

  return (
    <Dialog
      id="add-dealer-address-dialog"
      onClose={onClose}
      open
      // @ts-ignore
      maxWidth="md"
    >
      <VStack p={5} width={425}>
        <HStack justifyContent="space-between" alignItems="center">
          <DialogTitle text={t('my-parameters.my-profile.add-address')} loading={false} />
          <CloseButton onClose={onClose} />
        </HStack>

        <HStack className="form-container" gap={2} mt={2}>
          <VStack flex={1}>
            <Typography fontWeight="bolder">
              {t('form.labels.address-type')}
              <span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextField
              select
              variant="outlined"
              size="small"
              sx={{ width: '100%' }}
              value={dto.type}
              onChange={({ target: { value: type } }) => setDto({ ...dto, type: type as AddressType })}
            >
              {
              [ AddressType.Headquarter, AddressType.Shipping ].map((type) => (
                <MenuItem value={type} key={type}>{t(`enums.address-types.${type}`)}</MenuItem>
              ))
            }
            </TextField>
          </VStack>
          <HStack flex={1} />
        </HStack>

        <HStack className="form-container" gap={2} mt={2}>
          <VStack flex={1}>
            <Typography fontWeight="bolder">
              {t('form.labels.street')}
              <span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              sx={{ width: '100%' }}
              value={dto.street}
              onChange={({ target: { value: street } }) => setDto({ ...dto, street })}
            />
          </VStack>
        </HStack>

        <HStack className="form-container" gap={2} mt={2}>
          <VStack flex={1}>
            <Typography fontWeight="bolder">
              {t('form.labels.number')}
              <span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              sx={{ width: '100%' }}
              value={dto.number}
              onChange={({ target: { value: number } }) => setDto({ ...dto, number })}
            />
          </VStack>
          <VStack flex={1} />
        </HStack>

        <HStack className="form-container" gap={2} mt={2}>
          <VStack flex={1}>
            <Typography fontWeight="bolder">
              {t('form.labels.city')}
              <span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              sx={{ width: '100%' }}
              value={dto.city}
              onChange={({ target: { value: city } }) => setDto({ ...dto, city })}
            />
          </VStack>
          <VStack className="form-container" flex={1}>
            <Typography fontWeight="bolder">
              {t('form.labels.zip')}
              <span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              sx={{ width: '100%' }}
              value={dto.zip}
              onChange={({ target: { value: zip } }) => setDto({ ...dto, zip })}
            />
          </VStack>
        </HStack>

        <HStack className="form-container" gap={2} mt={2}>
          <VStack flex={1}>
            <Typography fontWeight="bolder">
              {t('form.labels.country')}
              <span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextField
              select
              variant="outlined"
              size="small"
              sx={{ width: '100%' }}
              value={dto.country}
              onChange={({ target: { value: country } }) => setDto({ ...dto, country: country as Country })}
            >
              {
              [ Country.Be, Country.Fr, Country.Lu, Country.De, Country.Nl, Country.It, Country.Es, Country.Gr ].map((country) => (
                <MenuItem value={country} key={country}>{t(`enums.countries.${country}`)}</MenuItem>
              ))
            }
            </TextField>
          </VStack>
          <HStack flex={1} />
        </HStack>

        <HStack justifyContent="flex-end">
          <Button color="primary" variant="outlined" onClick={handleSave} disabled={!canSave}>{t('buttons.save')}</Button>
        </HStack>
      </VStack>
    </Dialog>
  );
};

export default AddDealerAddressDialog;
