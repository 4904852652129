import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDeleteUserAsAdminMutation, User } from '../../../../../../graphql';
import useUiToast from '../../../../../../hooks/useUiToast.ts';
import HStack from '../../../../../ui-kit/layout/HStack.tsx';
import VStack from '../../../../../ui-kit/layout/VStack.tsx';

interface Props {
  user: User,
  onClose: () => void,
  onSave: () => void,
}

const AdminUsersDeleteUserDialog = ({ user, onClose, onSave }: Props) => {
  const { t } = useTranslation();
  const [ deleteUser, { loading: isDeleting } ] = useDeleteUserAsAdminMutation();
  const { error: showError } = useUiToast();

  const handleDelete = async () => {
    try {
      await deleteUser({ variables: { id: user.id } });
      onSave();
    } catch (error) {
      showError((error as Error).message);
    }
  };

  return (
    <Dialog
      onClose={onClose}
      open
      maxWidth="md"
    >
      <Box p={5} width={600}>
        <HStack justifyContent="space-between" alignItems="center">
          <h2>Supprimer un utilisateur</h2>
          <Button size="small" onClick={onClose}>
            <CloseIcon fontSize="small" sx={{ top: -1, position: 'relative' }} />
            {' '}
            { t('buttons.close') }
          </Button>
        </HStack>
        <VStack justifyContent="center" mt={2}>
          <p>
            Vous êtes sur le point de supprimer un utilisateur (
            { `${user.firstName} ${user.lastName} - ${user.email}` }
            ).
          </p>
          <p>
            <strong>Attention</strong>
            , ceci supprimera toutes les éléments liés à cet utilisateur (votes, véhicules, favoris, offres, etc).
          </p>

          <HStack justifyContent="flex-end" gap={2}>
            <Button sx={{ alignSelf: 'flex-end', mt: 2 }} onClick={handleDelete} disabled={isDeleting} variant="contained" color="secondary">
              Je supprime tout
            </Button>
            <Button sx={{ alignSelf: 'flex-end', mt: 2 }} onClick={onClose}>
              Annuler
            </Button>
          </HStack>
        </VStack>
      </Box>
    </Dialog>
  );
};

export default AdminUsersDeleteUserDialog;
