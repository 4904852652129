import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import fr from './locales/fr.json';
import en from './locales/en.json';
import nl from './locales/nl.json';
import it from './locales/it.json';
import es from './locales/es.json';
import de from './locales/de.json';
import el from './locales/el.json';
import pl from './locales/pl.json';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      fr,
      en,
      nl,
      it,
      es,
      de,
      el,
      pl,
    },
    lng: 'fr',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    compatibilityJSON: 'v4',
  });

export default i18n;
