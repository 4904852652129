import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { acceptedPhotoTypes } from '../../../../../../config/constants.ts';
import { VehicleMediaTag, VehicleMediaType } from '../../../../../../graphql';
import { DroppedVehicleMediaFile, StateVehicleMediaFile } from '../../../../../../index';
import VehicleFormMediaDropdzone from './VehicleFormMediaDropdzone.tsx';
import HStack from '../../../../../ui-kit/layout/HStack.tsx';

interface Props {
  summary: string,
  acceptedTypes?: string[],
  mediaType?: VehicleMediaType,
  defaultExpanded?: boolean,
  medias: StateVehicleMediaFile[],
  tag: VehicleMediaTag,
  filterMedia: (media: StateVehicleMediaFile) => boolean,
  onDropFile: (droppedFile: DroppedVehicleMediaFile) => void,
  onDelete: (index: number) => void,
}

const VehicleFormTabMediaAccordion = ({
  summary, acceptedTypes = acceptedPhotoTypes, mediaType = VehicleMediaType.Image, defaultExpanded = false, medias, tag, filterMedia, onDropFile, onDelete,
}: Props) => (
  <Accordion defaultExpanded={defaultExpanded}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <h3 style={{ margin: 0 }}>{ summary }</h3>
    </AccordionSummary>
    <AccordionDetails>
      <HStack className="dropzones-container" flexWrap="wrap" gap={1}>
        {
          medias.map((media, index) => {
            if (!filterMedia(media)) {
              return undefined;
            }

            return (
              <VehicleFormMediaDropdzone
                acceptedTypes={acceptedTypes}
                tag={tag}
                mediaType={mediaType}
                mediaFile={media}
                index={index}
                onDropFile={onDropFile}
                key={String(`${tag}_${index}`)}
                onDelete={onDelete}
              />
            );
          })
        }
        <VehicleFormMediaDropdzone
          acceptedTypes={acceptedTypes}
          tag={tag}
          mediaType={mediaType}
          onDropFile={onDropFile}
        />
      </HStack>
    </AccordionDetails>
  </Accordion>
);

export default VehicleFormTabMediaAccordion;
