import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetOnePublicDealerQuery } from '../../../../../graphql';
import HStack from '../../../../ui-kit/layout/HStack.tsx';
import Page from '../../../../ui-kit/layout/Page.tsx';
import PageTitle from '../../../../ui-kit/layout/PageTitle.tsx';
import VehiclesList from '../../../partials/lists/vehicles/VehiclesList.tsx';

const DealerVehicles = () => {
  const { dealerId } = useParams<{ dealerId: string, }>();
  const [ isLoading, setIsLoading ] = useState(true);
  const { data, loading } = useGetOnePublicDealerQuery({ variables: { dealerId: Number(dealerId) } });

  return (
    <Page>
      <PageTitle
        text={(
          <HStack gap={0.5}>
            { data?.publicDealer.name }
            <span style={{ fontSize: 16, fontWeight: 'bold' }}>{`${data ? ` (${data.publicDealer.numberOfPublishedVehicles})` : ''}`}</span>
          </HStack>
        )}
        loading={isLoading || loading}
      />

      <VehiclesList listType="dealer" onLoading={setIsLoading} dataFetchingDto={{ dealerId: Number(dealerId) }} />
    </Page>
  );
};

export default DealerVehicles;
