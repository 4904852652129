import { useState } from 'react';

export default function useImageLoader(stateEnabled: boolean = true) {
  const [ imageHtmlElement, setImageHtmlElement ] = useState<HTMLImageElement|null>(null);
  const [ imageFile, setImageFile ] = useState<File|null>(null);

  async function loadImageFromFile(file: File): Promise<{ element: HTMLImageElement, }> {
    return new Promise((resolve) => {
      const fileReader = new FileReader();

      fileReader.onload = function (event) {
        const htmlImageElement = new Image();

        htmlImageElement.onload = (imageEvent: any) => {
          const element = imageEvent.target as HTMLImageElement;

          if (stateEnabled) {
            setImageHtmlElement(element);
            setImageFile(file);
          }

          resolve({ element });
        };

        htmlImageElement.src = event.target!.result as string;
      };

      fileReader.readAsDataURL(file);
    });
  }

  async function loadImageFromUri(fileUri: string): Promise<{ element: HTMLImageElement, file: File, }> {
    const htmlImageElement = new Image();
    const blob = await (await fetch(fileUri, {})).blob();

    return new Promise((resolve) => {
      htmlImageElement.onload = async (imageEvent: any) => {
        const element = imageEvent.target as HTMLImageElement;
        const file = new File([ blob ], fileUri);

        if (stateEnabled) {
          setImageHtmlElement(imageEvent.target as HTMLImageElement);
          setImageFile(new File([ blob ], fileUri));
        }

        resolve({ element, file });
      };

      htmlImageElement.src = fileUri;
    });
  }

  return {
    imageHtmlElement,
    imageFile,
    loadImageFromFile,
    loadImageFromUri,
  };
}
