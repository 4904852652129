import {
  Alert, Button, Dialog, TextField,
} from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Offer,
  OfferType,
  SaveOfferDto,
  useGetMyOffersForVehiclesQuery,
  useSaveOfferMutation,
  Vehicle,
  VehicleStatus,
} from '../../../../../../graphql';
import useReactiveAttributes from '../../../../../../hooks/data/useReactiveAttributes.ts';
import useReactiveUserConnected from '../../../../../../hooks/data/useReactiveUserConnected.ts';
import useVehicleOffers from '../../../../../../hooks/data/useVehicleOffers.ts';
import CloseButton from '../../../../../ui-kit/buttons/CloseButton.tsx';
import DialogTitle from '../../../../../ui-kit/dialog/DialogTitle.tsx';
import HStack from '../../../../../ui-kit/layout/HStack.tsx';
import VStack from '../../../../../ui-kit/layout/VStack.tsx';
import OfferCard from '../../../cards/OfferCard.tsx';

interface Props {
  vehicle: Vehicle,
  type: OfferType,
  onClose: () => void,
  onSaved: () => void,
}

const MakeOfferDialog = ({
  vehicle, type, onClose, onSaved,
}: Props) => {
  const { t } = useTranslation();
  const { user } = useReactiveUserConnected();
  const { findAttributesByInternalCodes } = useReactiveAttributes();
  const [ dto, setDto ] = useState<SaveOfferDto>({
    id: null, type, amount: type === OfferType.Normal ? 0 : vehicle.immediatePurchase.price, vehicleId: vehicle.id,
  });
  const { data: offersData, loading: isLoadingOffer } = useGetMyOffersForVehiclesQuery({
    variables: { vehicleIds: [ vehicle.id ] },
  });
  const [ saveOffer, { loading: isSaving } ] = useSaveOfferMutation();
  const {
    hasOfferAccepted, hasOfferDeclined, hasOfferPending, latestNormalOffer, latestNormalOfferStatus, latestImmediatePurchaseOffer,
  } = useVehicleOffers(offersData ? offersData.myOffersForVehicles as Offer[] : []);
  const canSave = useMemo(
    () => !isSaving && (!latestNormalOffer || (dto.amount && dto.amount > latestNormalOffer.amount)),
    [ isSaving, dto, latestNormalOffer ],
  );
  const latestActiveOffer = useMemo(
    () => (offersData?.myOffersForVehicles || [])
      .slice()
      .filter((offer) => offer.type === type && offer.statuses!.slice().pop()!.type === 'WAITING')
      .pop(),
    [ offersData ],
  );
  const isDeductible = useMemo(
    () => vehicle.attributes!.find((attribute) => attribute.attributeId === findAttributesByInternalCodes([ 'isDeductible' ])[0]!.id)!.value === true,
    [ vehicle ],
  );

  const handleSave = async () => {
    await saveOffer({ variables: { dto } });

    onSaved();
  };

  const getDialogTitle = () => {
    if (hasOfferAccepted) {
      return t('dialogs.offers.accepted-offer');
    }

    if (hasOfferDeclined) {
      return t('dialogs.offers.declined-offer');
    }

    if (vehicle.status !== VehicleStatus.Published) {
      return t('buttons.see-my-offer');
    }

    if (type === OfferType.Normal) {
      return latestNormalOffer ? t('dialogs.titles.update-offer') : t('dialogs.titles.make-offer');
    }

    return t('dialogs.titles.immediate-purchase');
  };

  useEffect(() => {
    if (hasOfferPending && latestNormalOffer && type === OfferType.Normal) {
      setDto({
        id: latestNormalOffer.id, amount: latestNormalOffer.amount, vehicleId: vehicle.id, type,
      });
    }

    if (type === OfferType.ImmediatePurchase) {
      setDto({
        ...dto, amount: vehicle.immediatePurchase.price,
      });
    }
  }, [ hasOfferPending, latestNormalOffer ]);

  return (
    <Dialog
      id="make-offer-dialog"
      onClose={onClose}
      open
      // @ts-ignore
      maxWidth="md"
    >
      <VStack width={425} p={5}>
        <HStack justifyContent="space-between" alignItems="center">
          <DialogTitle loading={isLoadingOffer || isSaving} text={getDialogTitle()} />
          <CloseButton onClose={onClose} />
        </HStack>

        { type === OfferType.ImmediatePurchase && <Alert severity="error" sx={{ mb: 2 }}>{t('dialogs.alerts.careful-you-are-going-to-purchase-vehicle')}</Alert> }

        {
          vehicle.status === VehicleStatus.Published && latestNormalOffer && !hasOfferDeclined && !hasOfferAccepted && (
            <Alert severity="info" sx={{ mb: 2 }}>{t('dialogs.alerts.you-cannot-lower-your-offer-amount')}</Alert>
          )
        }

        { isDeductible && !hasOfferDeclined && type === OfferType.Normal && <Alert severity="warning" sx={{ mb: 2 }}>Ce véhicule est soumis au régime de la TVA récupérable</Alert> }

        {
          !hasOfferDeclined && !hasOfferAccepted && (
            <VStack>
              <HStack>
                {t('dialogs.offer.amount-of-offer')}
                { ' ' }
                (
                {isDeductible ? t('my-vehicle.vat-excluded') : t('my-vehicle.vat-included')}
                )
                <span style={{ color: 'red' }}>*</span>
              </HStack>
              <TextField
                type="text"
                variant="outlined"
                size="small"
                value={dto.amount && dto.amount > 0 ? dto.amount : ''}
                disabled={type === OfferType.ImmediatePurchase || vehicle.status !== VehicleStatus.Published}
                onChange={({ target: { value } }) => setDto({ ...dto, amount: Number(value) })}
                sx={{ width: '100%', mb: 2 }}
              />
            </VStack>
          )
        }

        {
          hasOfferAccepted && (latestNormalOffer || latestImmediatePurchaseOffer) && (
            <>
              <Alert sx={{ mb: 2 }}>
                {t('dialogs.offer.this-vehicle-will-available-at')}
                { ' ' }
                <strong>{ dayjs(vehicle.availableAt).format('DD/MM/YYYY') }</strong>
              </Alert>
              <OfferCard offer={{ ...(latestNormalOffer ?? latestImmediatePurchaseOffer) as Offer, user }} onSaved={() => {}} />
            </>
          )
        }

        {
          hasOfferDeclined && latestNormalOffer && latestNormalOfferStatus && (
            <>
              {
                latestNormalOfferStatus.reason && latestNormalOfferStatus.reason !== '' && (
                  <Alert sx={{ mb: 2 }} severity="error">
                    <strong>{t('dialogs.offer.seller-has-sent-a-comment')}</strong>
                    <br />
                    { t(`messages.${latestNormalOfferStatus.reason}`) !== `messages.${latestNormalOfferStatus.reason}` ? t(`messages.${latestNormalOfferStatus.reason}`) : latestNormalOfferStatus.reason }
                  </Alert>
                )
              }
              <OfferCard offer={{ ...latestNormalOffer as Offer, user }} onSaved={() => {}} />
            </>
          )
        }

        {
          vehicle.status === VehicleStatus.Published && !isLoadingOffer && !hasOfferDeclined && !hasOfferAccepted && (
            <HStack justifyContent="flex-end" mt={2}>
              {
                type === OfferType.Normal && (
                  <Button size="small" onClick={handleSave} disabled={!canSave}>{latestActiveOffer ? t('buttons.update') : t('buttons.validate')}</Button>
                )
              }

              {
                type === OfferType.ImmediatePurchase && (
                  <Button size="small" onClick={handleSave} disabled={isSaving}>{t('buttons.purchase')}</Button>
                )
              }
            </HStack>
          )
        }
      </VStack>
    </Dialog>
  );
};

export default MakeOfferDialog;
