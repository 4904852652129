import {
  Alert, Button, Checkbox, Dialog, FormControlLabel, Typography,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFinalizeVehicleSaleMutation, Vehicle } from '../../../../../../graphql';
import useLocalStorage from '../../../../../../hooks/useLocalStorage.ts';
import useUiToast from '../../../../../../hooks/useUiToast.ts';
import CloseButton from '../../../../../ui-kit/buttons/CloseButton.tsx';
import DialogTitle from '../../../../../ui-kit/dialog/DialogTitle.tsx';
import HStack from '../../../../../ui-kit/layout/HStack.tsx';
import VStack from '../../../../../ui-kit/layout/VStack.tsx';

interface Props {
  vehicle: Vehicle,
  onClose: () => void,
  onSaved: () => void,
}

const FinalizeSaleDialog = ({
  vehicle, onClose, onSaved,
}: Props) => {
  const { t } = useTranslation();
  const [ hideDialog, setHideDialog ] = useState(false);
  const { getObject, setObject } = useLocalStorage();
  const { success } = useUiToast();
  const [ finalizeVehicle ] = useFinalizeVehicleSaleMutation();

  const finalizeSale = async () => {
    await finalizeVehicle({ variables: { vehicleId: vehicle.id } });

    const preferences = getObject('preferences');

    preferences.showFinalizeVehicleSaleDialog = !hideDialog;

    setObject('preferences', preferences);

    onClose();
    onSaved();

    success(t('toasts.sale-finalized'));
  };

  return (
    <Dialog
      onClose={onClose}
      open
      // @ts-ignore
      maxWidth="md"
    >
      <VStack p={5} width={425}>
        <HStack justifyContent="space-between" alignItems="center">
          <DialogTitle text={t('dialogs.finalize-vehicle-sale.title')} loading={false} />
          <CloseButton onClose={onClose} />
        </HStack>

        <Alert severity="warning">
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: t('dialogs.finalize-vehicle-sale.alert-text') }} />
        </Alert>

        <FormControlLabel
          control={(
            <Checkbox
              size="small"
              onChange={(_, checked) => setHideDialog(checked)}
            />
          )}
          label={<Typography fontSize={12}>{t('messages.do-not-show-again')}</Typography>}
          sx={{ mt: 2 }}
        />

        <HStack justifyContent="flex-end" mt={2}>
          <Button onClick={finalizeSale}>{t('buttons.confirm')}</Button>
        </HStack>
      </VStack>
    </Dialog>
  );
};

export default FinalizeSaleDialog;
