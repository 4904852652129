import { ReactNode, useEffect } from 'react';
import useReactiveUserConnected from '../../hooks/data/useReactiveUserConnected.ts';
import useAppInitialization from '../../hooks/useAppInitialization.ts';
import PageLoader from '../ui-kit/utils/PageLoader.tsx';

interface Props {
  children: ReactNode,
}

const PublicGuard = ({ children }: Props) => {
  const { initializeApplication, isAppInitialized } = useAppInitialization(true);
  const { isConnected } = useReactiveUserConnected();

  useEffect(() => {
    if (isConnected) {
      return;
    }

    initializeApplication();
  }, [ isConnected ]);

  if (!isAppInitialized) {
    return <PageLoader />;
  }

  return (
    <>
      { children }
    </>
  );
};

export default PublicGuard;
