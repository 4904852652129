import {
  Checkbox, FormControlLabel, Switch, TextField, Typography,
} from '@mui/material';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import HStack from '../../../../../ui-kit/layout/HStack.tsx';
import VStack from '../../../../../ui-kit/layout/VStack.tsx';
import { SaveableVehicleContext } from '../SaveableVehicleContext.ts';

const VehicleFormImmediatePurchase = () => {
  const { t } = useTranslation();
  const { immediatePurchase: dto, setImmediatePurchase: setDto } = useContext(SaveableVehicleContext);

  useEffect(() => {

  }, []);

  return (
    <VStack mb={2}>
      <HStack>
        {t('my-vehicle.immediate-purchase-enabled')}
      </HStack>
      <HStack gap={1} alignItems="center">
        <Typography>{t('form.labels.no')}</Typography>
        <Switch
          checked={dto.enabled}
          onChange={(_event, enabled) => setDto({ ...dto, enabled })}
        />
        <Typography>{t('form.labels.yes')}</Typography>
      </HStack>

      {
        dto.enabled && (
          <>
            <HStack mt={2}>
              {t('my-vehicle.purchase-price-in-euro')}
              { ' ' }
              (
              { dto.isVatExcluded ? t('my-vehicle.vat-excluded') : t('my-vehicle.vat-included') }
              )
            </HStack>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              sx={{ maxWidth: 320 }}
              value={String(dto.price)}
              onChange={({ target: { value } }) => setDto({ ...dto, price: Number(value) })}
              placeholder={t('my-vehicle.purchase-price-in-euro')}
            />
            <FormControlLabel
              control={<Checkbox checked={dto.isVatExcluded} onChange={(_event, isVatExcluded) => setDto({ ...dto, isVatExcluded })} />}
              label={t('my-vehicle.vat-excluded-price')}
            />
          </>
        )
      }
    </VStack>
  );
};

export default VehicleFormImmediatePurchase;
