import { ReactNode, useMemo, useState } from 'react';
import ForgottenPasswordDialog from '../pages/partials/dialogs/forgotten-password/ForgottenPasswordDialog.tsx';
import LoginDialog from '../pages/partials/dialogs/login/LoginDialog.tsx';
import { OfflineDialogsContext, OfflineDialogsContextProps } from './OfflineDialogsContext.ts';

interface Props {
  children: ReactNode,
}

const OfflineDialogsProvider = ({ children }: Props) => {
  const [ isLoginDialogOpen, setIsLoginDialogOpen ] = useState(false);
  const [ isForgottenPasswordDialogOpen, setIsForgottenPasswordDialogOpen ] = useState(false);

  const context: OfflineDialogsContextProps = useMemo(() => ({
    openLoginDialog: () => setIsLoginDialogOpen(true),
    openRegisterDialog: () => setIsLoginDialogOpen(true),
    openForgottenPasswordDialog: () => setIsForgottenPasswordDialogOpen(true),
  }), []);

  return (
    <OfflineDialogsContext.Provider value={context}>
      { children }

      { isLoginDialogOpen && <LoginDialog onClose={() => setIsLoginDialogOpen(false)} /> }
      { isForgottenPasswordDialogOpen && <ForgottenPasswordDialog onClose={() => setIsForgottenPasswordDialogOpen(false)} /> }
    </OfflineDialogsContext.Provider>
  );
};

export default OfflineDialogsProvider;
