import { MenuItem, Select } from '@mui/material';
import { useState } from 'react';
import { Attribute } from '../../../../../../graphql';
import useLang from '../../../../../../hooks/useLang.ts';

interface Props {
  attribute: Attribute,
  onChange: (attributeValueId: number|null) => void,
}

const AttributeSelectFilter = ({ attribute, onChange }: Props) => {
  const lang = useLang();
  const [ value, setValue ] = useState<any>(0);

  return (
    <Select
      displayEmpty
      size="small"
      value={value}
      onChange={({ target: { value: selectedValue } }) => {
        setValue(Number(selectedValue));
        onChange(selectedValue ? Number(selectedValue) : null);
      }}
      MenuProps={{ sx: { maxHeight: 500 } }}
    >
      <MenuItem value={0}>{ attribute.name[lang] }</MenuItem>
      {
        attribute.values?.map((option) => (
          <MenuItem value={option.id} key={option.id}>{ option.value[lang] }</MenuItem>
        ))
      }
    </Select>
  );
};

export default AttributeSelectFilter;
