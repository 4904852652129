import {
  Avatar,
  IconButton, Paper, Rating, Typography,
} from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import { useNavigate } from 'react-router-dom';
import { User } from '../../../../graphql';
import HStack from '../../../ui-kit/layout/HStack';
import VStack from '../../../ui-kit/layout/VStack';

interface Props {
  user: User,
  ratingEnabled?: boolean,
  topTitle?: string,
}

const DealerCard = ({
  user, ratingEnabled = false, topTitle = undefined,
}: Props) => {
  const navigate = useNavigate();

  return (
    <Paper
      sx={{
        display: 'flex', flexDirection: 'column', gap: 2, mb: 2, padding: 2, cursor: 'pointer',
      }}
      onClick={() => navigate(`/dealers/${user.dealer!.id}`)}
      elevation={1}
    >
      <HStack alignItems="center" justifyContent="space-between" flex={1} gap={2}>
        <HStack flex={1} gap={2}>
          <HStack width={40} height={40} bgcolor="primary.main" borderRadius="100%" justifyContent="center" alignItems="center">
            { user.dealer && user.dealer.logo && <Avatar src={user.dealer.logo.uri} sx={{ width: 50, height: 50 }} /> }
            { user.dealer && !user.dealer.logo && <Typography fontWeight="bolder" color="white">{ `${user.dealer!.name[0]}` }</Typography> }
          </HStack>
          <VStack>
            <Typography fontWeight="bolder">{topTitle ?? user.dealer!.name}</Typography>

            {
              ratingEnabled && (
                <Rating size="small" name="read-only" value={user.averageRatingScore} precision={0.5} readOnly />
              )
            }
          </VStack>
        </HStack>

        {
          user.phoneNumber && (
            <a href={`tel:${user.phoneNumber}`} aria-label="Appeler la personne de contact">
              <IconButton color="primary">
                <PhoneIcon />
              </IconButton>
            </a>
          )
        }
      </HStack>
    </Paper>
  );
};

export default DealerCard;
