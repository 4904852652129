import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { IconButton } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { colors } from '../../../config/theming.ts';
import { inArray } from '../../../helpers/array.ts';
import useImageLoader from '../../../hooks/useImageLoader.tsx';
import VStack from '../layout/VStack.tsx';

interface Props {
  uri?: string|null,
  onFileDropped?: (file: File) => void,
}

const DroppableImageInput = ({ uri = null, onFileDropped }: Props) => {
  const inputRef = useRef<any>();
  const [ fileToUpload, setFileUpload ] = useState<File|null>(null);
  const [ photoUriToShow, setPhotoUriToShow ] = useState<string|null>(uri);
  const { loadImageFromFile } = useImageLoader(false);

  const handleDrop = async (event: any) => {
    if (
      event.target.files
      && event.target.files.length
      && inArray(event.target.files[0].name.split('.').pop(), [ 'jpg', 'png', 'jpeg', 'webp' ])
      && event.target.files[0].size <= 8 * 1024 * 1024
    ) {
      setFileUpload(event.target.files[0]);
      setPhotoUriToShow((await loadImageFromFile(event.target.files[0])).element.src);
      inputRef.current.value = null;
    }
  };

  useEffect(() => {
    if (fileToUpload) {
      onFileDropped?.(fileToUpload);
    }
  }, [ fileToUpload ]);

  useEffect(() => {
    setPhotoUriToShow(uri);
  }, [ uri ]);

  return (
    <VStack position="relative" width={150}>
      <img
        src={photoUriToShow ?? '/assets/images/default-logo-dealer.png'}
        alt="Logo de l'entreprise"
        width={150}
        height={150}
      />

      <IconButton
        sx={{
          position: 'absolute',
          bottom: -8,
          right: -8,
          background: `${colors.secondary.main} !important`,
        }}
        size="small"
        onClick={() => inputRef.current.click()}
      >
        <ModeEditIcon sx={{ color: 'white' }} />
      </IconButton>
      <input
        type="file"
        style={{
          width: 0,
          height: 0,
        }}
        ref={inputRef}
        onChange={handleDrop}
      />
    </VStack>
  );
};

export default DroppableImageInput;
