import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Button, Chip, Menu, MenuItem, Tooltip,
} from '@mui/material';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { useContext, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  useGetAllUsersForAdminQuery, User, UserSubscription, UserSubscriptionStatus,
} from '../../../../../graphql';
import useLoginUnderUser from '../../../../../hooks/useLoginUnderUser.ts';
import HStack from '../../../../ui-kit/layout/HStack.tsx';
import Page from '../../../../ui-kit/layout/Page.tsx';
import { AdminUsersContext, AdminUsersContextProps } from './AdminUsersContext.ts';
import AdminUsersDeleteUserDialog from './dialog-delete-user/AdminUsersDeleteUserDialog.tsx';
import AdminUsersSubscriptionsDialog from './dialog-subscriptions/AdminUsersSubscriptionsDialog.tsx';
import AdminUserDetailsDialog from './dialog-user-details/AdminUsersSubscriptionsDialog.tsx';

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'email',
    headerName: 'Utilisateur',
    width: 400,
    editable: false,
    renderCell: (params: GridRenderCellParams<any, any, any>) => (
      <div>
        <strong>
          { params.row.firstName }
          {' '}
          { params.row.lastName }
        </strong>
        <br />
        <span style={{ fontSize: '.7rem' }}>{ params.row.email }</span>
      </div>
    ),
  },
  {
    field: 'dealername',
    headerName: 'Dealer',
    flex: 1,
    editable: false,
    renderCell: (params: GridRenderCellParams<any, any, any>) => (
      <div>
        <strong>
          { params.row.dealer?.name }
        </strong>
        <br />
        <span style={{ fontSize: '.7rem' }}>{ params.row.dealer?.email }</span>
      </div>
    ),
  },
  {
    field: 'dealerType',
    headerName: 'Abonnement',
    width: 200,
    editable: false,
    renderCell: (params: GridRenderCellParams<User, any, any>) => {
      const hasSellerSubscription = params.row.latestSellerSubscription;
      const hasClientSubscription = params.row.latestClientSubscription;

      const getChipsProps = (userSubscription: UserSubscription): any => {
        if (userSubscription.status === 'ACTIVE') {
          return { variant: 'outlined', color: 'success' };
        }

        if (userSubscription.status === 'WAITING') {
          return { variant: 'outlined', color: 'warning' };
        }

        return { variant: 'outlined', color: 'default' };
      };

      const getTooltipTitle = (userSubscription: UserSubscription): any => {
        if (userSubscription.status === 'ACTIVE' && userSubscription.endAt) {
          return `Abonnement en cours jusqu'au ${dayjs(userSubscription.endAt).format('DD/MM/YYYY')}`;
        }

        if (userSubscription.status === 'WAITING') {
          return 'Abonnement en attente';
        }

        return 'Abonnement inactif';
      };

      return (
        <HStack alignItems="center" gap={1} flexWrap="wrap">
          {
            hasClientSubscription && (
              <Tooltip title={getTooltipTitle(params.row.latestClientSubscription as UserSubscription)}>
                <Chip label="Client" {...getChipsProps(params.row.latestClientSubscription as UserSubscription)} />
              </Tooltip>
            )
          }
          {
            hasSellerSubscription && (
              <Tooltip title={getTooltipTitle(params.row.latestSellerSubscription as UserSubscription)}>
                <Chip label="Vendeur" {...getChipsProps(params.row.latestSellerSubscription as UserSubscription)} />
              </Tooltip>
            )
          }
        </HStack>
      );
    },
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 100,
    headerAlign: 'center',
    align: 'center',
    editable: false,
    renderCell: (params: GridRenderCellParams<User, any, any>) => {
      const { openDialog, loginUnderUser } = useContext<AdminUsersContextProps>(AdminUsersContext);
      const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>(null);
      const open = Boolean(anchorEl);
      const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
      };
      const handleClose = () => {
        setAnchorEl(null);
      };

      return (
        <div>
          <Button onClick={handleClick}>
            <MoreVertIcon />
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={() => {
              loginUnderUser(params.row);
              handleClose();
            }}
            >
              Se connecter sous...
            </MenuItem>
            <MenuItem onClick={() => {
              openDialog('details', params.row);
              handleClose();
            }}
            >
              Voir les informations
            </MenuItem>
            <MenuItem onClick={() => {
              openDialog('subscriptions', params.row);
              handleClose();
            }}
            >
              Gérer les abonnements
            </MenuItem>
            <MenuItem onClick={() => {
              openDialog('delete', params.row);
              handleClose();
            }}
            >
              Supprimer cet utilisateur
            </MenuItem>
          </Menu>
        </div>
      );
    },
  },
];

const AdminUsers = () => {
  const { data, refetch } = useGetAllUsersForAdminQuery();
  const [ selectedUser, setSelectedUser ] = useState<User|null>(null);
  const [ openedDialog, setOpenedDialog ] = useState<false|string>(false);
  const [ showUsersWithSubscriptionRequestOnly, setShowUsersWithSubscriptionRequestOnly ] = useState(false);
  const { loginUnderUser } = useLoginUnderUser();
  const navigate = useNavigate();
  const filteredUsers: User[] = useMemo(() => {
    if (!showUsersWithSubscriptionRequestOnly) {
      return (data?.getAllUsersForAdmin || []) as User[];
    }

    return ((data?.getAllUsersForAdmin as User[]|undefined) || []).filter((user: User) => showUsersWithSubscriptionRequestOnly
      && (
        (user.latestClientSubscription && user.latestClientSubscription.status === UserSubscriptionStatus.Waiting)
        || (user.latestSellerSubscription && user.latestSellerSubscription.status === UserSubscriptionStatus.Waiting))) as User[];
  }, [ data, showUsersWithSubscriptionRequestOnly ]);

  const resetModal = () => {
    setSelectedUser(null);
    setOpenedDialog(false);
  };

  const context: AdminUsersContextProps = useMemo(() => ({
    selectedUser,
    openDialog: (dialog, user) => {
      setOpenedDialog(dialog);
      setSelectedUser(user);
    },
    loginUnderUser: async (user) => {
      await loginUnderUser(user.id);
      navigate('/parameters');
    },
  }), [ selectedUser, openedDialog ]);

  return (
    <AdminUsersContext.Provider value={context}>
      <Page>
        <HStack alignItems="center">
          <KeyboardArrowLeftIcon />
          <Link
            to="/admin"
            style={{
              color: 'initial',
              textDecoration: 'none',
            }}
          >
            Revenir au dashboard
          </Link>
        </HStack>

        <HStack alignItems="center" gap={2} mb={1}>
          <h1>Gestion des utilisateurs</h1>
        </HStack>

        <Button
          color={showUsersWithSubscriptionRequestOnly ? 'secondary' : 'primary'}
          variant="outlined"
          size="small"
          onClick={() => setShowUsersWithSubscriptionRequestOnly(!showUsersWithSubscriptionRequestOnly)}
        >
          Afficher uniquement les demandes en attente
        </Button>

        <Box
          mt={2}
          sx={{
            width: '100%',
          }}
        >
          <DataGrid<User>
            rows={filteredUsers}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 30,
                },
              },
            }}
            pageSizeOptions={[ 30 ]}
            disableRowSelectionOnClick
          />
        </Box>

        {
          openedDialog === 'subscriptions' && selectedUser && (
            <AdminUsersSubscriptionsDialog
              user={selectedUser}
              onClose={resetModal}
              onSave={async () => {
                resetModal();
                await refetch();
              }}
            />
          )
        }

        {
          openedDialog === 'details' && selectedUser && (
            <AdminUserDetailsDialog
              user={selectedUser}
              onClose={resetModal}
            />
          )
        }

        {
          openedDialog === 'delete' && selectedUser && (
            <AdminUsersDeleteUserDialog
              user={selectedUser}
              onClose={resetModal}
              onSave={async () => {
                resetModal();
                await refetch();
              }}
            />
          )
        }
      </Page>
    </AdminUsersContext.Provider>
  );
};

export default AdminUsers;
