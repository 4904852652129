import { useReactiveVar } from '@apollo/client';
import TaskIcon from '@mui/icons-material/Task';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import PublishIcon from '@mui/icons-material/Publish';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import StarRateIcon from '@mui/icons-material/StarRate';
import {
  Button, ButtonGroup, Tooltip, Typography,
} from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  isLoggedUnderUserVar, Offer, Vehicle, VehicleStatus,
} from '../../../../../graphql';
import { inArray } from '../../../../../helpers/array.ts';
import useReactiveUserConnected from '../../../../../hooks/data/useReactiveUserConnected.ts';
import useVehicleOffers from '../../../../../hooks/data/useVehicleOffers.ts';
import { VehicleActions, VehicleListTypes } from '../../../../../index';
import HStack from '../../../../ui-kit/layout/HStack.tsx';

interface Props {
  listType: VehicleListTypes,
  vehicle: Vehicle,
  offers: Offer[],
  onClick: (action: VehicleActions, vehicle: Vehicle) => void,
}

const VehiclesListActionButtons = ({
  listType, vehicle, offers, onClick,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const vehicleOffers = useMemo(() => offers.filter((offer) => offer.vehicleId === vehicle.id), [ offers ]);
  const { user, isClient } = useReactiveUserConnected();
  const isLoggedUnderUser = useReactiveVar(isLoggedUnderUserVar);
  const {
    hasOfferPending, hasOfferAccepted, hasOfferDeclined, hasOfferWon, latestNormalOffer, latestImmediatePurchaseOffer,
  } = useVehicleOffers(vehicleOffers);

  const getOfferIconColor = () => {
    if (hasOfferAccepted) {
      return 'success';
    }

    if (hasOfferDeclined) {
      return 'error';
    }

    return 'primary';
  };

  const getOfferTextColor = () => {
    if (hasOfferAccepted) {
      return 'green';
    }

    if (hasOfferDeclined) {
      return 'red';
    }

    return 'primary';
  };

  const getOfferText = () => {
    if (hasOfferPending) {
      return t('buttons.see-my-offer');
    }

    if (hasOfferAccepted) {
      return t('dialogs.offers.accepted-offer');
    }

    if (hasOfferDeclined) {
      return t('dialogs.offers.declined-offer');
    }

    return '';
  };

  if (!isClient && listType !== 'my-vehicles') {
    return (
      <HStack justifyContent="center" sx={{ position: 'absolute', bottom: 10, width: '100%' }}>
        <Tooltip title={t('buttons.see-vehicle')}>
          <Button
            size="small"
            variant="contained"
            color="inherit"
            onClick={() => onClick('openVehicleDetails', vehicle)}
            sx={{ border: 'thin solid #030b21' }}
          >
            <img src="/assets/images/icons/see-vehicle.png" width={18} height={18} />
          </Button>
        </Tooltip>
      </HStack>
    );
  }

  return (
    <HStack justifyContent="center" sx={{ position: 'absolute', bottom: 10, width: '100%' }}>
      <ButtonGroup>
        {
          listType === 'my-vehicles' && vehicle.status === VehicleStatus.Validated && (
            <Tooltip title={t('buttons.finalize-vehicle-sale')}>
              <Button
                size="small"
                variant="contained"
                color="inherit"
                onClick={() => onClick('finalizeSale', vehicle)}
                sx={{ border: 'thin solid #030b21', color: 'green' }}
              >
                <CheckCircleIcon sx={{ fontSize: 18 }} />
              </Button>
            </Tooltip>
          )
        }

        <Tooltip title={t('buttons.see-vehicle')}>
          <Button
            size="small"
            variant="contained"
            color="inherit"
            onClick={() => onClick('openVehicleDetails', vehicle)}
            sx={{ border: 'thin solid #030b21' }}
          >
            <img src="/assets/images/icons/see-vehicle.png" width={18} height={18} />
          </Button>
        </Tooltip>

        {
          listType === 'my-vehicles' && (isLoggedUnderUser || vehicle.status === VehicleStatus.Draft) && (
            <Tooltip title={t('buttons.edit')}>
              <Button
                size="small"
                variant="contained"
                color="inherit"
                onClick={() => navigate(`/my-vehicles/${vehicle.id}/edit`)}
                sx={{ border: 'thin solid #030b21' }}
              >
                <EditIcon sx={{ fontSize: 18 }} />
              </Button>
            </Tooltip>
          )
        }

        {
          listType === 'my-vehicles' && vehicle.status === VehicleStatus.Draft && (
            <Tooltip title={t('buttons.publish')}>
              <Button
                size="small"
                variant="contained"
                color="inherit"
                onClick={() => onClick('publish', vehicle)}
                sx={{ border: 'thin solid #030b21' }}
              >
                <PublishIcon sx={{ fontSize: 18 }} />
              </Button>
            </Tooltip>
          )
        }

        {
          listType === 'my-vehicles' && vehicle.status === VehicleStatus.Expired && (
            <Tooltip title={t('buttons.republish')}>
              <Button
                size="small"
                variant="contained"
                color="inherit"
                onClick={() => onClick('republish', vehicle)}
                sx={{ border: 'thin solid #030b21' }}
              >
                <PublishIcon sx={{ fontSize: 18 }} />
              </Button>
            </Tooltip>
          )
        }

        {
          listType === 'my-vehicles'
          && (
            (vehicle.numberOfOffers > 0 && !inArray(vehicle.status, [ VehicleStatus.Draft, VehicleStatus.Validated ]))
            || vehicle.status === VehicleStatus.Validated
          ) && (
            <Tooltip title={vehicle.status === VehicleStatus.Validated ? t('buttons.see-accepted-offer') : t('buttons.see-offers')}>
              <Button
                size="small"
                variant="contained"
                color="inherit"
                onClick={() => onClick('manageOffers', vehicle)}
                sx={{ border: 'thin solid #030b21' }}
              >
                <RequestQuoteIcon sx={{ fontSize: 18 }} />
              </Button>
            </Tooltip>
          )
        }

        {
          listType === 'my-vehicles' && vehicle.status === VehicleStatus.Finished && vehicle!.rating!.isBuyerRated === false && (
            <Tooltip title={t('buttons.rate-buyer')}>
              <Button
                size="small"
                variant="contained"
                color="inherit"
                onClick={() => onClick('rate', vehicle)}
                sx={{ border: 'thin solid #030b21' }}
              >
                <StarRateIcon sx={{ fontSize: 18 }} />
              </Button>
            </Tooltip>
          )
        }

        {
          (listType === 'my-vehicles' || (listType === 'my-offers' && hasOfferWon)) && vehicle.status === VehicleStatus.Finished && (
            <Tooltip title={t('tooltips.open-order-form')}>
              <Button
                size="small"
                variant="contained"
                color="inherit"
                onClick={() => onClick('openOrderForm', vehicle)}
                sx={{ border: 'thin solid #030b21' }}
              >
                <TaskIcon sx={{ fontSize: 18 }} />
              </Button>
            </Tooltip>
          )
        }

        {
          listType === 'my-vehicles' && vehicle.status !== VehicleStatus.Finished && vehicle.status !== VehicleStatus.Deleted && (
            <Tooltip title={vehicle.status === 'DRAFT' ? t('buttons.delete') : t('buttons.cancel-the-sale')}>
              <Button
                size="small"
                variant="contained"
                color="inherit"
                onClick={() => onClick('cancelSale', vehicle)}
                sx={{ border: 'thin solid #030b21', color: 'red' }}
              >
                <CancelIcon sx={{ fontSize: 18 }} />
              </Button>
            </Tooltip>
          )
        }

        {
          vehicle.status === VehicleStatus.Published && vehicle.addedByUserId !== user?.id && !hasOfferDeclined && !hasOfferAccepted && !hasOfferPending && (
            <Tooltip title={t('buttons.make-offer')}>
              <Button
                size="small"
                variant="contained"
                color="inherit"
                onClick={() => onClick('makeOffer', vehicle)}
                sx={{ border: 'thin solid #030b21' }}
              >
                <RequestQuoteIcon sx={{ fontSize: 18 }} />
              </Button>
            </Tooltip>
          )
        }

        {
          vehicle.status === VehicleStatus.Published && vehicle.immediatePurchase.enabled && vehicle.addedByUserId !== user?.id && !hasOfferAccepted && !hasOfferDeclined && (
            <Tooltip title={t('buttons.purchase-now')}>
              <Button
                size="small"
                variant="contained"
                color="inherit"
                onClick={() => onClick('purchase', vehicle)}
                sx={{ border: 'thin solid #030b21' }}
              >
                <HStack alignItems="center" gap={0.5}>
                  <ShoppingCartIcon color="secondary" sx={{ fontSize: 18 }} />
                  <Typography color="secondary" fontSize={12}>
                    (
                    { vehicle.immediatePurchase.price }
                    €)
                  </Typography>
                </HStack>
              </Button>
            </Tooltip>
          )
        }

        {
          vehicle.addedByUserId !== user?.id && (hasOfferPending || hasOfferAccepted || hasOfferDeclined) && (
            <Tooltip title={getOfferText()}>
              <Button
                size="small"
                variant="contained"
                color="inherit"
                onClick={() => onClick('makeOffer', vehicle)}
                sx={{ border: 'thin solid #030b21' }}
              >
                <HStack alignItems="center" gap={0.5}>
                  <RequestQuoteIcon color={getOfferIconColor()} sx={{ fontSize: 18 }} />
                  <Typography color={getOfferTextColor()} fontSize={12}>
                    (
                    {latestNormalOffer ? latestNormalOffer.amount : latestImmediatePurchaseOffer!.amount}
                    €)
                  </Typography>
                </HStack>
              </Button>
            </Tooltip>
          )
        }
      </ButtonGroup>
    </HStack>
  );
};

export default VehiclesListActionButtons;
