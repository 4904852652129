import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import { Chip, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import HStack from '../../../ui-kit/layout/HStack.tsx';

interface Props {
  count: number,
}

const VehicleOffersCountChip = ({ count }: Props) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={t('tooltips.number-of-offers')} placement="top">
      <Chip
        sx={{
          position: 'absolute',
          top: 10,
          left: 10,
          bgcolor: 'primary.main',
          color: 'white',
        }}
        label={(
          <HStack alignItems="center" gap={0.5}>
            <RequestQuoteIcon style={{ fontSize: 18 }} />
            <span style={{ fontSize: 15 }}>
              { count }
            </span>
          </HStack>
        )}
        variant="filled"
      />
    </Tooltip>
  );
};

export default VehicleOffersCountChip;
