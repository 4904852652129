import {
  isLoggedUnderUserVar,
  useLoginUnderUserLazyQuery, useMeLazyQuery, User, userConnected,
} from '../graphql';
import useLocalStorage from './useLocalStorage.ts';

export default function useLoginUnderUser() {
  const { get, set, remove } = useLocalStorage();
  const [ authenticateUnderUser, { loading: isLogging } ] = useLoginUnderUserLazyQuery();
  const [ fetchCurrentUser ] = useMeLazyQuery();

  const loginUnderUser = async (id: number) => {
    const { data } = await authenticateUnderUser({ variables: { id } });

    if (!data) {
      return;
    }

    set('backup-bearer-token', get('bearer-token'));
    set('bearer-token', data.loginUnderUser);

    isLoggedUnderUserVar(true);

    const { data: fetchedCurrentUser } = await fetchCurrentUser();

    if (fetchedCurrentUser) {
      userConnected(fetchedCurrentUser.getLoggedUser as User);
    }
  };

  const loginBackToAdmin = async () => {
    set('bearer-token', get('backup-bearer-token'));

    remove('backup-bearer-token');

    isLoggedUnderUserVar(false);

    document.location.reload();
  };

  return {
    loginUnderUser,
    loginBackToAdmin,
    isLogging,
  };
}
