import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PageOffline from './ui-kit/layout/PageOffline.tsx';

const PageNotFound = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <PageOffline>
      <h1>{t('errors.page-not-found')}</h1>

      <Button onClick={() => navigate(-1)}>{t('buttons.go-back')}</Button>
    </PageOffline>
  );
};

export default PageNotFound;
