import { Logout, Settings } from '@mui/icons-material';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import AppsIcon from '@mui/icons-material/Apps';
import BallotIcon from '@mui/icons-material/Ballot';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import FavoriteIcon from '@mui/icons-material/Favorite';
import GarageOutlinedIcon from '@mui/icons-material/GarageOutlined';
import HistoryIcon from '@mui/icons-material/History';
import StarIcon from '@mui/icons-material/StarPurple500';
import {
  Avatar, Divider, IconButton, ListItemIcon, Menu, MenuItem, Typography,
} from '@mui/material';
import { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import useReactiveUserConnected from '../../../../hooks/data/useReactiveUserConnected.ts';
import useLogout from '../../../../hooks/useLogout.ts';

const HeaderMainMenu = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    isSeller, isClient, dealer, isAdmin,
  } = useReactiveUserConnected();
  const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const logout = useLogout();
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        onClick={handleClick}
        size="small"
        sx={{ ml: 2 }}
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <Avatar src={dealer && dealer.logo ? dealer.logo.uri : ''} sx={{ width: 50, height: 50 }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <div className="all-menu">
          {
            isClient && (
              <>
                <MenuItem onClick={() => navigate('/online/marketplace')}>
                  <ListItemIcon>
                    <DirectionsCarIcon />
                  </ListItemIcon>
                  <Typography>{t('menu.marketplace')}</Typography>
                </MenuItem>
                <MenuItem onClick={() => navigate('/offers')}>
                  <ListItemIcon>
                    <BallotIcon />
                  </ListItemIcon>
                  <Typography>{t('menu.my-offers')}</Typography>
                </MenuItem>
                <MenuItem onClick={() => navigate('/favorites')}>
                  <ListItemIcon>
                    <FavoriteIcon />
                  </ListItemIcon>
                  <Typography>{t('menu.my-favorites')}</Typography>
                </MenuItem>
                <MenuItem onClick={() => navigate('/history')}>
                  <ListItemIcon>
                    <HistoryIcon />
                  </ListItemIcon>
                  <Typography>{t('menu.my-history')}</Typography>
                </MenuItem>
              </>
            )
          }
          {
            !isClient && (
              <MenuItem onClick={() => navigate('/parameters/profile')}>
                <ListItemIcon>
                  <StarIcon sx={{ color: 'gold' }} />
                </ListItemIcon>
                <Typography>{t('menu.become-client')}</Typography>
              </MenuItem>
            )
          }
          {
            !isSeller && (
              <MenuItem onClick={() => navigate('/parameters/profile')}>
                <ListItemIcon>
                  <StarIcon sx={{ color: 'gold' }} />
                </ListItemIcon>
                <Typography>{t('menu.become-dealer')}</Typography>
              </MenuItem>
            )
          }
          {
            isSeller && (
              <>
                <MenuItem onClick={() => navigate('/my-vehicles/add')}>
                  <ListItemIcon>
                    <AddBoxOutlinedIcon />
                  </ListItemIcon>
                  <Typography>{t('menu.add-vehicle')}</Typography>
                </MenuItem>
                <MenuItem onClick={() => navigate('/my-vehicles')}>
                  <ListItemIcon>
                    <GarageOutlinedIcon />
                  </ListItemIcon>
                  <Typography>{t('menu.my-vehicles')}</Typography>
                </MenuItem>
              </>
            )
          }
          <Divider />
        </div>
        {
          isAdmin && (
            <Link to="/admin" style={{ color: 'initial', textDecoration: 'initial' }}>
              <MenuItem onClick={handleClose}>
                <ListItemIcon>
                  <AppsIcon fontSize="small" />
                </ListItemIcon>
                {t('menu.administration')}
              </MenuItem>
            </Link>
          )
        }
        <MenuItem onClick={() => navigate('/parameters')}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          {t('menu.my-parameters')}
        </MenuItem>
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          {t('buttons.logout')}
        </MenuItem>
      </Menu>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <div className="all-menu">
          <MenuItem onClick={() => navigate('/online/marketplace')}>
            <ListItemIcon>
              <DirectionsCarIcon />
            </ListItemIcon>
            <Typography>{t('menu.marketplace')}</Typography>
          </MenuItem>

          {
          isClient && (
            <>
              <MenuItem onClick={() => navigate('/offers')}>
                <ListItemIcon>
                  <BallotIcon />
                </ListItemIcon>
                <Typography>{t('menu.my-offers')}</Typography>
              </MenuItem>
              <MenuItem onClick={() => navigate('/favorites')}>
                <ListItemIcon>
                  <FavoriteIcon />
                </ListItemIcon>
                <Typography>{t('menu.my-favorites')}</Typography>
              </MenuItem>
              <MenuItem onClick={() => navigate('/history')}>
                <ListItemIcon>
                  <HistoryIcon />
                </ListItemIcon>
                <Typography>{t('menu.my-history')}</Typography>
              </MenuItem>
            </>
          )
        }
          {
          !isClient && (
            <MenuItem onClick={() => navigate('/parameters/profile')}>
              <ListItemIcon>
                <StarIcon sx={{ color: 'gold' }} />
              </ListItemIcon>
              <Typography>{t('menu.become-client')}</Typography>
            </MenuItem>
          )
        }
          {
          !isSeller && (
            <MenuItem onClick={() => navigate('/parameters/profile')}>
              <ListItemIcon>
                <StarIcon sx={{ color: 'gold' }} />
              </ListItemIcon>
              <Typography>{t('menu.become-dealer')}</Typography>
            </MenuItem>
          )
        }
          {
          isSeller && (
            <>
              <MenuItem onClick={() => navigate('/my-vehicles/add')}>
                <ListItemIcon>
                  <AddBoxOutlinedIcon />
                </ListItemIcon>
                <Typography>{t('menu.add-vehicle')}</Typography>
              </MenuItem>
              <MenuItem onClick={() => navigate('/my-vehicles')}>
                <ListItemIcon>
                  <GarageOutlinedIcon />
                </ListItemIcon>
                <Typography>{t('menu.my-vehicles')}</Typography>
              </MenuItem>
            </>
          )
        }
          <Divider />
        </div>
        {
        isAdmin && (
          <Link to="/admin" style={{ color: 'initial', textDecoration: 'initial' }}>
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <AppsIcon fontSize="small" />
              </ListItemIcon>
              {t('menu.administration')}
            </MenuItem>
          </Link>
        )
      }
        <MenuItem onClick={() => navigate('/parameters')}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          {t('menu.my-parameters')}
        </MenuItem>
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          {t('buttons.logout')}
        </MenuItem>
      </Menu>
    </>
  );
};

export default HeaderMainMenu;
