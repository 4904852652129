import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  RecoverPasswordDto,
  useAskRecoveryPasswordCodeMutation,
  useRecoverPasswordMutation,
  useValidateRecoveryPasswordCodeLazyQuery,
} from '../../../../../graphql';
import useLogin from '../../../../../hooks/useLogin.tsx';
import HStack from '../../../../ui-kit/layout/HStack.tsx';
import VStack from '../../../../ui-kit/layout/VStack.tsx';
import ForgottenPasswordFormCodeInputs from './ForgottenPasswordFormCodeInputs.tsx';

interface Props {
  onCancel: () => void,
  onLoggedIn: () => void,
}

const ForgottenPasswordForm = ({ onCancel, onLoggedIn }: Props) => {
  const { t } = useTranslation();
  const [ dto, setDto ] = useState<Omit<RecoverPasswordDto, 'code'> & { code: number, }>({ email: '', code: 0, password: '' });
  const [ error, setError ] = useState<null|string>(null);
  const [ showPassword, setShowPassword ] = useState(false);
  const [ step, setStep ] = useState(1);
  const [ askCode, { data: askPasswordRecoveryCodeData, loading: isAskingRecoveryPassword } ] = useAskRecoveryPasswordCodeMutation();
  const [ validateCode, { data: validatePasswordRecoveryCodeData, loading: isValidatingRecoveryPassword } ] = useValidateRecoveryPasswordCodeLazyQuery();
  const [ recoverPassword, { loading: isRecoveringPassword, data: recoverPasswordData } ] = useRecoverPasswordMutation();
  const { fullLogin, isFullLogging } = useLogin();

  useEffect(() => {
    if (askPasswordRecoveryCodeData) {
      setStep(2);
    }
  }, [ askPasswordRecoveryCodeData, validatePasswordRecoveryCodeData ]);

  useEffect(() => {
    if (validatePasswordRecoveryCodeData) {
      setStep(3);
    }
  }, [ validatePasswordRecoveryCodeData ]);

  useEffect(() => {
    if (recoverPasswordData) {
      setStep(4);
    }
  }, [ recoverPasswordData ]);

  return (
    <>
      {
        step === 1 && (
          <>
            <Typography variant="h3" component="h2" sx={{ mb: 2 }} textAlign="center">{ t('offline.forgotten-password.step-1.title') }</Typography>
            <Typography variant="body1" component="p" sx={{ mb: 2, width: '100%', maxWidth: 600 }} textAlign="center">
              { t('offline.forgotten-password.step-1.sub-title') }
            </Typography>
            {
              error && (
                <Alert severity="error" sx={{ width: '100%', maxWidth: 600, mb: 5 }}>
                  <strong>Oops.</strong>
                  {' '}
                  { t(`homepage.forgotten-password.errors.${error}`) }
                </Alert>
              )
            }
            <TextField
              label={t('form.labels.email')}
              variant="outlined"
              sx={{ mb: 2, width: '100%', maxWidth: 600 }}
              onChange={({ target: { value: email } }) => setDto({ ...dto, email })}
            />
            <HStack justifyContent="flex-end" mt={2} gap={2}>
              <Button variant="outlined" onClick={onCancel}>{ t('buttons.go-back') }</Button>
              <Button
                variant="contained"
                disabled={isAskingRecoveryPassword || dto.email === ''}
                onClick={async () => {
                  const { errors } = await askCode({ variables: { email: dto.email } });

                  if (errors && errors.length) {
                    setError(errors[0].message);
                  }

                  setError(null);
                }}
              >
                { t('buttons.send-me-code') }
              </Button>
            </HStack>
          </>
        )
      }

      {
        step === 2 && (
          <>
            <Typography variant="h3" component="h2" sx={{ mb: 2 }} textAlign="center">{ t('offline.forgotten-password.step-2.title') }</Typography>
            <Typography variant="body1" component="p" sx={{ mb: 2, width: '100%', maxWidth: 600 }} textAlign="center">
              { t('offline.forgotten-password.step-2.sub-title') }
            </Typography>
            {
              error && (
                <Alert severity="error" sx={{ width: '100%', maxWidth: 600, mb: 5 }}>
                  <strong>Oops.</strong>
                  {' '}
                  { t(`homepage.forgotten-password.errors.${error}`) }
                </Alert>
              )
            }
            <ForgottenPasswordFormCodeInputs onCodeChange={(code) => setDto({ ...dto, code })} />
            <HStack justifyContent="flex-end" mt={2} gap={2}>
              <Button variant="outlined" onClick={() => setStep(1)}>{ t('buttons.go-back') }</Button>
              <Button
                variant="contained"
                disabled={isAskingRecoveryPassword || isValidatingRecoveryPassword || dto.email === '' || dto.code === 0}
                onClick={async () => {
                  const { error: apiError } = await validateCode({ variables: { dto: { email: dto.email, code: dto.code } } });

                  if (apiError) {
                    setError(apiError.message);
                  }

                  setError(null);
                }}
              >
                { t('buttons.confirm') }
              </Button>
            </HStack>
          </>
        )
      }

      {
        step === 3 && validatePasswordRecoveryCodeData && validatePasswordRecoveryCodeData.validateRecoveryPasswordCode.id && (
          <>
            <Typography variant="h3" component="h2" sx={{ mb: 2 }} textAlign="center">{ t('offline.forgotten-password.step-3.title') }</Typography>
            <Typography variant="body1" component="p" sx={{ mb: 2, width: '100%', maxWidth: 600 }} textAlign="center">
              { t('offline.forgotten-password.step-3.sub-title') }
            </Typography>

            {
              error && (
                <Alert severity="error" sx={{ width: '100%', maxWidth: 600, mb: 5 }}>
                  <strong>Oops.</strong>
                  {' '}
                  { t(`homepage.forgotten-password.errors.${error}`) }
                </Alert>
              )
            }

            <FormControl id="recover-password-input-container" sx={{ m: 1, width: '100%', maxWidth: 600 }} variant="outlined">
              <InputLabel htmlFor="recover-password-input">{ t('form.labels.password') }</InputLabel>
              <OutlinedInput
                id="recover-password-input"
                type={showPassword ? 'text' : 'password'}
                onChange={({ target: { value: password } }) => setDto({ ...dto, password })}
                endAdornment={(
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={() => {}}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )}
                label="Password"
              />
            </FormControl>
            <HStack justifyContent="flex-end" mt={2} gap={2}>
              <Button variant="outlined" disabled={false} onClick={onCancel}>{ t('buttons.cancel') }</Button>
              <Button
                variant="contained"
                disabled={isAskingRecoveryPassword || isValidatingRecoveryPassword || isRecoveringPassword || dto.email === '' || dto.code === 0 || dto.password === ''}
                onClick={async () => {
                  const { errors } = await recoverPassword({ variables: { dto } });

                  if (errors && errors[0]) {
                    setError(errors[0].message);
                  }

                  setError(null);
                }}
              >
                { t('buttons.confirm') }
              </Button>
            </HStack>
          </>
        )
      }

      {
        step === 4 && (
          <VStack gap={1}>
            <Typography variant="h3" component="h2" sx={{ mb: 2 }} textAlign="center">{ t('offline.forgotten-password.step-4.title') }</Typography>

            <Alert severity="success" sx={{ width: '100%', maxWidth: 600, mb: 5 }}>
              <strong>{ t('offline.forgotten-password.its-done') }</strong>
              <br />
              { t('offline.forgotten-password.success') }
            </Alert>

            <HStack justifyContent="flex-end">
              <LoadingButton
                loading={isFullLogging}
                variant="contained"
                onClick={async () => {
                  if (await fullLogin(dto.email, dto.password)) {
                    onLoggedIn();
                  }
                }}
              >
                {t('buttons.login')}
              </LoadingButton>
            </HStack>
          </VStack>
        )
      }
    </>
  );
};

export default ForgottenPasswordForm;
