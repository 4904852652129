export default function useLocalStorage() {
  const get = (key: string, defaultValue?: any) => {
    const value = localStorage.getItem(`${import.meta.env.VITE_WEBAPP_LOCAL_STORAGE_KEYNAME}:${key}`);

    if (defaultValue !== undefined && value === null) {
      return defaultValue;
    }

    return value;
  };

  const set = (key: string, value: any) => {
    localStorage.setItem(`${import.meta.env.VITE_WEBAPP_LOCAL_STORAGE_KEYNAME}:${key}`, value);
  };

  const remove = (key: string) => {
    localStorage.removeItem(`${import.meta.env.VITE_WEBAPP_LOCAL_STORAGE_KEYNAME}:${key}`);
  };

  const getObject = (key: string): Record<any, any> => JSON.parse(get(key, null) ?? '{}');

  const setObject = (key: string, value: Record<any, any>) => {
    set(key, JSON.stringify(value));
  };

  return {
    get, set, getObject, setObject, remove,
  };
}
