const CarVerticalBanner = () => (
  <a
    href="https://www.carvertical.com/be/fr/landing/v3?utm_source=aff&a=autoconnect6040&b=5d2de125"
    target="_blank"
    rel="noreferrer"
  >
    <img
      src="/assets/images/carvertical/fr.png"
      alt="carvertical"
      title="carvertical"
      style={{ maxWidth: '100%' }}
    />
  </a>
);

export default CarVerticalBanner;
