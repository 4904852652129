import { Context, createContext } from 'react';
import { Vehicle } from '../../graphql';

export interface VehicleDetailsDialogContextProps {
  openDialogByVehicleId: (vehicleId: number) => void,
  openDialogByVehicle: (vehicle: Vehicle) => void,
}

export const VehicleDetailsDialogContext: Context<VehicleDetailsDialogContextProps> = createContext<VehicleDetailsDialogContextProps>({
  openDialogByVehicleId: () => {},
  openDialogByVehicle: () => {},
});
