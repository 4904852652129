import { Button, Modal } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { SaveBrandDto, useSaveBrandMutation } from '../../../../../../../../graphql';
import HStack from '../../../../../../../ui-kit/layout/HStack.tsx';
import VStack from '../../../../../../../ui-kit/layout/VStack.tsx';
import SaveBrandModalInputName from './SaveBrandModalInputName.tsx';

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '20px',
  left: '50%',
  transform: 'translate(-50%, 0%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 3,
  marginBottom: 20,
  overflowY: 'auto',
  p: 4,
};

interface Props {
  defaultDto: SaveBrandDto,
  onClose: () => void,
}

const SaveBrandModal = ({ defaultDto, onClose }: Props) => {
  const [ dto, setDto ] = useState(defaultDto);
  const [ saveDto ] = useSaveBrandMutation();

  const handleSave = async () => {
    await saveDto({ variables: { dto } });

    onClose();
  };

  return (
    <Modal
      open
      onClose={onClose}
    >
      <VStack sx={{
        width: '100%', height: 'auto', overflowY: 'auto', position: 'relative', minHeight: '100vh',
      }}
      >
        <VStack sx={modalStyle} gap={1}>
          <Typography id="modal-modal-title" variant="h5" component="h5" mb={2}>Ajouter une marque</Typography>
          <SaveBrandModalInputName dto={dto} onChange={setDto} />
          <HStack justifyContent="flex-end" mt={4} gap={1}>
            <Button variant="outlined" color="secondary" onClick={handleSave}>Sauvegarder</Button>
            <Button variant="outlined" onClick={onClose}>Fermer</Button>
          </HStack>
        </VStack>
      </VStack>
    </Modal>
  );
};

export default SaveBrandModal;
