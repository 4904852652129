import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PeopleIcon from '@mui/icons-material/People';
import {
  Accordion, AccordionDetails, AccordionSummary, Button,
} from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { DealerContact } from '../../../../../../graphql';
import useReactiveUserConnected from '../../../../../../hooks/data/useReactiveUserConnected.ts';
import HStack from '../../../../../ui-kit/layout/HStack.tsx';
import VStack from '../../../../../ui-kit/layout/VStack.tsx';

const columns: (t: any) => GridColDef[] = (t) => [
  {
    field: 'lastName',
    headerName: t('dictionary.contact'),
    flex: 1,
    editable: false,
    renderCell: (params: GridRenderCellParams<any, any, any>) => (
      <VStack my={1}>
        <strong>
          { params.row.firstName }
          {' '}
          { params.row.lastName }
        </strong>
        <span style={{ fontSize: '.7rem' }}>{ params.row.email }</span>
        <span style={{ fontSize: '.7rem' }}>{ params.row.phoneNumber }</span>
      </VStack>
    ),
  },
];

interface Props {
  onAddButtonClick: () => void,
}

const DealerContactsAccordion = ({ onAddButtonClick }: Props) => {
  const { t } = useTranslation();
  const { dealer } = useReactiveUserConnected();

  return (
    <Accordion sx={{ maxWidth: 800 }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <HStack gap={1} alignItems="center">
          <PeopleIcon />
          {t('my-parameters.my-profile.dealer-contact-peoples')}
        </HStack>
      </AccordionSummary>
      <AccordionDetails>
        {
          dealer && dealer.contacts && dealer.contacts.length > 0 && (
            <DataGrid<DealerContact>
              rows={dealer.contacts}
              columns={columns(t)}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 50,
                  },
                },
              }}
              pageSizeOptions={[ 50 ]}
              hideFooter
              disableColumnSelector
              disableRowSelectionOnClick
              disableDensitySelector
              sx={{ mb: 2 }}
              getRowHeight={() => 'auto'}
            />
          )
        }

        <HStack justifyContent="flex-end">
          <Button color="primary" variant="outlined" onClick={onAddButtonClick}>{t('my-parameters.my-profile.add-dealer-contact')}</Button>
        </HStack>
      </AccordionDetails>
    </Accordion>
  );
};

export default DealerContactsAccordion;
