import { Tab, Tabs } from '@mui/material';
import * as React from 'react';
import { ReactNode, useState } from 'react';

interface Props {
  tabs: ReactNode[],
  defaultOpenedTab?: number,
  onChangeTab: (tabIndex: number) => void,
}

const UiTabs = ({ tabs, defaultOpenedTab = 0, onChangeTab }: Props) => {
  const [ value, setValue ] = useState(defaultOpenedTab);

  const handleChange = (_event: React.SyntheticEvent, newValue: any) => {
    setValue(newValue);
    onChangeTab(newValue);
  };

  return (
    <Tabs value={value} onChange={handleChange}>
      {
        tabs.map((tab, index) => (
          <Tab label={tab} key={String(index)} />
        ))
      }
    </Tabs>
  );
};

export default UiTabs;
