import { Checkbox } from '@mui/material';
import { useContext, useMemo } from 'react';
import { Damage } from '../../../../../../graphql';
import { filter } from '../../../../../../helpers/array.ts';
import useLang from '../../../../../../hooks/useLang.ts';
import HStack from '../../../../../ui-kit/layout/HStack.tsx';
import { SaveableVehicleContext } from '../SaveableVehicleContext.ts';

interface Props {
  damages: Damage[],
}

const VehicleFormDamagesList = ({ damages }: Props) => {
  const { selectedDamageIds, setSelectedDamageIds } = useContext(SaveableVehicleContext);
  const lang = useLang();
  const sortedDamages = useMemo(() => [ ...damages ].filter((damage) => !damage.dealerId).sort(
    (damageA, damageB) => damageA.name[lang].localeCompare(damageB.name[lang]),
  ), [ damages, lang ]);

  return (
    <HStack flexWrap="wrap" gap="1rem">
      {
        sortedDamages.map((damage) => {
          const isSelected = selectedDamageIds.includes(damage.id);
          const onChange = () => setSelectedDamageIds(isSelected ? filter(selectedDamageIds, damage.id) : [ ...selectedDamageIds, damage.id ]);

          return (
            <HStack
              key={damage.id}
              onClick={onChange}
              style={{ width: 'calc(50% - 10px)', cursor: 'pointer' }}
              alignItems="center"
            >
              <Checkbox checked={isSelected} onClick={onChange} />
              { damage.name[lang] }
            </HStack>
          );
        })
      }
    </HStack>
  );
};

export default VehicleFormDamagesList;
