import { ReactNode, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useReactiveUserConnected from '../../hooks/data/useReactiveUserConnected.ts';
import PageLoader from '../ui-kit/utils/PageLoader.tsx';

interface Props {
  children: ReactNode,
}

const LoggedInGuard = ({ children }: Props) => {
  const { isConnected, user } = useReactiveUserConnected();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!location.pathname.includes('/register') && user && !user.validatedAt) {
      navigate('/validate-account');
    }

    if (!isConnected) {
      navigate('/login');
    }
  }, [ isConnected, user ]);

  if (!isConnected || (user && !user.validatedAt)) {
    return <PageLoader />;
  }

  return (
    <>
      { children }
    </>
  );
};

export default LoggedInGuard;
