import { ReactNode } from 'react';
import WheelLoader from '../utils/WheelLoader.tsx';

interface Props {
  text: ReactNode,
  loading?: boolean,
}

const DialogTitle = ({ text, loading = false }: Props) => (
  <h2 className="h-stack align-items-center">
    {
      loading && <WheelLoader width={30} height={30} showText={false} style={{ marginRight: 10 }} />
    }

    { text }
  </h2>
);

export default DialogTitle;
