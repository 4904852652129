import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WarningIcon from '@mui/icons-material/Warning';
import {
  Accordion, AccordionDetails, AccordionSummary, Alert,
} from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { acceptedPhotoTypes, acceptedVideoTypes, uniqueVehicleMediaTags } from '../../../../../../config/constants.ts';
import { VehicleMediaTag, VehicleMediaType } from '../../../../../../graphql';
import { DroppedVehicleMediaFile } from '../../../../../../index';
import HStack from '../../../../../ui-kit/layout/HStack.tsx';
import VStack from '../../../../../ui-kit/layout/VStack.tsx';
import { SaveableVehicleContext } from '../SaveableVehicleContext.ts';
import VehicleFormMediaDropdzone from './VehicleFormMediaDropdzone.tsx';
import VehicleFormTabMediaAccordion from './VehicleFormTabMediaAccordion.tsx';

interface Props {
  onDropFile: (droppedFile: DroppedVehicleMediaFile) => void,
  onDelete: (index: number) => void,
}

const VehicleFormTabMedias = ({ onDropFile, onDelete }: Props) => {
  const { medias: files, selectedEquipmentIds, selectedDamageIds } = useContext(SaveableVehicleContext);
  const { t } = useTranslation();

  return (
    <VStack>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <h3 style={{ margin: 0 }}>{t('my-vehicle.exterior-photos')}</h3>
        </AccordionSummary>
        <AccordionDetails>
          <Alert sx={{ mb: 2 }} color="warning" icon={<WarningIcon />}>
            {t('my-vehicle.you-must-add-all-exterior-photos')}
          </Alert>
          <HStack className="dropzones-container" flexWrap="wrap" gap={1}>
            {
            uniqueVehicleMediaTags.map((tag) => {
              const taggedFileIndex = files.findIndex((file) => file.tag === tag);
              const media = files[taggedFileIndex] ?? null;

              return (
                <VehicleFormMediaDropdzone
                  label={t(`enums.${tag}`)}
                  acceptedTypes={acceptedPhotoTypes}
                  tag={tag}
                  mediaFile={media}
                  index={taggedFileIndex}
                  onDropFile={onDropFile}
                  onDelete={onDelete}
                  key={tag}
                />
              );
            })
          }
          </HStack>
        </AccordionDetails>
      </Accordion>
      <VehicleFormTabMediaAccordion
        medias={files}
        tag={VehicleMediaTag.Interior}
        summary={t('my-vehicle.interior-photos')}
        onDelete={onDelete}
        onDropFile={onDropFile}
        filterMedia={(media) => media.tag === VehicleMediaTag.Interior}
      />
      {
        selectedEquipmentIds.length > 0 && (
          <VehicleFormTabMediaAccordion
            medias={files}
            tag={VehicleMediaTag.Equipment}
            summary={t('my-vehicle.equipments-photos')}
            onDelete={onDelete}
            onDropFile={onDropFile}
            filterMedia={(media) => media.tag === VehicleMediaTag.Equipment}
          />
        )
      }
      {
        selectedDamageIds.length > 0 && (
          <VehicleFormTabMediaAccordion
            medias={files}
            tag={VehicleMediaTag.Damage}
            summary={t('my-vehicle.damages-photos')}
            onDelete={onDelete}
            onDropFile={onDropFile}
            filterMedia={(media) => media.tag === VehicleMediaTag.Damage}
          />
        )
      }
      <VehicleFormTabMediaAccordion
        medias={files}
        acceptedTypes={acceptedVideoTypes}
        tag={VehicleMediaTag.None}
        mediaType={VehicleMediaType.Video}
        summary={t('dictionary.videos')}
        onDelete={onDelete}
        onDropFile={onDropFile}
        filterMedia={(media) => media.type === VehicleMediaType.Video}
      />
    </VStack>
  );
};

export default VehicleFormTabMedias;
