import { Alert } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetMyDealerQuery } from '../../../../../graphql';
import useReactiveUserConnected from '../../../../../hooks/data/useReactiveUserConnected.ts';
import AddressesAccordion from './accordion-addresses/AddressesAccordion.tsx';
import DealerContactsAccordion from './accordion-dealer-contacts/DealerContactsAccordion.tsx';
import DealerInformationAccordion from './accordion-dealer-information/DealerInformationAccordion.tsx';
import MyInformationAccordion from './accordion-my-information/MyInformationAccordion.tsx';
import AddDealerAddressDialog from './dialog-save-dealer-address/AddDealerAddressDialog.tsx';
import SaveDealerContactDialog from './dialog-save-dealer-contact/SaveDealerContactDialog.tsx';
import MyProfileBecomeClient from './MyProfileBecomeClient.tsx';
import MyProfileBecomeSeller from './MyProfileBecomeSeller.tsx';

interface Props {
  onLoading: (loading: boolean) => void,
}

const MyProfile = ({ onLoading }: Props) => {
  const { t } = useTranslation();
  const {
    isSeller, isClient, isUserDataValid, refetchMe,
  } = useReactiveUserConnected();
  const [ isAddDealerAddressDialog, setIsAddDealerAddressDialog ] = useState(false);
  const [ isAddDealerContactDialog, setIsAddDealerContactDialog ] = useState(false);
  const { loading } = useGetMyDealerQuery();

  useEffect(() => {
    onLoading(loading);
  }, [ loading ]);

  useEffect(() => {
    refetchMe();
  }, []);

  return (
    <div id="my-profile">
      {
        !isUserDataValid && (
          <Alert severity="error" sx={{ maxWidth: 750, mb: 4 }}>
            {/* eslint-disable-next-line react/no-danger */}
            <div dangerouslySetInnerHTML={{ __html: t('errors.incomplete-profile') }} />
          </Alert>
        )
      }

      { !isClient && (<MyProfileBecomeClient onLoading={onLoading} />) }
      { !isSeller && (<MyProfileBecomeSeller onLoading={onLoading} />) }

      <MyInformationAccordion onLoading={onLoading} />
      <DealerInformationAccordion onLoading={onLoading} />
      <DealerContactsAccordion onAddButtonClick={() => setIsAddDealerContactDialog(true)} />
      <AddressesAccordion onAddButtonClick={() => setIsAddDealerAddressDialog(true)} />

      {
        isAddDealerAddressDialog && (
          <AddDealerAddressDialog onSaved={() => setIsAddDealerAddressDialog(false)} onClose={() => setIsAddDealerAddressDialog(false)} />
        )
      }

      {
        isAddDealerContactDialog && (
          <SaveDealerContactDialog onSaved={() => setIsAddDealerContactDialog(false)} onClose={() => setIsAddDealerContactDialog(false)} />
        )
      }
    </div>
  );
};

export default MyProfile;
