import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import { IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  Damage, SaveDamageDto,
  useDeleteDamageMutation,
  useSaveDamageMutation,
} from '../../../../../../graphql';

function createSaveDamageDto(damage?: Damage): SaveDamageDto {
  return {
    id: damage?.id || null,
    name: damage?.name || { fr: '' },
  };
}

interface Props {
  damage?: Damage,
  isAdding: boolean,
  onSaved: () => void,
}

const SaveDamageInput = ({
  damage, isAdding, onSaved,
}: Props) => {
  const [ dto, setDto ] = useState(createSaveDamageDto(damage));
  const [ saveDto ] = useSaveDamageMutation();
  const [ deleteDamage ] = useDeleteDamageMutation();

  const handleAdd = async () => {
    await saveDto({ variables: { dto } });

    onSaved();

    if (isAdding) {
      setDto(createSaveDamageDto());
    }
  };

  const handleEdit = async () => {
    if (damage && damage.name.fr === dto.name.fr) {
      return;
    }

    await saveDto({ variables: { dto } });

    onSaved();
  };

  const handleDelete = async () => {
    await deleteDamage({ variables: { id: damage!.id } });

    onSaved();
  };

  useEffect(() => {
    setDto(createSaveDamageDto(damage));
  }, [ damage ]);

  return (
    <OutlinedInput
      size="small"
      onChange={({ target: { value } }) => setDto({ ...dto, name: { fr: value } })}
      value={dto.name.fr}
      onKeyUp={(event) => {
        if (event.key !== 'Enter') {
          return;
        }

        if (isAdding) {
          handleAdd();
        }

        if (!isAdding) {
          handleEdit();
        }
      }}
      placeholder="Ajouter une valeur"
      endAdornment={(
        <InputAdornment position="end">
          { !damage && <IconButton onClick={handleAdd} edge="end"><AddIcon /></IconButton> }
          {
            damage && dto.name !== damage.name && (
              <IconButton onClick={handleEdit} edge="end"><DoneIcon /></IconButton>
            )
          }
          {
            damage && dto.name === damage.name && (
              <IconButton onClick={handleDelete} edge="end"><ClearIcon /></IconButton>
            )
          }
        </InputAdornment>
      )}
    />
  );
};

export default SaveDamageInput;
