import {
  Button, FormControl, FormControlLabel, InputLabel, MenuItem, Modal, Select, Switch,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import {
  attributeCategoriesVar,
  AttributeType,
  SaveAttributeDto,
  useSaveAttributeMutation,
} from '../../../../../../../../graphql';
import HStack from '../../../../../../../ui-kit/layout/HStack.tsx';
import VStack from '../../../../../../../ui-kit/layout/VStack.tsx';
import SaveAttributeModalInputName from './SaveAttributeModalInputName.tsx';

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '20px',
  left: '50%',
  transform: 'translate(-50%, 0%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 3,
  marginBottom: 20,
  overflowY: 'auto',
  p: 4,
};

interface Props {
  defaultDto: SaveAttributeDto,
  onClose: () => void,
  onSaved: () => void,
}

const SaveAttributeModal = ({ defaultDto, onClose, onSaved }: Props) => {
  const [ dto, setDto ] = useState(defaultDto);
  const [ saveDto ] = useSaveAttributeMutation();

  const handleSave = async () => {
    await saveDto({ variables: { dto } });

    onSaved();
  };

  return (
    <Modal
      open
      onClose={onClose}
    >
      <VStack sx={{
        width: '100%', height: 'auto', overflowY: 'auto', position: 'relative', minHeight: '100vh',
      }}
      >
        <VStack sx={modalStyle} gap={1}>
          <Typography id="modal-modal-title" variant="h5" component="h5" mb={2}>Ajouter un attribut</Typography>
          <SaveAttributeModalInputName dto={dto} onChange={setDto} />

          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="select-category-label">Catégorie d&apos;attribut</InputLabel>
            <Select
              size="small"
              labelId="select-category-label"
              id="select-category"
              value={dto.categoryId ?? ''}
              label="Catégorie d'attribut"
              onChange={({ target: { value } }) => setDto({ ...dto, categoryId: Number(value) })}
            >
              {
                attributeCategoriesVar().map((category) => (
                  <MenuItem value={category.id} key={`${category.id}`}>{ category.name.fr }</MenuItem>
                ))
              }
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id="select-attribute-type-label" sx={{ top: -6 }}>Type d&apos;attribut</InputLabel>
            <Select
              size="small"
              labelId="select-attribute-type-label"
              id="select-attribute-type"
              value={dto.type}
              label="Type d'attribut"
              onChange={({ target: { value } }) => setDto(({ ...dto, type: value as AttributeType }))}
            >
              <MenuItem value={AttributeType.Boolean}>Oui/Non</MenuItem>
              <MenuItem value={AttributeType.Select}>Sélection</MenuItem>
              <MenuItem value={AttributeType.Text}>Champ libre</MenuItem>
              <MenuItem value={AttributeType.Date}>Date</MenuItem>
              <MenuItem value={AttributeType.Number}>Nombre</MenuItem>
            </Select>
          </FormControl>

          <FormControl sx={{ mt: 1 }}>
            <FormControlLabel control={<Switch checked={dto.filterable} onChange={() => setDto({ ...dto, filterable: !dto.filterable })} />} label="Filtre dans la marketplace" />
          </FormControl>

          <FormControl>
            <FormControlLabel control={<Switch checked={dto.mandatory} />} onChange={() => setDto({ ...dto, mandatory: !dto.mandatory })} label="Obligatoire lors de l'ajout d'un véhicule" />
          </FormControl>

          <HStack justifyContent="flex-end" mt={4} gap={1}>
            <Button variant="contained" onClick={handleSave}>Sauvegarder</Button>
            <Button variant="outlined" onClick={onClose}>Fermer</Button>
          </HStack>
        </VStack>
      </VStack>
    </Modal>
  );
};

export default SaveAttributeModal;
