import { ReactNode } from 'react';
import useReactiveUserConnected from '../../hooks/data/useReactiveUserConnected.ts';
import PageNotFound from '../PageNotFound.tsx';
import Unauthorized from '../Unauthorized.tsx';

interface Props {
  children: ReactNode,
}

const AdministrationGuard = ({ children }: Props) => {
  const { isConnected, isAdmin } = useReactiveUserConnected();

  if (!isConnected) {
    return <PageNotFound />;
  }

  if (!isAdmin) {
    return <Unauthorized />;
  }

  return (
    <>
      { children }
    </>
  );
};

export default AdministrationGuard;
