import DoneIcon from '@mui/icons-material/Done';
import { Box, Button, Dialog } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CloseButton from '../../../../../ui-kit/buttons/CloseButton.tsx';
import HStack from '../../../../../ui-kit/layout/HStack.tsx';
import VStack from '../../../../../ui-kit/layout/VStack.tsx';

interface Props {
  droppedFile: File|null,
  open: boolean,
  onClose: () => void,
  onValidate: (blob: Blob, uri: string, equipmentOrDamageId: number|null) => void,
}

const VehicleFormVideoPlayerDialog = ({
  droppedFile, open, onClose, onValidate,
}: Props) => {
  const { t } = useTranslation();
  const [ videoUri, setVideoUri ] = useState<string|null>(null);

  useEffect(() => {
    if (!droppedFile) {
      setVideoUri(null);

      return;
    }

    setVideoUri(URL.createObjectURL(droppedFile!));
  }, [ droppedFile ]);

  return (
    <Dialog
      onClose={onClose}
      open={open && droppedFile !== null}
      // @ts-ignore
      maxWidth="md"
    >
      {
        videoUri !== null && (
          <Box p={5}>
            <HStack justifyContent="space-between" alignItems="center">
              <h2>{ t('my-vehicle.video-player') }</h2>
              <CloseButton onClose={onClose} />
            </HStack>
            <VStack justifyContent="center" mt={2}>
              <video src={videoUri} controls />
              <Button sx={{ alignSelf: 'flex-end', mt: 2 }} onClick={() => onValidate(droppedFile as any, videoUri, null)}>
                <DoneIcon />
                {' '}
                { t('buttons.validate-video') }
              </Button>
            </VStack>
          </Box>
        )
      }
    </Dialog>
  );
};

export default VehicleFormVideoPlayerDialog;
