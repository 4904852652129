import { TextField } from '@mui/material';
import dayjs from 'dayjs';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import HStack from '../../../../../ui-kit/layout/HStack.tsx';
import VStack from '../../../../../ui-kit/layout/VStack.tsx';
import { SaveableVehicleContext } from '../SaveableVehicleContext.ts';

const VehicleFormAvailableDateInput = () => {
  const { t } = useTranslation();
  const { availableAt, setAvailableAt } = useContext(SaveableVehicleContext);

  return (
    <VStack mb={2}>
      <HStack>
        {t('my-vehicle.available-date')}
        <span style={{ color: 'red' }}>*</span>
      </HStack>
      <TextField
        type="date"
        variant="outlined"
        size="small"
        sx={{ maxWidth: 320 }}
        value={availableAt ? dayjs(availableAt).format('YYYY-MM-DD') : ''}
        onChange={({ target: { value } }) => setAvailableAt(dayjs(value).hour(12).toDate())}
      />
    </VStack>
  );
};

export default VehicleFormAvailableDateInput;
