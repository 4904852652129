import AcceptIcon from '@mui/icons-material/CheckCircleOutline';
import DeclineIcon from '@mui/icons-material/HighlightOff';
import {
  Button,
  Divider,
  Paper, Rating, TextField, Tooltip, Typography,
} from '@mui/material';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Offer, useAcceptOfferMutation, useDeclineOfferMutation } from '../../../../graphql';
import useUiToast from '../../../../hooks/useUiToast.ts';
import HStack from '../../../ui-kit/layout/HStack.tsx';
import VStack from '../../../ui-kit/layout/VStack.tsx';

interface Props {
  offer: Offer,
  onSaved?: (action: 'accepted' | 'declined') => void,
}

const OfferCard = ({ offer, onSaved }: Props) => {
  const { t } = useTranslation();
  const latestStatus = useMemo(() => offer.statuses!.slice().pop(), [ offer ]);
  const [ reason, setReason ] = useState('');
  const [ isGoingToAccept, setIsGoingToAccept ] = useState(false);
  const [ isGoingToDecline, setIsGoingToDecline ] = useState(false);
  const [ acceptOffer ] = useAcceptOfferMutation();
  const [ declineOffer ] = useDeclineOfferMutation();
  const { success } = useUiToast();

  const toggleAcceptOrDecline = (action: 'accept' | 'decline') => {
    if (action === 'accept') {
      setIsGoingToAccept(!isGoingToAccept);
      setIsGoingToDecline(false);
    }

    if (action === 'decline') {
      setIsGoingToDecline(!isGoingToDecline);
      setIsGoingToAccept(false);
    }
  };

  const handleAccept = async () => {
    await acceptOffer({ variables: { offerId: offer.id } });
    onSaved?.('accepted');
    success(t('toasts.you-have-accepted-offer'));
  };

  const handleDecline = async () => {
    await declineOffer({ variables: { dto: { offerId: offer.id, reason } } });
    onSaved?.('declined');
    success(t('toasts.you-have-declined-offer'));
  };

  return (
    <Paper
      sx={{
        display: 'flex', flexDirection: 'column', gap: 2, mb: 2, padding: 2,
      }}
      elevation={1}
    >
      <HStack alignItems="center" flex={1} gap={2}>
        <HStack width={40} height={40} bgcolor="primary.main" borderRadius="100%" justifyContent="center" alignItems="center">
          <Typography fontWeight="bolder" color="white">{ `${offer.user!.firstName[0]}` }</Typography>
        </HStack>
        <VStack>
          <Typography color="#989898" fontSize={12}>{offer.user?.dealer?.name}</Typography>
          <Typography fontWeight="bolder">{ `${offer.user!.firstName} ${offer.user!.lastName[0]}.` }</Typography>
          <Rating size="small" name="read-only" value={offer.user!.averageRatingScore} precision={0.5} readOnly />
        </VStack>
        <HStack flex={1} justifyContent="flex-end">
          <VStack>
            <Typography fontWeight="bolder" flex={1}>{ `${offer.amount}€` }</Typography>
            {
              latestStatus && latestStatus.type === 'WAITING' && (
                <HStack justifyContent="center" gap={1} alignItems="flex-end">
                  <Tooltip title="Accepter l'offre">
                    <AcceptIcon sx={{ color: 'green', cursor: 'pointer' }} onClick={() => toggleAcceptOrDecline('accept')} />
                  </Tooltip>
                  <Tooltip title="Refuser l'offre">
                    <DeclineIcon sx={{ color: 'red', cursor: 'pointer' }} onClick={() => toggleAcceptOrDecline('decline')} />
                  </Tooltip>
                </HStack>
              )
            }
          </VStack>
        </HStack>
      </HStack>

      {
        isGoingToAccept && (
          <>
            <Divider />
            <Typography variant="h6" fontWeight="bolder">{t('buttons.accept-offer')}</Typography>
            <Typography variant="body1" fontSize={14}>{t('dialogs.offers.you-are-going-to-accept-this-offer')}</Typography>
            <HStack justifyContent="flex-end" gap={1}>
              <Button size="small" variant="outlined" onClick={handleAccept}>{t('buttons.accept')}</Button>
              <Button size="small" variant="outlined" onClick={() => toggleAcceptOrDecline('accept')}>{t('buttons.cancel')}</Button>
            </HStack>
          </>
        )
      }

      {
        isGoingToDecline && (
          <>
            <Divider />
            <Typography variant="h6" fontWeight="bolder">{t('buttons.decline-offer')}</Typography>
            <Typography variant="body1" fontSize={14}>{t('dialogs.offers.you-are-going-to-decline-this-offer')}</Typography>
            <VStack>
              <Typography variant="body1" fontWeight="bolder">
                {t('dictionary.comment')}
                <span style={{ color: 'red' }}>*</span>
              </Typography>
              <TextField type="text" multiline onChange={({ target: { value } }) => setReason(value)} />
            </VStack>
            <HStack justifyContent="flex-end" gap={1}>
              <Button size="small" variant="outlined" onClick={handleDecline} disabled={reason.length < 1}>{t('buttons.decline')}</Button>
              <Button size="small" variant="outlined" onClick={() => toggleAcceptOrDecline('decline')}>{t('buttons.cancel')}</Button>
            </HStack>
          </>
        )
      }
    </Paper>
  );
};

export default OfferCard;
