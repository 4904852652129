import { useReactiveVar } from '@apollo/client';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Link } from 'react-router-dom';
import { Equipment, equipmentsVar, useGetAllEquipmentsLazyQuery } from '../../../../../graphql';
import HStack from '../../../../ui-kit/layout/HStack.tsx';
import Page from '../../../../ui-kit/layout/Page.tsx';
import VStack from '../../../../ui-kit/layout/VStack.tsx';
import SaveEquipmentNameInput from './partials/SaveEquipmentNameInput.tsx';

const AdminEquipmentsPage = () => {
  const equipments = useReactiveVar(equipmentsVar);
  const [ fetchAllEquipments ] = useGetAllEquipmentsLazyQuery();

  const reloadEquipments = async () => {
    const { data } = await fetchAllEquipments();

    equipmentsVar(data?.allEquipments || []);
  };

  return (
    <Page>
      <HStack alignItems="center">
        <KeyboardArrowLeftIcon />
        <Link to="/admin" style={{ color: 'initial', textDecoration: 'none' }}>Revenir au dashboard</Link>
      </HStack>

      <HStack alignItems="center" gap={2} mb={1}>
        <h1>Gestion des équipements</h1>
      </HStack>

      <VStack gap={1} maxWidth={400}>
        <SaveEquipmentNameInput onSaved={reloadEquipments} isAdding />
        {
          equipments.map((equipment, index) => (
            <SaveEquipmentNameInput
              equipment={equipment as Equipment}
              isAdding={false}
              onSaved={reloadEquipments}
              key={`save-equipment-input-${index}`}
            />
          ))
        }
        {
          equipments.length > 10 && (
            <SaveEquipmentNameInput onSaved={reloadEquipments} isAdding />
          )
        }
      </VStack>
    </Page>
  );
};

export default AdminEquipmentsPage;
