import { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useReactiveUserConnected from '../../hooks/data/useReactiveUserConnected.ts';

interface Props {
  children: ReactNode,
}

const MaintenanceGuard = ({ children }: Props) => {
  const isOnMaintenance = false;
  const { isAdmin } = useReactiveUserConnected();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAdmin && isOnMaintenance) {
      navigate('/maintenance');
    }
  }, [ isAdmin ]);

  return (
    <>
      { children }
    </>
  );
};

export default MaintenanceGuard;
