import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import {
  Accordion, AccordionDetails, AccordionSummary, Button,
} from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { Address } from '../../../../../../graphql';
import useReactiveUserConnected from '../../../../../../hooks/data/useReactiveUserConnected.ts';
import HStack from '../../../../../ui-kit/layout/HStack.tsx';
import VStack from '../../../../../ui-kit/layout/VStack.tsx';

const columns: (t: any) => GridColDef[] = (t) => [
  {
    field: 'type', headerName: t('dictionary.type'), renderCell: (params) => t(`enums.address-types.${params.row.type}`), width: 150,
  },
  {
    field: 'street',
    headerName: t('dictionary.address'),
    flex: 1,
    editable: false,
    renderCell: (params: GridRenderCellParams<any, any, any>) => (
      <VStack my={2}>
        <strong>{ `${params.row.street} ${params.row.number},` }</strong>
        <strong>{ `${params.row.city} ${params.row.zip}` }</strong>
        <strong>{ params.row.country }</strong>
      </VStack>
    ),
  },
];

interface Props {
  onAddButtonClick: () => void,
}

const AddressesAccordion = ({ onAddButtonClick }: Props) => {
  const { t } = useTranslation();
  const { user } = useReactiveUserConnected();

  return (
    <Accordion sx={{ maxWidth: 800 }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <HStack gap={1} alignItems="center">
          <LocationOnIcon />
          {t('my-parameters.my-profile.my-addresses')}
        </HStack>
      </AccordionSummary>
      <AccordionDetails>
        {
          user && user.dealer && user.dealer.addresses && user.dealer.addresses.length > 0 && (
            <DataGrid<Address>
              rows={user.dealer.addresses}
              columns={columns(t)}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 50,
                  },
                },
              }}
              pageSizeOptions={[ 50 ]}
              hideFooter
              disableColumnSelector
              disableRowSelectionOnClick
              disableDensitySelector
              getRowHeight={() => 'auto'}
              sx={{ mb: 2 }}
            />
          )
        }

        <HStack justifyContent="flex-end">
          <Button color="primary" variant="outlined" onClick={onAddButtonClick}>
            {t('my-parameters.my-profile.add-address')}
          </Button>
        </HStack>
      </AccordionDetails>
    </Accordion>
  );
};

export default AddressesAccordion;
