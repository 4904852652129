import { Checkbox } from '@mui/material';
import { useContext, useMemo } from 'react';
import { Equipment } from '../../../../../../graphql';
import { filter } from '../../../../../../helpers/array.ts';
import useLang from '../../../../../../hooks/useLang.ts';
import HStack from '../../../../../ui-kit/layout/HStack.tsx';
import { SaveableVehicleContext } from '../SaveableVehicleContext.ts';

interface Props {
  equipments: Equipment[],
}

const VehicleFormEquipmentsList = ({ equipments }: Props) => {
  const { selectedEquipmentIds, setSelectedEquipmentIds } = useContext(SaveableVehicleContext);
  const lang = useLang();
  const sortedEquipments = useMemo(() => [ ...equipments ].filter((equipment) => !equipment.dealerId).sort(
    (equipmentA, equipmentB) => equipmentA.name[lang].localeCompare(equipmentB.name[lang]),
  ), [ equipments, lang ]);

  return (
    <HStack flexWrap="wrap" gap="1rem">
      {
        sortedEquipments.map((equipment) => {
          const isSelected = selectedEquipmentIds.includes(equipment.id);
          const onChange = () => setSelectedEquipmentIds(isSelected ? filter(selectedEquipmentIds, equipment.id) : [ ...selectedEquipmentIds, equipment.id ]);

          return (
            <HStack
              key={equipment.id}
              onClick={onChange}
              style={{ width: 'calc(50% - 10px)', cursor: 'pointer' }}
              alignItems="center"
            >
              <Checkbox checked={isSelected} onClick={onChange} />
              { equipment.name[lang] }
            </HStack>
          );
        })
      }
    </HStack>
  );
};

export default VehicleFormEquipmentsList;
