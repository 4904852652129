import { useReactiveVar } from '@apollo/client';
import AbcIcon from '@mui/icons-material/Abc';
import AddIcon from '@mui/icons-material/Add';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import MoreVert from '@mui/icons-material/MoreVert';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import EventIcon from '@mui/icons-material/Event';
import LooksOneOutlinedIcon from '@mui/icons-material/LooksOneOutlined';
import {
  Autocomplete, Button, IconButton, Menu, MenuItem, TextField, Tooltip, Typography,
} from '@mui/material';
import {
  MouseEvent, useEffect, useMemo, useState,
} from 'react';
import { Link } from 'react-router-dom';
import {
  Attribute,
  attributesVar,
  AttributeType,
  GetAllAttributesQuery,
  SaveAttributeDto,
  useDeleteAttributeMutation,
  useGetAllAttributesLazyQuery,
} from '../../../../../graphql';
import HStack from '../../../../ui-kit/layout/HStack.tsx';
import Page from '../../../../ui-kit/layout/Page.tsx';
import VStack from '../../../../ui-kit/layout/VStack.tsx';
import SaveAttributeModal from './partials/modals/save-attribute/SaveAttributeModal.tsx';
import SaveAttributeValueInput from './partials/SaveAttributeValueInput.tsx';

function createSaveAttributeDto(attribute?: Attribute): SaveAttributeDto {
  return {
    id: attribute?.id || null,
    name: attribute?.name || {},
    categoryId: attribute?.categoryId || 0,
    type: attribute?.type || null as any,
    values: attribute?.values || [],
    mandatory: attribute?.mandatory || true,
    filterable: attribute?.filterable || true,
  };
}

const AdminAttributesPage = () => {
  const attributes = useReactiveVar(attributesVar);
  const [ fetchAllAttributes ] = useGetAllAttributesLazyQuery();
  const [ deleteAttribute ] = useDeleteAttributeMutation();
  const [ openSaveAttributeModal, setOpenSaveAttributeModal ] = useState<boolean>(false);
  const [ saveableAttribute, setSaveableAttribute ] = useState(createSaveAttributeDto());
  const [ selectedAttribute, setSelectedAttribute ] = useState<Attribute|null>(null);
  const saveableSelectedAttribute = useMemo(() => createSaveAttributeDto(selectedAttribute ?? undefined), [ selectedAttribute ]);
  const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const reloadAttributes = async () => {
    const { data } = await fetchAllAttributes();

    attributesVar(data?.allAttributes);
  };

  const handleEdit = (attribute: GetAllAttributesQuery['allAttributes'][0]) => {
    setSaveableAttribute(attribute as SaveAttributeDto);
    setOpenSaveAttributeModal(true);
  };

  const handleDelete = async (attribute: GetAllAttributesQuery['allAttributes'][0]) => {
    await deleteAttribute({ variables: { id: attribute.id } });
    await reloadAttributes();
  };

  const handleSaveAttributeModalClose = async () => {
    setOpenSaveAttributeModal(false);
    setSaveableAttribute(createSaveAttributeDto(selectedAttribute ?? undefined));
    await reloadAttributes();
  };

  useEffect(() => {
    if (selectedAttribute) {
      setSelectedAttribute(attributes.find((attribute) => attribute.id === selectedAttribute.id) as Attribute|null);
    }
  }, [ attributes ]);

  return (
    <Page>
      <HStack alignItems="center">
        <KeyboardArrowLeftIcon />
        <Link to="/admin" style={{ color: 'initial', textDecoration: 'none' }}>Revenir au dashboard</Link>
      </HStack>

      <HStack alignItems="center" gap={2} mb={1}>
        <h1>Gestion des attributs</h1>

        <Tooltip title="Ajouter un attribut">
          <Button
            variant="outlined"
            size="small"
            color="primary"
            sx={{ width: 30, minWidth: 'unset', height: 30 }}
            onClick={() => {
              setOpenSaveAttributeModal(true);
              setSaveableAttribute(createSaveAttributeDto());
            }}
          >
            <AddIcon />
          </Button>
        </Tooltip>
      </HStack>

      <HStack width={400} gap={1}>
        <Autocomplete
          fullWidth
          disablePortal
          options={attributes.map((attribute) => ({ label: attribute.name.fr, attribute }))}
          renderInput={(params) => <TextField {...params} label="Sélectionner un attribut" />}
          renderOption={(props, option) => (
            <HStack {...props as any} alignItems="center" gap={1}>
              { option.attribute.type === AttributeType.Select && <FormatListBulletedIcon /> }
              { option.attribute.type === AttributeType.Boolean && <ToggleOnIcon /> }
              { option.attribute.type === AttributeType.Text && <AbcIcon /> }
              { option.attribute.type === AttributeType.Date && <EventIcon /> }
              { option.attribute.type === AttributeType.Number && <LooksOneOutlinedIcon /> }
              { option.label }
            </HStack>
          )}
          getOptionLabel={(option) => option.label}
          onChange={(_event, value) => {
            setSelectedAttribute(value ? value.attribute as Attribute : null);
            setSaveableAttribute(createSaveAttributeDto(value ? value.attribute as Attribute : undefined));
          }}
        />
        <IconButton sx={{ width: 50, height: 50 }} aria-label="delete" color="primary" disabled={!selectedAttribute} onClick={handleOpenMenu}>
          <MoreVert />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={isMenuOpen}
          onClose={handleCloseMenu}
          onClick={handleCloseMenu}
        >
          <MenuItem onClick={() => {
            handleEdit(selectedAttribute as GetAllAttributesQuery['allAttributes'][0]);
            handleCloseMenu();
          }}
          >
            <Typography>Éditer l&apos;attribut</Typography>
          </MenuItem>
          <MenuItem onClick={() => {
            handleDelete(selectedAttribute as GetAllAttributesQuery['allAttributes'][0]);
            handleCloseMenu();
          }}
          >
            <Typography>Supprimer l&apos;attribut</Typography>
          </MenuItem>
        </Menu>
      </HStack>

      {
        saveableSelectedAttribute && saveableSelectedAttribute.type === AttributeType.Select && (
          <>
            <Typography fontWeight="bold" mt={4} mb={2}>Ajouter les valeurs possibles</Typography>
            <VStack gap={1} maxWidth={400}>
              <SaveAttributeValueInput dto={saveableSelectedAttribute} onSaved={reloadAttributes} isAdding />
              {
                saveableSelectedAttribute.values.map((_attributeValue, index) => (
                  <SaveAttributeValueInput
                    dto={saveableSelectedAttribute}
                    isAdding={false}
                    index={index}
                    onSaved={reloadAttributes}
                    key={`save-attribute-value-input-${index}`}
                  />
                ))
              }
              {
                saveableAttribute.values.length > 10 && (
                  <SaveAttributeValueInput dto={saveableSelectedAttribute} onSaved={reloadAttributes} isAdding />
                )
              }
            </VStack>
          </>
        )
      }

      {
        openSaveAttributeModal && (
        <SaveAttributeModal
          defaultDto={saveableAttribute}
          onSaved={handleSaveAttributeModalClose}
          onClose={handleSaveAttributeModalClose}
        />
        )
      }
    </Page>
  );
};

export default AdminAttributesPage;
