import { ReactNode } from 'react';
import { PhotoProvider } from 'react-photo-view';
import AutoLoginProvider from './providers/AutoLoginProvider.tsx';

interface Props {
  children: ReactNode,
}

const Route = ({ children }: Props) => (
  <AutoLoginProvider>
    <PhotoProvider>
      { children }
    </PhotoProvider>
  </AutoLoginProvider>
);

export default Route;
