import { useNavigate } from 'react-router-dom';
import { loggedIn, userConnected } from '../graphql';
import useLocalStorage from './useLocalStorage.ts';
import useLoginUnderUser from './useLoginUnderUser.ts';

export default function useLogout() {
  const { get, remove } = useLocalStorage();
  const navigate = useNavigate();
  const loginUnderUser = useLoginUnderUser();

  return () => {
    if (get('backup-bearer-token', null)) {
      loginUnderUser.loginBackToAdmin();

      return;
    }

    remove('bearer-token');
    userConnected(null);
    loggedIn(false);
    navigate('/');
  };
}
