import { Box } from '@mui/material';
import { ReactNode } from 'react';

interface Props {
  children?: ReactNode,
  index: number,
  value: number,
  padding?: number,
}

const UiTabPanel = ({
  children, value, index, padding = 3, ...other
}: Props) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    {value === index && (
      <Box sx={{ p: padding }}>
        {children}
      </Box>
    )}
  </div>
);

export default UiTabPanel;
