import { FormControl, TextField } from '@mui/material';
import { useState } from 'react';
import { SaveBrandDto } from '../../../../../../../../graphql';

interface Props {
  dto: SaveBrandDto,
  onChange: (dto: SaveBrandDto) => void,
}

const SaveBrandModalInputName = ({ dto, onChange }: Props) => {
  const [ name, setName ] = useState(dto.name);

  return (
    <FormControl fullWidth>
      <TextField
        defaultValue={name}
        size="small"
        label="Nom de la marque"
        onChange={({ target: { value } }) => setName(value)}
        onBlur={() => onChange({ ...dto, name })}
      />
    </FormControl>
  );
};

export default SaveBrandModalInputName;
