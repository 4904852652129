export default function className(defaultClassName: string, classNamesObj: Record<string, boolean>) {
  let result = defaultClassName;

  for (const key in classNamesObj) {
    if (classNamesObj[key]) {
      result += ` ${key}`;
    }
  }

  return result;
}
