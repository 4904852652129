import { MenuItem, TextField } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import useReactiveUserConnected from '../../../../../../hooks/data/useReactiveUserConnected.ts';
import HStack from '../../../../../ui-kit/layout/HStack.tsx';
import VStack from '../../../../../ui-kit/layout/VStack.tsx';
import { SaveableVehicleContext } from '../SaveableVehicleContext.ts';

const VehicleFormContactSelect = () => {
  const { dealer } = useReactiveUserConnected();
  const { t } = useTranslation();
  const { selectedContactId, setSelectedContactId } = useContext(SaveableVehicleContext);

  return (
    <VStack mb={2}>
      <HStack>
        {t('my-vehicle.dealer-contact')}
      </HStack>
      <TextField
        select
        variant="outlined"
        size="small"
        sx={{ maxWidth: 320 }}
        value={selectedContactId ?? ''}
        onChange={({ target: { value } }) => setSelectedContactId(Number(value))}
      >
        {
          dealer!.contacts!.map((contact) => (
            <MenuItem value={contact.id} key={contact.id}>{ `${contact.firstName} ${contact.lastName}` }</MenuItem>
          ))
        }
      </TextField>
    </VStack>
  );
};

export default VehicleFormContactSelect;
