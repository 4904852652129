import PhoneIcon from '@mui/icons-material/Phone';
import {
  IconButton, Paper, Rating, Typography,
} from '@mui/material';
import { User } from '../../../../graphql';
import HStack from '../../../ui-kit/layout/HStack';
import VStack from '../../../ui-kit/layout/VStack';

interface Props {
  user: User,
  ratingEnabled?: boolean,
  fullNameEnabled?: boolean,
  topTitle?: string,
}

const UserCard = ({
  user, ratingEnabled = false, fullNameEnabled = false, topTitle = undefined,
}: Props) => (
  <Paper
    sx={{
      display: 'flex', flexDirection: 'column', gap: 2, mb: 2, padding: 2,
    }}
    elevation={1}
  >
    <HStack alignItems="center" justifyContent="space-between" flex={1} gap={2}>
      <HStack flex={1} gap={2}>
        <HStack width={40} height={40} bgcolor="primary.main" borderRadius="100%" justifyContent="center" alignItems="center">
          <Typography fontWeight="bolder" color="white">{ `${user.firstName[0]}` }</Typography>
        </HStack>
        <VStack>
          <Typography color="#989898" fontSize={12}>{topTitle ?? user?.dealer?.name}</Typography>
          <Typography fontWeight="bolder">{ `${user.firstName} ${fullNameEnabled ? user.lastName : `${user.lastName[0]}.`}` }</Typography>
          {
              ratingEnabled && (
                <Rating size="small" name="read-only" value={user.averageRatingScore} precision={0.5} readOnly />
              )
            }
        </VStack>
      </HStack>

      {
          user.phoneNumber && (
            <a href={`tel:${user.phoneNumber}`}>
              <IconButton color="primary">
                <PhoneIcon />
              </IconButton>
            </a>
          )
        }
    </HStack>
  </Paper>
);

export default UserCard;
