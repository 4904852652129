import { useReactiveVar } from '@apollo/client';
import { Autocomplete, TextField } from '@mui/material';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { brandsVar } from '../../../../../../graphql';
import HStack from '../../../../../ui-kit/layout/HStack.tsx';
import VStack from '../../../../../ui-kit/layout/VStack.tsx';
import { SaveableVehicleContext } from '../SaveableVehicleContext.ts';

const VehicleFormBrandSelect = () => {
  const { selectedBrand: selectedBrandState, setSelectedBrand } = useContext(SaveableVehicleContext);
  const { t } = useTranslation();
  const brands = useReactiveVar(brandsVar);
  const models = useMemo(() => {
    if (!selectedBrandState.brandId) {
      return [];
    }

    return brands.find((brand) => brand.id === selectedBrandState.brandId)!.models!;
  }, [ brands, selectedBrandState ]);
  const selectedBrand = useMemo(() => {
    if (!selectedBrandState.brandId) {
      return null;
    }

    const selectedModelFound = brands.find((model) => model.id === selectedBrandState.brandId);

    return { ...selectedModelFound, label: selectedModelFound!.name };
  }, [ selectedBrandState.modelId, models ]);
  const selectedModel = useMemo(() => {
    if (!selectedBrandState.modelId) {
      return null;
    }

    const selectedModelFound = models.filter((model) => !model.dealerId).find((model) => model.id === selectedBrandState.modelId);

    return { ...selectedModelFound, label: selectedModelFound!.name };
  }, [ selectedBrandState.modelId, models ]);

  return (
    <>
      <VStack>
        <HStack>
          { t('dictionary.brand') }
          {' '}
          <span style={{ color: 'red' }}>*</span>
        </HStack>
        <Autocomplete
          disablePortal
          options={brands.map((brand) => ({ ...brand, label: brand.name })).sort((a, b) => a.label.localeCompare(b.label))}
          value={selectedBrand}
          onChange={(_event, value) => setSelectedBrand({ brandId: value?.id || null, modelId: null })}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              size="small"
              sx={{ mb: 2 }}
            />
          )}
        />
      </VStack>

      <VStack>
        <HStack>
          { t('dictionary.model') }
          {' '}
          <span style={{ color: 'red' }}>*</span>
        </HStack>
        <Autocomplete
          disablePortal
          options={models.map((model) => ({ ...model, label: model.name })).sort((a, b) => a.label.localeCompare(b.label))}
          disabled={selectedBrandState.brandId === null}
          value={selectedModel}
          onChange={(_event, value) => setSelectedBrand({ ...selectedBrandState, modelId: value?.id || null })}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              size="small"
              sx={{ mb: 2 }}
            />
          )}
        />
      </VStack>
    </>
  );
};

export default VehicleFormBrandSelect;
