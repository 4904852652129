import { useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SaveableVehicleContext } from '../../components/pages/online/my-vehicles/form/SaveableVehicleContext.ts';
import { requiredVehicleMediaByTag } from '../../config/constants.ts';
import {
  Attribute,
  AttributeType,
  SaveSingleVehicleMediaDto,
  SaveVehicleAttributeDto,
  SaveVehicleDamageDto,
  SaveVehicleEquipmentDto, useGenerateVehicleMediaThumbnailsMutation,
  useSaveVehicleMediasMutation,
  useSaveVehicleMutation,
} from '../../graphql';
import useFileUpload from '../useFileUpload.ts';
import useUiToast from '../useUiToast.ts';
import useReactiveAttributes from './useReactiveAttributes.ts';
import useReactiveUserConnected from './useReactiveUserConnected.ts';

type Return = [() => void, { progression: number, canSave: boolean, }];

export default function useSaveVehicle(): Return {
  const { id } = useParams<{ id: string, }>();
  const { dealer, refetchMe } = useReactiveUserConnected();
  const {
    availableAt,
    filledAttributes,
    immediatePurchase,
    medias,
    selectedAddressId,
    selectedBrand,
    selectedContactId,
    selectedDamageIds,
    selectedEquipmentIds,
  } = useContext(SaveableVehicleContext);
  const { requiredAttributeIds, findAttributeById, findAttributeValueById } = useReactiveAttributes();
  const canSave = useMemo(
    () => {
      const mediasWithRequiredTag = medias.filter((media) => requiredVehicleMediaByTag.includes(media.tag));
      const filledAttributeIds = Object.keys(filledAttributes);
      const requiredFilledAttributeId = filledAttributeIds.filter((filledAttributeId) => requiredAttributeIds.includes(Number(filledAttributeId)));

      return selectedBrand.brandId !== null && selectedBrand.modelId !== null
        && availableAt !== null
        && selectedAddressId !== null
        && mediasWithRequiredTag.length === requiredVehicleMediaByTag.length
        && requiredFilledAttributeId.length === requiredAttributeIds.length;
    },
    [ requiredAttributeIds, selectedBrand, filledAttributes, medias ],
  );
  const [ saveVehicle ] = useSaveVehicleMutation();
  const [ saveVehicleMedias ] = useSaveVehicleMediasMutation();
  const [ generateThumbnailMedias ] = useGenerateVehicleMediaThumbnailsMutation();
  const [ progression, setProgression ] = useState(-1);
  const uploadFile = useFileUpload();
  const { error: showError } = useUiToast();

  const progress = (currentProgression: number) => {
    setProgression(currentProgression);

    if (currentProgression === 100) {
      setTimeout(() => {
        setProgression(-1);
      }, 1500);
    }
  };

  const save = async () => {
    if (!canSave) {
      return;
    }

    let currentProgression = 0;

    progress(currentProgression += 25);

    try {
      const vehicleAttributes: SaveVehicleAttributeDto[] = Object.keys(filledAttributes).map((attributeId) => {
        const value = filledAttributes[Number(attributeId)];
        const attribute = findAttributeById(Number(attributeId))!;
        let attributeValue = null;

        if (attribute.type === AttributeType.Select) {
          attributeValue = findAttributeValueById(attribute as Attribute, Number(value));
        }

        return {
          id: null,
          attributeId: attribute.id,
          attributeValueId: attributeValue ? attributeValue.id : null,
          value: attributeValue ? null : value,
        };
      });

      const vehicleDamages: SaveVehicleDamageDto[] = selectedDamageIds.map((damageId) => ({
        damageId,
      }));

      const vehicleEquipments: SaveVehicleEquipmentDto[] = selectedEquipmentIds.map((equipmentId) => ({
        equipmentId,
      }));

      const { data } = await saveVehicle({
        variables: {
          dto: {
            ...(id ? { id: Number(id) } : {}),
            addressId: selectedAddressId!,
            contactId: selectedContactId,
            availableAt: availableAt!,
            brandId: selectedBrand.brandId!,
            modelId: selectedBrand.modelId!,
            attributes: vehicleAttributes,
            damages: vehicleDamages,
            equipments: vehicleEquipments,
            dealerId: dealer!.id,
            immediatePurchase,
          },
        },
      });

      if (!data) {
        progress(100);

        return;
      }

      progress(currentProgression += 25);

      const vehicleMedias: SaveSingleVehicleMediaDto[] = [];
      const mediasWithFileToSave = medias.filter((media) => media.file);
      const percentagePerFileSaved = 25 / mediasWithFileToSave.length;

      // eslint-disable-next-line no-restricted-syntax
      for (const media of mediasWithFileToSave) {
        if (media.id) {
          continue;
        }

        // eslint-disable-next-line no-await-in-loop
        const file = await uploadFile(media.file!, `/vehicles/medias/${data.saveVehicle.id}`, 1920);

        vehicleMedias.push({
          id: media.id,
          fileId: file.id,
          type: media.type,
          tag: media.tag,
          damageId: media.damageId,
          equipmentId: media.equipmentId,
        });

        progress(currentProgression += percentagePerFileSaved);
      }

      if (vehicleMedias.length) {
        const { data: savedMedias } = await saveVehicleMedias({ variables: { dto: { vehicleId: data.saveVehicle.id, medias: vehicleMedias } } });

        progress(80);

        if (savedMedias) {
          await generateThumbnailMedias({
            variables: {
              dto: {
                vehicleId: data.saveVehicle.id,
                mediaIds: savedMedias.saveVehicleMedias.map((vehicleMedia) => vehicleMedia.id),
              },
            },
          });
        }
      }

      progress(90);

      await refetchMe();
    } catch (error) {
      showError((error as Error).message);
    } finally {
      progress(100);
    }
  };

  return [ save, { progression, canSave } ];
}
