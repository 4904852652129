import { Dialog } from '@mui/material';
import { ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '../../../../../../config/theming.ts';
import {
  Offer,
  useGetAllOffersOfMyVehicleQuery,
  UserRatingType,
  Vehicle,
  VehicleStatus,
} from '../../../../../../graphql';
import CloseButton from '../../../../../ui-kit/buttons/CloseButton.tsx';
import DialogTitle from '../../../../../ui-kit/dialog/DialogTitle.tsx';
import HStack from '../../../../../ui-kit/layout/HStack.tsx';
import VStack from '../../../../../ui-kit/layout/VStack.tsx';
import UiTabPanels from '../../../../../ui-kit/navigation/tabs/UiTabPanels.tsx';
import UiTabs from '../../../../../ui-kit/navigation/tabs/UiTabs.tsx';
import OfferCard from '../../../cards/OfferCard.tsx';
import RatingForm from '../../../forms/RatingForm.tsx';
import OffersListTab from './tab-offers-list/OffersListTab.tsx';

interface Props {
  vehicle: Vehicle,
  ratingEnabled?: boolean,
  onClose: () => void,
  onSaved: () => void,
}

const ManagerOffersDialog = ({
  ratingEnabled = false, vehicle, onClose, onSaved,
}: Props) => {
  const { t } = useTranslation();
  const [ tabIndex, setTabIndex ] = useState(0);
  const { data, loading: isLoadingOffers, refetch } = useGetAllOffersOfMyVehicleQuery({ variables: { vehicleId: vehicle.id } });
  const waitingOffers = useMemo(() => ((data?.offersOfMyVehicle as Offer[]|undefined) || []).filter((offer) => offer.statuses?.slice().pop()!.type === 'WAITING'), [ data ]);
  const declinedOffers = useMemo(() => ((data?.offersOfMyVehicle as Offer[]|undefined) || []).filter((offer) => offer.statuses?.slice().pop()!.type === 'DECLINED'), [ data ]);
  const acceptedOffers = useMemo(() => ((data?.offersOfMyVehicle as Offer[]|undefined) || []).filter((offer) => offer.statuses?.slice().pop()!.type === 'ACCEPTED'), [ data ]);
  const wonOffers = useMemo(() => ((data?.offersOfMyVehicle as Offer[]|undefined) || []).filter((offer) => offer.statuses?.slice().pop()!.type === 'WON'), [ data ]);

  const title: () => ReactNode = () => {
    if (ratingEnabled) {
      return t('dialogs.offers.rate-buyer');
    }

    if (vehicle.status === VehicleStatus.Validated || wonOffers.length > 0 || acceptedOffers.length > 0) {
      return t('dialogs.offers.accepted-offer');
    }

    return (
      <HStack gap={0.5}>
        {t('dialogs.offers.my-offers')}
        <span style={{
          fontSize: 16,
          fontWeight: 'bold',
        }}
        >
          {`(${waitingOffers.length + declinedOffers.length})`}
        </span>
      </HStack>
    );
  };

  return (
    <Dialog
      id="manage-offers-dialog"
      onClose={onClose}
      open
      // @ts-ignore
      maxWidth="md"
    >
      <VStack p={5} width={425}>
        <HStack justifyContent="space-between" alignItems="center">
          <DialogTitle text={title()} loading={isLoadingOffers} />
          <CloseButton onClose={onClose} />
        </HStack>

        {
          wonOffers.length > 0 && (
            <>
              <OfferCard offer={wonOffers[0]} />

              {
                ratingEnabled && (
                  <RatingForm
                    type={UserRatingType.Buyer}
                    targetedUserId={wonOffers[0].userId}
                    vehicleId={vehicle.id}
                    onSaved={() => {
                      onSaved();
                      onClose();
                    }}
                  />
                )
              }

              {
                wonOffers[0].user?.dealer?.phoneNumber && (
                  <HStack justifyContent="center">
                    <a href={`tel://${wonOffers[0].user?.dealer?.phoneNumber}`} style={{ color: colors.primary.main, textDecoration: 'none' }}>
                      Appeler l&apos;acheteur au
                      {' '}
                      {wonOffers[0].user?.dealer?.phoneNumber}
                    </a>
                  </HStack>
                )
              }
            </>
          )
        }

        {
          acceptedOffers.length > 0 && (
            <>
              <OfferCard offer={acceptedOffers[0]} />

              {
                ratingEnabled && (
                  <RatingForm
                    type={UserRatingType.Buyer}
                    targetedUserId={acceptedOffers[0].userId}
                    vehicleId={vehicle.id}
                    onSaved={() => {
                      onSaved();
                      onClose();
                    }}
                  />
                )
              }
            </>
          )
        }

        {
          !isLoadingOffers && wonOffers.length === 0 && acceptedOffers.length === 0 && (
            <>
              <UiTabs
                onChangeTab={setTabIndex}
                tabs={[
                  (
                    <HStack alignItems="center" gap={1}>
                      {t('dialogs.offers.offers-in-waiting')}
                      {' '}
                      (
                      { waitingOffers.length }
                      )
                    </HStack>
                  ),
                  (
                    <HStack alignItems="center" gap={1}>
                      {t('dialogs.offers.offers-declined')}
                      {' '}
                      (
                      { declinedOffers.length }
                      )
                    </HStack>
                  ),
                ]}
              />

              <UiTabPanels
                value={tabIndex}
                panels={[
                  <OffersListTab
                    offers={waitingOffers}
                    onSaved={() => {
                      refetch();
                      onSaved();
                    }}
                  />,
                  <OffersListTab offers={declinedOffers} />,
                ]}
                padding={0}
              />
            </>
          )
        }
      </VStack>
    </Dialog>
  );
};

export default ManagerOffersDialog;
