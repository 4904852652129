import {
  Button, Rating, TextField, Typography,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RateSomeoneDto, useRateSomeoneMutation, UserRatingType } from '../../../../graphql';
import useUiToast from '../../../../hooks/useUiToast.ts';
import HStack from '../../../ui-kit/layout/HStack.tsx';
import VStack from '../../../ui-kit/layout/VStack';

interface Props {
  targetedUserId: number,
  type: UserRatingType,
  vehicleId: number,
  onSaved?: () => void,
}

const RatingForm = ({
  targetedUserId, type, vehicleId, onSaved,
}: Props) => {
  const { t } = useTranslation();
  const [ dto, setDto ] = useState<RateSomeoneDto>({
    targetedUserId, type, vehicleId, comment: '', value: 0,
  });
  const [ rateSomeone ] = useRateSomeoneMutation();
  const { success } = useUiToast();

  const handleRating = async () => {
    await rateSomeone({ variables: { dto } });
    onSaved?.();
    success(t('toasts.your-rating-has-been-sent'));
  };

  return (
    <VStack>
      <VStack alignItems="flex-start">
        <HStack>
          <Typography fontWeight="bolder">{t('form.rating.score')}</Typography>
          <span style={{ color: 'red' }}>*</span>
        </HStack>

        <Rating value={dto.value} onChange={(_event, value) => setDto({ ...dto, value: value === null ? 0 : value })} />
      </VStack>

      <VStack mt={2} mb={4}>
        <HStack>
          <Typography fontWeight="bolder">{t('dictionary.comment')}</Typography>
        </HStack>

        <TextField
          type="text"
          multiline
          value={dto.comment}
          sx={{ width: '100%' }}
          onChange={({ target: { value: comment } }) => setDto({ ...dto, comment })}
        />
      </VStack>

      <HStack justifyContent="flex-end">
        <Button variant="outlined" onClick={handleRating}>{t('buttons.save')}</Button>
      </HStack>
    </VStack>
  );
};

export default RatingForm;
