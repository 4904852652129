import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useReactiveUserConnected from '../../../../hooks/data/useReactiveUserConnected.ts';
import HStack from '../../../ui-kit/layout/HStack.tsx';
import Page from '../../../ui-kit/layout/Page.tsx';
import PageTitle from '../../../ui-kit/layout/PageTitle.tsx';
import VehiclesList from '../../partials/lists/vehicles/VehiclesList.tsx';

const MyHistory = () => {
  const { t } = useTranslation();
  const { kpi } = useReactiveUserConnected();
  const [ isLoading, setIsLoading ] = useState(true);

  return (
    <Page id="vehicles-list-page">
      <PageTitle
        text={(
          <HStack gap={0.5}>
            {t('dictionary.my-history')}
            <span style={{ fontSize: 16, fontWeight: 'bold' }}>{`${kpi ? ` (${kpi.numberOfMyOpenedVehicles})` : ''}`}</span>
          </HStack>
        )}
        loading={isLoading}
      />

      <VehiclesList listType="my-history" onLoading={setIsLoading} dataFetchingDto={{}} />
    </Page>
  );
};

export default MyHistory;
