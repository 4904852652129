import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Typography } from '@mui/material';
import { ReactNode, useEffect } from 'react';
import { Link } from 'react-router-dom';
import useReactiveUserConnected from '../../../hooks/data/useReactiveUserConnected.ts';
import NotificationSubscriptionProvider from '../../providers/NotificationSubscriptionProvider.tsx';
import OfflineDialogsProvider from '../../providers/OfflineDialogsProvider.tsx';
import VehicleDetailsDialogProvider from '../../providers/VehicleDetailsDialogProvider.tsx';
import Header from './Header.tsx';
import HStack from './HStack.tsx';
import LeftMenu from './LeftMenu.tsx';
import VStack from './VStack.tsx';

interface Props {
  id?: string,
  children: ReactNode,
}

const Page = ({ id, children }: Props) => {
  const { refetchMe } = useReactiveUserConnected();

  useEffect(() => {
    refetchMe();
  }, []);

  return (
    <NotificationSubscriptionProvider>
      <VehicleDetailsDialogProvider>
        <OfflineDialogsProvider>
          <div id={id}>
            <Header />
            <main style={{ position: 'relative', top: 100 }}>
              <HStack className="main-h-stack" alignItems="flex-start" padding="0 2rem" mt={2}>
                <LeftMenu />
                <HStack className="content-container-h-stack" flex={1} minHeight="calc(100vh - 260px)" padding="1rem 2rem" bgcolor="#f4f4f4" borderRadius={3}>
                  <div id="content">
                    { children }
                  </div>
                </HStack>
              </HStack>
              <footer style={{ position: 'relative', paddingBottom: 10 }}>
                <VStack className="version" width="20%" alignItems="center" gap={1}>
                  <Typography fontWeight="bold">Version 1.0</Typography>
                  <HStack alignItems="center" gap={1}>
                    <Link to="https://www.facebook.com/profile.php?id=61556254056623" target="_blank">
                      <FacebookIcon />
                    </Link>
                    <Link to="https://www.linkedin.com/company/autoconnect-solutions/" target="_blank">
                      <LinkedInIcon />
                    </Link>
                  </HStack>
                </VStack>
                <HStack alignItems="center" flex={1} justifyContent="flex-end" gap={5}>
                  <Link to="https://autoconnect-solutions.com/documents/fr/privacy.pdf" target="_blank">Privacy</Link>
                  <Link to="https://autoconnect-solutions.com/documents/fr/cgu.pdf" target="_blank">CGU</Link>
                </HStack>
              </footer>
            </main>
          </div>
        </OfflineDialogsProvider>
      </VehicleDetailsDialogProvider>
    </NotificationSubscriptionProvider>
  );
};

export default Page;
