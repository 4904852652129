import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VehicleStatus } from '../../../../../graphql';
import useReactiveUserConnected from '../../../../../hooks/data/useReactiveUserConnected.ts';
import HStack from '../../../../ui-kit/layout/HStack.tsx';
import Page from '../../../../ui-kit/layout/Page.tsx';
import PageTitle from '../../../../ui-kit/layout/PageTitle.tsx';
import UiTabs from '../../../../ui-kit/navigation/tabs/UiTabs.tsx';
import VehiclesList from '../../../partials/lists/vehicles/VehiclesList.tsx';

const MyVehicles = () => {
  const { t } = useTranslation();
  const { kpi } = useReactiveUserConnected();
  const [ isLoading, setIsLoading ] = useState(true);
  const [ tabIndex, setTabIndex ] = useState(0);

  return (
    <Page id="vehicles-list-page">
      <PageTitle
        text={(
          <HStack gap={0.5}>
            {t('dictionary.my-vehicles')}
            <span style={{
              fontSize: 16,
              fontWeight: 'bold',
            }}
            >
              {`${kpi ? ` (${kpi.numberOfMyVehicles})` : ''}`}
            </span>
          </HStack>
        )}
        loading={isLoading}
      />

      <UiTabs
        onChangeTab={setTabIndex}
        tabs={[
          (
            <HStack alignItems="center" gap={1}>
              {t('enums.DRAFT')}
              {' '}
              (
              {kpi!.numberOfMyDraftVehicles}
              )
            </HStack>
          ),
          (
            <HStack alignItems="center" gap={1}>
              {t('enums.PUBLISHED')}
              {' '}
              (
              {kpi!.numberOfMyPublishedVehicles}
              )
            </HStack>
          ),
          (
            <HStack alignItems="center" gap={1}>
              {t('enums.EXPIRED')}
              {' '}
              (
              {kpi!.numberOfMyExpiredVehicles}
              )
            </HStack>
          ),
          (
            <HStack alignItems="center" gap={1}>
              {t('enums.VALIDATED')}
              {' '}
              (
              {kpi!.numberOfMyValidatedVehicles}
              )
            </HStack>
          ),
          (
            <HStack alignItems="center" gap={1}>
              {t('enums.FINISHED')}
              {' '}
              (
              {kpi!.numberOfMyFinishedVehicles}
              )
            </HStack>
          ),
          (
            <HStack alignItems="center" gap={1}>
              {t('enums.DELETED')}
              {' '}
              (
              {kpi!.numberOfMyDeletedVehicles}
              )
            </HStack>
          ),
        ]}
      />

      <br />

      { tabIndex === 0 && <VehiclesList listType="my-vehicles" onLoading={setIsLoading} dataFetchingDto={{ statuses: [ VehicleStatus.Draft ] }} /> }
      { tabIndex === 1 && <VehiclesList listType="my-vehicles" onLoading={setIsLoading} dataFetchingDto={{ statuses: [ VehicleStatus.Published ] }} /> }
      { tabIndex === 2 && <VehiclesList listType="my-vehicles" onLoading={setIsLoading} dataFetchingDto={{ statuses: [ VehicleStatus.Expired ] }} /> }
      { tabIndex === 3 && <VehiclesList listType="my-vehicles" onLoading={setIsLoading} dataFetchingDto={{ statuses: [ VehicleStatus.Validated ] }} /> }
      { tabIndex === 4 && <VehiclesList listType="my-vehicles" onLoading={setIsLoading} dataFetchingDto={{ statuses: [ VehicleStatus.Finished ] }} /> }
      { tabIndex === 5 && <VehiclesList listType="my-vehicles" onLoading={setIsLoading} dataFetchingDto={{ statuses: [ VehicleStatus.Deleted ] }} /> }
    </Page>
  );
};

export default MyVehicles;
