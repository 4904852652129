import { TextField } from '@mui/material';
import { useState } from 'react';
import HStack from '../../../../ui-kit/layout/HStack.tsx';

interface Props {
  onCodeChange: (code: number) => void,
}

const ForgottenPasswordFormCodeInputs = ({ onCodeChange }: Props) => {
  const [ code, setCode ] = useState<number[]>([ -1, -1, -1, -1, -1, -1 ]);

  return (
    <HStack justifyContent="center" gap={2}>
      {
        Array.from(Array(6)).map((_v, index) => (
          <TextField
            className="input-code"
            variant="outlined"
            value={code[index] > -1 ? code[index] : ''}
            sx={{ mb: 2, width: 40 }}
            onChange={({ target: { value } }) => {
              const codeCopy: number[] = [ ...code ];
              const formatted = Number(value);

              codeCopy[index] = formatted >= 0 ? formatted : -1;

              setCode(codeCopy);
              onCodeChange(Number(codeCopy.join('')));
            }}
            onKeyUp={(event) => {
              if (event.code === 'Backspace') {
                const codeCopy: number[] = [ ...code ];

                codeCopy[index] = -1;

                setCode(codeCopy);
                onCodeChange(Number(codeCopy.join('')));
              }
            }}
            inputProps={{ maxLength: 1 }}
            onPaste={(event) => {
              if (index === 0) {
                const codePasted = event.clipboardData.getData('Text').split('') as unknown as number[];

                setCode(codePasted);
                onCodeChange(Number(codePasted.join('')));
              }
            }}
          />
        ))
      }
    </HStack>
  );
};

export default ForgottenPasswordFormCodeInputs;
