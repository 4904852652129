import { useReactiveVar } from '@apollo/client';
import InfoIcon from '@mui/icons-material/Info';
import {
  Alert, Autocomplete, Box, Dialog, TextField,
} from '@mui/material';
import {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { damagesVar, equipmentsVar, VehicleMediaTag } from '../../../../../../graphql';
import useImageLoader from '../../../../../../hooks/useImageLoader.tsx';
import useLang from '../../../../../../hooks/useLang.ts';
import { DroppedVehicleMediaFile } from '../../../../../../index';
import CloseButton from '../../../../../ui-kit/buttons/CloseButton.tsx';
import HStack from '../../../../../ui-kit/layout/HStack.tsx';
import VStack from '../../../../../ui-kit/layout/VStack.tsx';
import { SaveableVehicleContext } from '../SaveableVehicleContext.ts';
import PhotoEditor from './PhotoEditor.tsx';

interface Props {
  droppedFile: DroppedVehicleMediaFile,
  index: number|null,
  onClose: () => void,
  onValidate: (blob: Blob, uri: string, equipmentOrDamageId: number|null) => void,
}

const VehicleFormPhotoDialog = ({
  droppedFile, index, onClose, onValidate,
}: Props) => {
  const { selectedDamageIds, selectedEquipmentIds } = useContext(SaveableVehicleContext);
  const lang = useLang();
  const { t } = useTranslation();
  const equipments = useReactiveVar(equipmentsVar);
  const damages = useReactiveVar(damagesVar);
  const selectedEquipments = useMemo(() => equipments.filter((equipment) => selectedEquipmentIds.includes(equipment.id)), [ equipments, selectedEquipmentIds ]);
  const selectedDamages = useMemo(() => damages.filter((damage) => selectedDamageIds.includes(damage.id)), [ damages, selectedDamageIds ]);
  const { imageHtmlElement, loadImageFromFile } = useImageLoader();
  const [ equipmentOrDamageId, setEquipmentOrDamageId ] = useState<number|null>(null);
  const selectedDamage = useMemo(() => {
    const damage = selectedDamages.find((item) => item.id === equipmentOrDamageId);

    return damage ? { ...damage, label: damage.name[lang] } : null;
  }, [ equipmentOrDamageId, lang ]);
  const selectedEquipment = useMemo(() => {
    const equipment = selectedEquipments.find((item) => item.id === equipmentOrDamageId);

    return equipment ? { ...equipment, label: equipment.name[lang] } : null;
  }, [ equipmentOrDamageId, lang ]);

  useEffect(() => {
    if (droppedFile.tag === VehicleMediaTag.Equipment && droppedFile.equipmentId) {
      setEquipmentOrDamageId(droppedFile.equipmentId);
    }

    if (droppedFile.tag === VehicleMediaTag.Damage && droppedFile.damageId) {
      setEquipmentOrDamageId(droppedFile.damageId);
    }

    loadImageFromFile(droppedFile.file!);
  }, [ droppedFile ]);

  return (
    <Dialog
      onClose={onClose}
      open
      // @ts-ignore
      maxWidth="md"
    >
      {
        imageHtmlElement && (
          <Box p={5}>
            <HStack justifyContent="space-between" alignItems="center">
              <h2>{ t('my-vehicle.photo-editor') }</h2>
              <CloseButton onClose={onClose} />
            </HStack>
            <Alert
              color={'primary' as any}
              icon={<InfoIcon />}
            >
              { t('my-vehicle.photo-editor-help') }
            </Alert>
            <HStack justifyContent="center">
              {
                droppedFile.tag === VehicleMediaTag.Equipment && (
                  <VStack mt={2} alignItems="center">
                    <HStack>
                      {t('my-vehicle.select-one-equipment')}
                      <span style={{ color: 'red' }}>*</span>
                    </HStack>

                    <Autocomplete
                      options={selectedEquipments.slice().sort((a, b) => a.name[lang].localeCompare(b.name[lang])).map((equipment) => ({ ...equipment, label: equipment.name[lang] }))}
                      sx={{ width: 320 }}
                      value={selectedEquipment}
                      size="small"
                      ListboxProps={{ sx: { maxHeight: 300 } }}
                      onChange={(_event, value) => setEquipmentOrDamageId(value ? Number(value!.id) : null)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          size="small"
                          sx={{ mb: 2 }}
                        />
                      )}
                    />
                  </VStack>
                )
              }
              {
                droppedFile.tag === VehicleMediaTag.Damage && (
                  <VStack mt={2} alignItems="center">
                    <HStack>
                      {t('my-vehicle.select-one-damage')}
                      <span style={{ color: 'red' }}>*</span>
                    </HStack>

                    <Autocomplete
                      options={selectedDamages.slice().sort((a, b) => a.name[lang].localeCompare(b.name[lang])).map((damage) => ({ ...damage, label: damage.name[lang] }))}
                      sx={{ width: 320 }}
                      value={selectedDamage}
                      size="small"
                      ListboxProps={{ sx: { maxHeight: 300 } }}
                      onChange={(_event, value) => setEquipmentOrDamageId(value ? Number(value!.id) : null)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          size="small"
                          sx={{ mb: 2 }}
                        />
                      )}
                    />
                  </VStack>
                )
              }
            </HStack>
            <HStack justifyContent="center" mt={2}>
              <PhotoEditor
                index={index}
                photoURL={imageHtmlElement.src}
                originalHeight={imageHtmlElement.height}
                originalWidth={imageHtmlElement.width}
                shouldValidate={
                  (droppedFile.tag === VehicleMediaTag.Damage && equipmentOrDamageId !== droppedFile.damageId)
                  || (droppedFile.tag === VehicleMediaTag.Equipment && equipmentOrDamageId !== droppedFile.equipmentId)
                }
                disableValidate={(droppedFile.tag === VehicleMediaTag.Damage || droppedFile.tag === VehicleMediaTag.Equipment) && equipmentOrDamageId === null}
                onValidate={(blob, uri) => onValidate(blob, uri, equipmentOrDamageId)}
              />
            </HStack>
          </Box>
        )
      }
    </Dialog>
  );
};

export default VehicleFormPhotoDialog;
