import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OfferStatusType } from '../../../../graphql';
import useReactiveUserConnected from '../../../../hooks/data/useReactiveUserConnected.ts';
import HStack from '../../../ui-kit/layout/HStack.tsx';
import Page from '../../../ui-kit/layout/Page.tsx';
import PageTitle from '../../../ui-kit/layout/PageTitle.tsx';
import UiTabs from '../../../ui-kit/navigation/tabs/UiTabs.tsx';
import VehiclesList from '../../partials/lists/vehicles/VehiclesList.tsx';

const MyOffers = () => {
  const { t } = useTranslation();
  const { kpi } = useReactiveUserConnected();
  const [ isLoading, setIsLoading ] = useState(true);
  const [ tabIndex, setTabIndex ] = useState(0);

  return (
    <Page id="vehicles-list-page">
      <PageTitle
        text={(
          <HStack gap={0.5}>
            {t('dictionary.my-offers')}
            <span style={{ fontSize: 16, fontWeight: 'bold' }}>{`${kpi ? ` (${kpi.numberOfMyOffers})` : ''}`}</span>
          </HStack>
        )}
        loading={isLoading}
      />

      <UiTabs
        onChangeTab={setTabIndex}
        tabs={[
          (
            <HStack alignItems="center" gap={1}>
              {t('dictionary.offers-in-progress')}
              {' '}
              (
              {kpi!.numberOfMyWaitingOffers}
              )
            </HStack>
          ),
          (
            <HStack alignItems="center" gap={1}>
              {t('dictionary.offers-accepted')}
              {' '}
              (
              {kpi!.numberOfMyAcceptedOffers}
              )
            </HStack>
          ),
          (
            <HStack alignItems="center" gap={1}>
              {t('dictionary.offers-won')}
              {' '}
              (
              {kpi!.numberOfMyWonOffers}
              )
            </HStack>
          ),
          (
            <HStack alignItems="center" gap={1}>
              {t('dictionary.offers-declined')}
              {' '}
              (
              {kpi!.numberOfMyDeclinedOffers}
              )
            </HStack>
          ),
        ]}
      />

      <br />

      { tabIndex === 0 && <VehiclesList listType="my-offers" onLoading={setIsLoading} dataFetchingDto={{ filterByOfferStatusTypes: [ OfferStatusType.Waiting ] }} /> }
      { tabIndex === 1 && <VehiclesList listType="my-offers" onLoading={setIsLoading} dataFetchingDto={{ filterByOfferStatusTypes: [ OfferStatusType.Accepted ] }} />}
      { tabIndex === 2 && <VehiclesList listType="my-offers" onLoading={setIsLoading} dataFetchingDto={{ filterByOfferStatusTypes: [ OfferStatusType.Won ] }} />}
      { tabIndex === 3 && <VehiclesList listType="my-offers" onLoading={setIsLoading} dataFetchingDto={{ filterByOfferStatusTypes: [ OfferStatusType.Declined ] }} /> }
    </Page>
  );
};

export default MyOffers;
