import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import { IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  Equipment,
  SaveEquipmentDto,
  useDeleteEquipmentMutation,
  useSaveEquipmentMutation,
} from '../../../../../../graphql';

function createSaveEquipmentDto(equipment?: Equipment): SaveEquipmentDto {
  return {
    id: equipment?.id || null,
    name: equipment?.name || { fr: '' },
  };
}

interface Props {
  equipment?: Equipment,
  isAdding: boolean,
  onSaved: () => void,
}

const SaveEquipmentNameInput = ({
  equipment, isAdding, onSaved,
}: Props) => {
  const [ dto, setDto ] = useState(createSaveEquipmentDto(equipment));
  const [ saveDto ] = useSaveEquipmentMutation();
  const [ deleteEquipment ] = useDeleteEquipmentMutation();

  const handleAdd = async () => {
    await saveDto({ variables: { dto } });

    onSaved();

    if (isAdding) {
      setDto(createSaveEquipmentDto());
    }
  };

  const handleEdit = async () => {
    if (equipment && equipment.name.fr === dto.name.fr) {
      return;
    }

    await saveDto({ variables: { dto } });

    onSaved();
  };

  const handleDelete = async () => {
    await deleteEquipment({ variables: { id: equipment!.id } });

    onSaved();
  };

  useEffect(() => {
    setDto(createSaveEquipmentDto(equipment));
  }, [ equipment ]);

  return (
    <OutlinedInput
      size="small"
      onChange={({ target: { value } }) => setDto({ ...dto, name: { fr: value } })}
      value={dto.name.fr}
      onKeyUp={(event) => {
        if (event.key !== 'Enter') {
          return;
        }

        if (isAdding) {
          handleAdd();
        }

        if (!isAdding) {
          handleEdit();
        }
      }}
      placeholder="Ajouter une valeur"
      endAdornment={(
        <InputAdornment position="end">
          { !equipment && <IconButton onClick={handleAdd} edge="end"><AddIcon /></IconButton> }
          {
            equipment && dto.name !== equipment.name && (
              <IconButton onClick={handleEdit} edge="end"><DoneIcon /></IconButton>
            )
          }
          {
            equipment && dto.name === equipment.name && (
              <IconButton onClick={handleDelete} edge="end"><ClearIcon /></IconButton>
            )
          }
        </InputAdornment>
      )}
    />
  );
};

export default SaveEquipmentNameInput;
