import { useReactiveVar } from '@apollo/client';
import AirlineSeatReclineExtraIcon from '@mui/icons-material/AirlineSeatReclineExtra';
import CarCrashIcon from '@mui/icons-material/CarCrash';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import PhotoIcon from '@mui/icons-material/Photo';
import PublishIcon from '@mui/icons-material/Publish';
import { Box, Button } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  AttributeType, isLoggedUnderUserVar,
  SaveVehicleImmediatePurchaseDto,
  useDeleteOneVehicleMediaMutation,
  useGetVehicleToUpdateLazyQuery,
  usePublishVehicleMutation,
  VehicleImmediatePurchase,
  VehicleMediaTag,
  VehicleMediaType,
  VehicleStatus,
} from '../../../../../graphql';
import useReactiveAttributes from '../../../../../hooks/data/useReactiveAttributes.ts';
import useReactiveUserConnected from '../../../../../hooks/data/useReactiveUserConnected.ts';
import useImageLoader from '../../../../../hooks/useImageLoader.tsx';
import useUiToast from '../../../../../hooks/useUiToast.ts';
import useVideoLoader from '../../../../../hooks/useVideoLoader.tsx';
import { DroppedVehicleMediaFile, IdsState, StateVehicleMediaFile } from '../../../../../index';
import HStack from '../../../../ui-kit/layout/HStack.tsx';
import Page from '../../../../ui-kit/layout/Page.tsx';
import PageTitle from '../../../../ui-kit/layout/PageTitle.tsx';
import UiTabPanels from '../../../../ui-kit/navigation/tabs/UiTabPanels.tsx';
import UiTabs from '../../../../ui-kit/navigation/tabs/UiTabs.tsx';
import ConfirmVehiclePublishingDialog
  from '../../../partials/lists/vehicles/dialog-publishing-confirmation/ConfirmVehiclePublishingDialog.tsx';
import VehicleFormDeleteExistingMediaDialog
  from './dialog-delete-existing-media/VehicleFormDeleteExistingMediaDialog.tsx';
import VehicleFormPhotoDialog from './dialog-photo-editor/VehicleFormPhotoDialog.tsx';
import VehicleFormVideoPlayerDialog from './dialog-video-player/VehicleFormVideoPlayerDialog.tsx';
import {
  FilledAttributeState,
  SaveableVehicleContext,
  SaveableVehicleContextProps,
  SelectedBrandState,
} from './SaveableVehicleContext.ts';
import VehicleFormTabAttributes from './tab-attributes/VehicleFormTabAttributes.tsx';
import VehicleFormTabDamages from './tab-damages/VehicleFormTabDamages.tsx';
import VehicleFormTabEquipments from './tab-equipments/VehicleFormTabEquipments.tsx';
import VehicleFormTabMedias from './tab-medias/VehicleFormTabMedias.tsx';
import VehicleFormSaveButton from './VehicleFormSaveButton.tsx';

function createSaveVehicleImmediatePurchaseDto(vehicleImmediatePurchase?: VehicleImmediatePurchase): SaveVehicleImmediatePurchaseDto {
  return {
    enabled: vehicleImmediatePurchase ? vehicleImmediatePurchase.enabled : false,
    isVatExcluded: vehicleImmediatePurchase ? vehicleImmediatePurchase.isVatExcluded : false,
    price: vehicleImmediatePurchase?.price || 1,
  };
}

const VehicleForm = () => {
  const { id } = useParams<{ id: string, }>();
  const navigate = useNavigate();
  const [ fetchVehicle, { data: vehicleData } ] = useGetVehicleToUpdateLazyQuery();
  const { t } = useTranslation();
  const { requiredAttributes } = useReactiveAttributes();
  const { user } = useReactiveUserConnected();
  const isLoggedUnderUser = useReactiveVar(isLoggedUnderUserVar);
  const [ tabIndex, setTabIndex ] = useState(0);
  const [ selectedBrand, setSelectedBrand ] = useState<SelectedBrandState>({ brandId: null, modelId: null });
  const [ immediatePurchase, setImmediatePurchase ] = useState<SaveVehicleImmediatePurchaseDto>(createSaveVehicleImmediatePurchaseDto());
  const [ availableAt, setAvailableAt ] = useState<Date|null>(null);
  const [ filledAttributes, setFilledAttributes ] = useState<FilledAttributeState>({});
  const [ selectedAddressId, setSelectedAddressId ] = useState<number|null>(null);
  const [ selectedContactId, setSelectedContactId ] = useState<number|null>(null);
  const [ selectedDamageIds, setSelectedDamageIds ] = useState<IdsState>([]);
  const [ selectedEquipmentIds, setSelectedEquipmentIds ] = useState<IdsState>([]);
  const [ medias, setMedias ] = useState<StateVehicleMediaFile[]>([]);
  const [ droppedFile, setDroppedFile ] = useState<DroppedVehicleMediaFile|null>(null);
  const [ deletableMedia, setDeletableMedia ] = useState<DroppedVehicleMediaFile|null>(null);
  const [ isPhotoDialogOpened, setIsPhotoDialogOpened ] = useState(false);
  const [ isVideoPlayerDialogOpened, setIsVideoPlayerDialogOpened ] = useState(false);
  const [ isDeleteExistingMediaDialogOpened, setIsDeleteExistingMediaDialogOpened ] = useState(false);
  const [ isPublishingConfirmationDialogOpened, setIsPublishingConfirmationDialogOpened ] = useState(false);
  const [ publishVehicle ] = usePublishVehicleMutation();
  const [ isReady, setIsReady ] = useState(false);
  const { success } = useUiToast();
  const { loadImageFromUri } = useImageLoader(false);
  const { loadVideoFromUri } = useVideoLoader(false);
  const [ deleteVehicleMedia ] = useDeleteOneVehicleMediaMutation();

  const saveableVehicleContext: SaveableVehicleContextProps = useMemo(() => ({
    availableAt,
    filledAttributes,
    immediatePurchase,
    medias,
    selectedAddressId,
    selectedBrand,
    selectedContactId,
    selectedDamageIds,
    selectedEquipmentIds,
    setAvailableAt,
    setFilledAttributes,
    setImmediatePurchase,
    setMedias,
    setSelectedAddressId,
    setSelectedBrand,
    setSelectedContactId,
    setSelectedEquipmentIds,
    setSelectedDamageIds,
  }), [ availableAt, filledAttributes, immediatePurchase, medias, selectedAddressId, selectedBrand, selectedContactId, selectedDamageIds, selectedEquipmentIds ]);

  const handleDelete = (index: number) => {
    if (!medias[index]) {
      return;
    }

    if (!medias[index].id) {
      setMedias(medias.filter((_, mediaIndex) => mediaIndex !== index));

      return;
    }

    setDeletableMedia(medias[index]);
    setIsDeleteExistingMediaDialogOpened(true);
  };

  const handleValidateMedia = async (droppedMediaFile: DroppedVehicleMediaFile, blobOrFile: Blob|File, uri: string, equipmentOrDamageId: number|null) => {
    if (droppedMediaFile.id && droppedMediaFile.index !== null) {
      await deleteVehicleMedia({ variables: { mediaId: droppedMediaFile.id } });

      setMedias(medias.filter((media) => media.id !== droppedMediaFile.id));

      droppedMediaFile.id = null;
    }

    let file = blobOrFile;

    if (blobOrFile.constructor.name === 'Blob') {
      file = new File([ blobOrFile ], droppedMediaFile.file!.name);
    }

    const currentMedias = medias.slice();
    const media: StateVehicleMediaFile = {
      ...droppedMediaFile,
      file: file as File,
      uri,
      ...(droppedMediaFile.tag === VehicleMediaTag.Equipment ? { equipmentId: equipmentOrDamageId } : {}),
      ...(droppedMediaFile.tag === VehicleMediaTag.Damage ? { damageId: equipmentOrDamageId } : {}),
    };
    const foundExistingMediaIndex = droppedMediaFile.index;

    if (foundExistingMediaIndex !== null && foundExistingMediaIndex > -1) {
      currentMedias[foundExistingMediaIndex] = media;
    }

    setMedias(foundExistingMediaIndex !== null && foundExistingMediaIndex > -1 ? currentMedias : [ ...medias, media ]);
    setDroppedFile(null);
    setIsPhotoDialogOpened(false);
    setIsVideoPlayerDialogOpened(false);
  };

  const handleDropFile = async (droppedVehicleMediaFile: DroppedVehicleMediaFile) => {
    if (
      (
        droppedVehicleMediaFile.type === 'IMAGE'
        && droppedVehicleMediaFile.tag !== 'DAMAGE'
        && droppedVehicleMediaFile.tag !== 'EQUIPMENT'
        && (droppedVehicleMediaFile.index === null || !medias[droppedVehicleMediaFile.index])
      )
      || (droppedVehicleMediaFile.type === 'VIDEO' && (droppedVehicleMediaFile.index === null || !medias[droppedVehicleMediaFile.index]))
    ) {
      handleValidateMedia(droppedVehicleMediaFile, droppedVehicleMediaFile.file as Blob, URL.createObjectURL(droppedVehicleMediaFile.file as Blob), null);

      return;
    }

    setDroppedFile(droppedVehicleMediaFile);

    if (droppedVehicleMediaFile.type === VehicleMediaType.Image) {
      setIsPhotoDialogOpened(true);

      return;
    }

    setIsVideoPlayerDialogOpened(true);
  };

  const handlePublish = async () => {
    if (!id) {
      return;
    }

    await publishVehicle({ variables: { id: Number(id) } });

    success('Véhicule publié avec succès !');

    navigate('/my-vehicles');
  };

  useEffect(() => {
    (async () => {
      setIsReady(false);

      const { data } = await fetchVehicle({ variables: { id: Number(id) } });
      const defaultFilledAttributes: FilledAttributeState = requiredAttributes.reduce((attributes, currentAttribute) => {
        if (currentAttribute.type !== AttributeType.Boolean) {
          return attributes;
        }

        return { ...attributes, [currentAttribute.id]: false };
      }, {});

      if (!id || !data) {
        setAvailableAt(null);
        setMedias([]);
        setSelectedBrand({ brandId: null, modelId: null });
        setImmediatePurchase({ price: 1, isVatExcluded: false, enabled: false });
        setSelectedAddressId(null);
        setSelectedContactId(null);
        setSelectedEquipmentIds([]);
        setSelectedDamageIds([]);
        setFilledAttributes(defaultFilledAttributes);
        setIsReady(true);

        return;
      }

      const vehicle = data.vehicleById;

      if (!isLoggedUnderUser && (vehicle.addedByUserId !== user!.id || vehicle.status !== 'DRAFT')) {
        navigate('/my-vehicles');

        return;
      }

      vehicle.attributes?.forEach((vehicleAttribute) => {
        defaultFilledAttributes[vehicleAttribute.attributeId] = vehicleAttribute.attributeValueId ? vehicleAttribute.attributeValueId : vehicleAttribute.value;
      });

      setAvailableAt(dayjs(vehicle.availableAt).toDate());
      setImmediatePurchase(vehicle.immediatePurchase);
      setSelectedBrand({ brandId: vehicle.brandId, modelId: vehicle.modelId });
      setSelectedAddressId(vehicle.addressId);
      setSelectedContactId(vehicle.contactId ?? null);
      setSelectedEquipmentIds((vehicle.equipments || []).map((vehicleEquipment) => vehicleEquipment.equipmentId));
      setSelectedDamageIds((vehicle.damages || []).map((vehicleDamage) => vehicleDamage.damageId));
      setFilledAttributes(defaultFilledAttributes);

      const mediasToShow: any[] = [];
      const filteredMedias = vehicle.medias!.filter((vehicleMedia) => !vehicleMedia.tag.includes('THUMBNAIL'));

      for (let index = 0; index < filteredMedias.length; index++) {
        const vehicleMedia = filteredMedias[index];
        const equipmentId = vehicleMedia.vehicleEquipmentId ? vehicle.equipments!.find((vehicleEquipment) => vehicleEquipment.id === vehicleMedia.vehicleEquipmentId)!.equipmentId : null;
        const damageId = vehicleMedia.vehicleDamageId ? vehicle.damages!.find((vehicleDamage) => vehicleDamage.id === vehicleMedia.vehicleDamageId)!.damageId : null;

        mediasToShow.push({
          id: vehicleMedia.id,
          uri: vehicleMedia.file!.uri,
          // eslint-disable-next-line no-await-in-loop
          file: vehicleMedia.type === 'IMAGE' ? (await loadImageFromUri(vehicleMedia.file!.uri)).file : (await loadVideoFromUri(vehicleMedia.file!.uri)).file,
          type: vehicleMedia.type,
          tag: vehicleMedia.tag,
          equipmentId,
          damageId,
          index,
        });
      }

      setMedias(mediasToShow);
      setIsReady(true);
    })();
  }, [ id ]);

  return (
    <SaveableVehicleContext.Provider value={saveableVehicleContext}>
      <Page id="add-vehicle-page">
        <HStack id="page-title" justifyContent="space-between" alignItems="center">
          <PageTitle text={!id ? t('my-vehicle.title-add-vehicle') : t('my-vehicle.title-update-vehicle')} loading={!isReady} />
          <HStack gap={1}>
            <VehicleFormSaveButton />
            {
              vehicleData && vehicleData.vehicleById.status === VehicleStatus.Draft && (
                <Button variant="outlined" size="small" onClick={() => setIsPublishingConfirmationDialogOpened(true)} disabled={!isReady}>
                  <HStack gap={0.5}>
                    <PublishIcon style={{ fontSize: 18 }} />
                    {' '}
                    Publier
                  </HStack>
                </Button>
              )
            }
          </HStack>
        </HStack>
        <Box sx={{
          borderBottom: 1,
          borderColor: 'divider',
        }}
        >
          <UiTabs
            onChangeTab={setTabIndex}
            tabs={[
              (
                <HStack alignItems="center" gap={1}>
                  <DirectionsCarIcon />
                  { t('dictionary.characteristics') }
                </HStack>
              ),
              (
                <HStack alignItems="center" gap={1}>
                  <AirlineSeatReclineExtraIcon />
                  { t('dictionary.equipments') }
                </HStack>
              ),
              (
                <HStack alignItems="center" gap={1}>
                  <CarCrashIcon />
                  { t('dictionary.damages') }
                </HStack>
              ),
              (
                <HStack alignItems="center" gap={1}>
                  <PhotoIcon />
                  { t('my-vehicle.photos-and-videos') }
                </HStack>
              ),
            ]}
          />
        </Box>

        {
          isReady && (
            <UiTabPanels
              value={tabIndex}
              panels={[
                <VehicleFormTabAttributes />,
                <VehicleFormTabEquipments />,
                <VehicleFormTabDamages />,
                <VehicleFormTabMedias onDropFile={handleDropFile} onDelete={handleDelete} />,
              ]}
            />
          )
        }

        {
          isPhotoDialogOpened && droppedFile && (
            <VehicleFormPhotoDialog
              droppedFile={droppedFile}
              index={droppedFile.index}
              onClose={() => {
                setIsPhotoDialogOpened(false);
                setDroppedFile(null);
              }}
              onValidate={(blob, uri, equipmentOrDamageId) => handleValidateMedia(droppedFile, blob, uri, equipmentOrDamageId)}
            />
          )
        }

        {
          isDeleteExistingMediaDialogOpened && deletableMedia && (
            <VehicleFormDeleteExistingMediaDialog
              media={deletableMedia}
              onClose={() => {
                setIsDeleteExistingMediaDialogOpened(false);
                setDeletableMedia(null);
              }}
              onDeleted={() => {
                setIsDeleteExistingMediaDialogOpened(false);
                setMedias(medias.filter((media) => media.id !== deletableMedia.id));
                setDeletableMedia(null);
              }}
            />
          )
        }

        {
          isPublishingConfirmationDialogOpened && (
            <ConfirmVehiclePublishingDialog
              onClose={() => setIsPublishingConfirmationDialogOpened(false)}
              onConfirm={handlePublish}
            />
          )
        }

        {
          isVideoPlayerDialogOpened && droppedFile && (
            <VehicleFormVideoPlayerDialog
              droppedFile={droppedFile?.file || null}
              onClose={() => {
                setIsVideoPlayerDialogOpened(false);
                setDroppedFile(null);
              }}
              open={isVideoPlayerDialogOpened}
              onValidate={(blob, uri, equipmentOrDamageId) => handleValidateMedia(droppedFile, blob, uri, equipmentOrDamageId)}
            />
          )
        }
      </Page>
    </SaveableVehicleContext.Provider>
  );
};

export default VehicleForm;
