import { useMemo } from 'react';
import { SaveUserDto } from '../../../graphql';
import { isNotEmpty, isValidEmail } from '../../../helpers/validations.ts';

export default function useValidateSaveUserDto(dto: SaveUserDto|null) {
  return useMemo(
    () => dto
      && isNotEmpty(dto.email)
      && isValidEmail(dto.email)
      && isNotEmpty(dto.firstName)
      && isNotEmpty(dto.lastName)
      && isNotEmpty(dto.gender)
      && isNotEmpty(dto.phoneNumber),
    [ dto ],
  );
}
