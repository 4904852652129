import {
  Button, Dialog, TextField, Typography,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCancelVehicleMutation, Vehicle } from '../../../../../../graphql';
import CloseButton from '../../../../../ui-kit/buttons/CloseButton.tsx';
import DialogTitle from '../../../../../ui-kit/dialog/DialogTitle.tsx';
import HStack from '../../../../../ui-kit/layout/HStack.tsx';
import VStack from '../../../../../ui-kit/layout/VStack.tsx';

interface Props {
  vehicle: Vehicle,
  onClose: () => void,
  onSaved: () => void,
}

const CancelSaleDialog = ({
  vehicle, onClose, onSaved,
}: Props) => {
  const { t } = useTranslation();
  const [ dto, setDto ] = useState({ vehicleId: vehicle.id, reason: '' });
  const [ cancelVehicle ] = useCancelVehicleMutation();

  const handleCancel = async () => {
    await cancelVehicle({ variables: { dto } });
    onSaved();
  };

  return (
    <Dialog
      onClose={onClose}
      open
      // @ts-ignore
      maxWidth="md"
    >
      <VStack p={5} width={425}>
        <HStack justifyContent="space-between" alignItems="center">
          <DialogTitle text={vehicle.status === 'DRAFT' ? t('dialogs.cancel-vehicle.title-delete-this-vehicle') : t('dialogs.cancel-vehicle.title-cancel-the-sale')} loading={false} />
          <CloseButton onClose={onClose} />
        </HStack>

        {
          vehicle.status === 'DRAFT' && (
            <Typography>{t('dialogs.cancel-vehicle.you-are-going-to-delete-this-vehicle')}</Typography>
          )
        }

        {
          vehicle.status !== 'DRAFT' && (
            <>
              <Typography>{t('dialogs.cancel-vehicle.you-are-going-to-cancel-the-sale')}</Typography>
              <VStack mt={2}>
                <HStack>
                  <strong>{t('dialogs.cancel-vehicle.cancellation-reason')}</strong>
                  <span style={{ color: 'red' }}>*</span>
                </HStack>
                <TextField
                  multiline
                  variant="outlined"
                  size="small"
                  value={dto.reason}
                  onChange={({ target: { value } }) => setDto({ ...dto, reason: value })}
                  sx={{ width: '100%', mb: 2 }}
                />
              </VStack>
            </>
          )
        }

        <HStack justifyContent="flex-end" mt={2}>
          <Button onClick={handleCancel} disabled={vehicle.status !== 'DRAFT' && dto.reason.trim() === ''}>{ vehicle.status === 'DRAFT' ? t('buttons.delete') : t('buttons.cancel-the-sale')}</Button>
        </HStack>
      </VStack>
    </Dialog>
  );
};

export default CancelSaleDialog;
