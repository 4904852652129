import { Dialog, Typography } from '@mui/material';
import { useMemo } from 'react';
import { colors } from '../../../../../../config/theming.ts';
import { User } from '../../../../../../graphql';
import CloseButton from '../../../../../ui-kit/buttons/CloseButton.tsx';
import DialogTitle from '../../../../../ui-kit/dialog/DialogTitle.tsx';
import HStack from '../../../../../ui-kit/layout/HStack.tsx';
import VStack from '../../../../../ui-kit/layout/VStack.tsx';

interface Props {
  user: User,
  onClose: () => void,
}

const AdminUserDetailsDialog = ({ user, onClose }: Props) => {
  const headQuarter = useMemo(() => (user.dealer && user.dealer.addresses!.length ? user.dealer!.addresses!.find((address) => address.type === 'HEADQUARTER') : null), [ user ]);

  return (
    <Dialog
      onClose={onClose}
      open
      maxWidth="md"
    >
      <VStack width={425} p={5}>
        <HStack justifyContent="space-between" alignItems="center">
          <DialogTitle text="" />
          <CloseButton onClose={onClose} />
        </HStack>

        <Typography fontWeight="bolder" variant="h5">Profil de l'utilisateur</Typography>

        <HStack alignItems="center" justifyContent="space-between" borderBottom="1px solid black" mt={2} pb={1}>
          <Typography fontWeight="bolder" fontSize={14}>Nom et prénom</Typography>
          <Typography textAlign="right" fontSize={14}>{`${user.lastName} ${user.firstName}`}</Typography>
        </HStack>

        <HStack alignItems="center" justifyContent="space-between" borderBottom="1px solid black" mt={2} pb={1}>
          <Typography fontWeight="bolder" fontSize={14}>E-mail</Typography>
          <Typography textAlign="right" fontSize={14}><a href={`mailto:${user.email}`} style={{ color: colors.red }}>{user.email}</a></Typography>
        </HStack>

        <HStack alignItems="center" justifyContent="space-between" borderBottom="1px solid black" mt={2} pb={1}>
          <Typography fontWeight="bolder" fontSize={14}>Téléphone</Typography>
          <Typography textAlign="right" fontSize={14}><a href={`tel:${user.phoneNumber}`} style={{ color: colors.red }}>{user.phoneNumber}</a></Typography>
        </HStack>

        {
          user.dealer && (
            <>
              <Typography fontWeight="bolder" variant="h5" mt={4}>Profil de l'entreprise</Typography>

              <HStack alignItems="center" justifyContent="space-between" borderBottom="1px solid black" mt={2} pb={1}>
                <Typography fontWeight="bolder" fontSize={14}>Nom</Typography>
                <Typography textAlign="right" fontSize={14}>{user.dealer!.name}</Typography>
              </HStack>

              <HStack alignItems="center" justifyContent="space-between" borderBottom="1px solid black" mt={2} pb={1}>
                <Typography fontWeight="bolder" fontSize={14}>E-mail</Typography>
                <Typography textAlign="right" fontSize={14}><a href={`mailto:${user.dealer!.email}`} style={{ color: colors.red }}>{user.dealer!.email}</a></Typography>
              </HStack>

              <HStack alignItems="center" justifyContent="space-between" borderBottom="1px solid black" mt={2} pb={1}>
                <Typography fontWeight="bolder" fontSize={14}>Téléphone</Typography>
                <Typography textAlign="right" fontSize={14}><a href={`tel:${user.dealer!.phoneNumber}`} style={{ color: colors.red }}>{user.dealer!.phoneNumber}</a></Typography>
              </HStack>

              <HStack alignItems="center" justifyContent="space-between" borderBottom="1px solid black" mt={2} pb={1}>
                <Typography fontWeight="bolder" fontSize={14}>TVA</Typography>
                <Typography textAlign="right" fontSize={14}>{user.dealer!.vatNumber}</Typography>
              </HStack>

              <HStack alignItems="center" justifyContent="space-between" borderBottom="1px solid black" mt={2} pb={1}>
                <Typography fontWeight="bolder" fontSize={14}>Iban</Typography>
                <Typography textAlign="right" fontSize={14}>{user.dealer!.iban}</Typography>
              </HStack>

              <HStack alignItems="center" justifyContent="space-between" borderBottom="1px solid black" mt={2} pb={1}>
                <Typography fontWeight="bolder" fontSize={14}>Adresse du siège</Typography>
                <Typography textAlign="right" fontSize={14}>
                  { !headQuarter && 'Aucune adresse' }
                  {
                    headQuarter && (
                      <>
                        { `${headQuarter.street} ${headQuarter.number},` }
                        <br />
                        { `${headQuarter.zip} ${headQuarter.city}` }
                        <br />
                        { headQuarter.country }
                        <br />
                      </>
                    )
                  }
                </Typography>
              </HStack>
            </>
          )
        }
      </VStack>
    </Dialog>
  );
};

export default AdminUserDetailsDialog;
