import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import VStack from '../ui-kit/layout/VStack.tsx';

const MaintenancePage = () => {
  const navigate = useNavigate();

  return (
    <VStack position="relative" justifyContent="center" alignItems="center" height="100vh" width="100%" bgcolor="white">
      <VStack position="relative" alignItems="center" justifyContent="flex-end">
        <img src="/assets/images/maintenance.webp" style={{ borderRadius: 30 }} width={400} height={400} />
        <div style={{
          width: '100%', height: '100%', background: '#00000042', position: 'absolute', borderRadius: 30,
        }}
        />
        <img src="/assets/images/logo.png" style={{ position: 'absolute', width: 270 }} />
      </VStack>
      <h1>Site en maintenance</h1>
      <h3>Autoconnect revient vite (15 Août 2024 à 21h)</h3>
      <p>La maintenance sert à améliorer votre expérience utilisateur et corriger les bugs présents sur la plateforme.</p>
      <p>Merci d'avance pour votre compréhension.</p>
      <p>L'équipe d'Autoconnect</p>

      <Button onClick={() => navigate('/online/marketplace')} variant="outlined" sx={{ mt: 2 }}>Rafraîchir la page</Button>
    </VStack>
  );
};

export default MaintenancePage;
