import { useReactiveVar } from '@apollo/client';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  FormControlLabel, Switch, TextField, Typography,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { filters } from '../../../../../../config/filters.ts';
import {
  Attribute,
  attributesVar,
  AttributeType,
  VehicleFilterDto,
  VehicleFilterValueRangeDto,
} from '../../../../../../graphql';
import useLang from '../../../../../../hooks/useLang.ts';
import HStack from '../../../../../ui-kit/layout/HStack.tsx';
import VStack from '../../../../../ui-kit/layout/VStack.tsx';
import AttributeSelectFilter from './AttributeSelectFilter.tsx';
import BrandsAndModelsSelectFilters from './BrandsAndModelsSelectFilters.tsx';
import RangeFilter from './RangeFilter.tsx';

interface Props {
  onFiltersChange: (filters: VehicleFilterDto[]) => void,
}

const Filters = ({ onFiltersChange }: Props) => {
  const { t } = useTranslation();
  const lang = useLang();
  const attributes = useReactiveVar(attributesVar);
  const [ isOpen, setIsOpen ] = useState(false);
  const filterableAttributes = useMemo(() => attributes.filter((attribute) => attribute.filterable), [ attributes ]);
  const [ dto, setDto ] = useState<VehicleFilterDto[]>([]);

  const openFilters = () => setIsOpen(!isOpen);

  const handleFilterById = (prop: 'brandId' | 'modelId' | 'id', id: number, selected: boolean) => {
    const currentDto = [ ...dto ];
    const existingFilterIndex = dto.findIndex((filter) => filter[prop] !== undefined);

    if (selected && existingFilterIndex === -1) {
      setDto([ ...dto, { [prop]: id } ]);
    }

    if (selected && existingFilterIndex > -1) {
      currentDto[existingFilterIndex][prop] = id;
      setDto(currentDto);
    }

    if (!selected) {
      setDto(dto.filter((filter) => filter[prop] === undefined));
    }
  };

  const handleFilterBySelectAttribute = (attributeId: number, attributeValueId: number|null) => {
    const existingFilterIndex = dto.findIndex((filter) => filter.attributeId === attributeId);

    if (attributeValueId && existingFilterIndex === -1) {
      setDto([ ...dto, { attributeId, attributeValueId } ]);
    }

    if (attributeValueId && existingFilterIndex > -1) {
      const newDto = [ ...dto ];

      newDto[existingFilterIndex] = { attributeId, attributeValueId };

      setDto(newDto);
    }

    if (!attributeValueId) {
      setDto(dto.filter((filter) => filter.attributeId !== attributeId));
    }
  };

  const handleFilterByEqualsAttribute = (attributeId: number, value: any) => {
    const existingFilterIndex = dto.findIndex((filter) => filter.attributeId === attributeId);
    const isValueDeletable = value === 'undefined' || value === null || value === '';

    if (existingFilterIndex === -1) {
      setDto([ ...dto, { attributeId, value: { equals: value } } ]);
    }

    if (!isValueDeletable && existingFilterIndex > -1) {
      const newDto = [ ...dto ];

      newDto[existingFilterIndex] = { attributeId, value: { equals: value } };

      setDto(newDto);
    }

    if (isValueDeletable) {
      setDto(dto.filter((filter) => filter.attributeId !== attributeId));
    }
  };

  const handleFilterByBooleanAttribute = (attributeId: number, value: boolean) => {
    if (value && !dto.find((filter) => filter.attributeId === attributeId)) {
      setDto([ ...dto, { attributeId, value: { equals: value } } ]);
    }

    if (!value) {
      setDto(dto.filter((filter) => filter.attributeId !== attributeId));
    }
  };

  const handleFilterByRange = (attributeId: number, value: any, type: keyof VehicleFilterValueRangeDto) => {
    const existingFilterIndex = dto.findIndex((filter) => filter.attributeId === attributeId);

    if (value && existingFilterIndex === -1) {
      setDto([ ...dto, { attributeId, value: { range: { [type]: value } } } ]);
    }

    if (value && existingFilterIndex > -1) {
      const newDto = [ ...dto ];

      newDto[existingFilterIndex] = { attributeId, value: { range: { ...newDto[existingFilterIndex].value!.range!, [type]: value } } };

      setDto(newDto);
    }

    if (!value && existingFilterIndex > -1 && type === 'from' && dto[existingFilterIndex].value?.range?.to) {
      const newDto = [ ...dto ];

      newDto[existingFilterIndex] = { attributeId, value: { range: { to: value } } };

      setDto(newDto);
    }

    if (!value && existingFilterIndex > -1 && type === 'to' && dto[existingFilterIndex].value?.range?.to) {
      const newDto = [ ...dto ];

      newDto[existingFilterIndex] = { attributeId, value: { range: { from: value } } };

      setDto(newDto);
    }

    if (!value) {
      setDto(dto.filter((filter) => filter.attributeId !== attributeId));
    }
  };

  useEffect(() => {
    onFiltersChange(dto);
  }, [ dto ]);

  return (
    <VStack bgcolor="white" padding={2} borderRadius={2}>
      <HStack alignItems="center" justifyContent="space-between">
        <HStack alignItems="center" style={{ cursor: 'pointer', flex: 1 }} onClick={openFilters}>
          <DragIndicatorIcon sx={{ color: '#cf0e10' }} />
          <Typography>{t('marketplace.filter-results')}</Typography>
        </HStack>
        { isOpen && <KeyboardArrowUpIcon style={{ cursor: 'pointer' }} onClick={openFilters} /> }
        { !isOpen && <KeyboardArrowDownIcon style={{ cursor: 'pointer' }} onClick={openFilters} /> }
      </HStack>

      {
        isOpen && (
          <VStack>
            <HStack flexWrap="wrap" mt={2} gap={1.5}>
              <TextField label="ID" onChange={({ target: { value } }) => handleFilterById('id', Number(value), Number(value) > 0)} type="number" size="small" sx={{ maxWidth: 80 }} />
              <BrandsAndModelsSelectFilters
                onBrandChange={(brandId, selected) => handleFilterById('brandId', brandId, selected)}
                onModelChange={(modelId, selected) => handleFilterById('modelId', modelId, selected)}
              />

              {
                filterableAttributes.map((filterableAttribute) => {
                  if (filterableAttribute.type === AttributeType.Select) {
                    return (
                      <AttributeSelectFilter key={filterableAttribute.id} attribute={filterableAttribute as Attribute} onChange={(attributeValueId) => handleFilterBySelectAttribute(filterableAttribute.id, attributeValueId)} />
                    );
                  }

                  if (filterableAttribute.internalCode === 'kilometers') {
                    return (
                      <RangeFilter
                        key={filterableAttribute.id}
                        fromItems={filters.kilometers}
                        toItems={filters.kilometers}
                        labelFrom="KM (min)"
                        labelTo="KM (max)"
                        onFromChange={(value) => handleFilterByRange(filterableAttribute.id, Number(value) > -1 ? Number(value) : null, 'from')}
                        onToChange={(value) => handleFilterByRange(filterableAttribute.id, Number(value) > -1 ? Number(value) : null, 'to')}
                      />
                    );
                  }

                  if (filterableAttribute.internalCode === 'power') {
                    return (
                      <RangeFilter
                        key={filterableAttribute.id}
                        fromItems={filters.power}
                        toItems={filters.power}
                        labelFrom="KW (min)"
                        labelTo="KW (max)"
                        onFromChange={(value) => handleFilterByRange(filterableAttribute.id, Number(value) > -1 ? Number(value) : null, 'from')}
                        onToChange={(value) => handleFilterByRange(filterableAttribute.id, Number(value) > -1 ? Number(value) : null, 'to')}
                      />
                    );
                  }

                  if (filterableAttribute.internalCode === 'engineCapacity') {
                    return (
                      <RangeFilter
                        key={filterableAttribute.id}
                        fromItems={filters.engineCapacity}
                        toItems={filters.engineCapacity}
                        labelFrom="Cylindrée (min)"
                        labelTo="Cylindrée (max)"
                        onFromChange={(value) => handleFilterByRange(filterableAttribute.id, Number(value) > -1 ? Number(value) : null, 'from')}
                        onToChange={(value) => handleFilterByRange(filterableAttribute.id, Number(value) > -1 ? Number(value) : null, 'to')}
                      />
                    );
                  }

                  if (filterableAttribute.type === AttributeType.Boolean) {
                    return (
                      <FormControlLabel
                        key={filterableAttribute.id}
                        control={<Switch defaultChecked={false} onChange={(_event, checked) => handleFilterByBooleanAttribute(filterableAttribute.id, checked)} />}
                        label={filterableAttribute.name[lang]}
                      />
                    );
                  }

                  if (filterableAttribute.type === AttributeType.Text) {
                    return (
                      <TextField
                        key={filterableAttribute.id}
                        label={filterableAttribute.name[lang]}
                        onChange={({ target: { value } }) => handleFilterByEqualsAttribute(filterableAttribute.id, value)}
                        size="small"
                        sx={{ maxWidth: 200 }}
                      />
                    );
                  }

                  if (filterableAttribute.type === AttributeType.Number) {
                    return (
                      <TextField
                        key={filterableAttribute.id}
                        label={filterableAttribute.name[lang]}
                        onChange={({ target: { value } }) => handleFilterByEqualsAttribute(filterableAttribute.id, value !== '' ? Number(value) : null)}
                        type="number"
                        size="small"
                        sx={{ maxWidth: 150 }}
                      />
                    );
                  }

                  return <div key={filterableAttribute.id} />;
                })
              }
            </HStack>
          </VStack>
        )
      }
    </VStack>
  );
};

export default Filters;
