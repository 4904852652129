import { Context, createContext } from 'react';
import { SaveVehicleImmediatePurchaseDto } from '../../../../../graphql';
import { IdsState, Mutator, StateVehicleMediaFile } from '../../../../../index';

export interface SelectedBrandState {
  brandId: null|number,
  modelId: null|number,
}

export interface FilledAttributeState {
  [key:number]: unknown,
}

export interface SaveableVehicleContextProps {
  availableAt: Date|null,
  filledAttributes: FilledAttributeState,
  immediatePurchase: SaveVehicleImmediatePurchaseDto,
  medias: StateVehicleMediaFile[],
  selectedAddressId: number|null,
  selectedBrand: SelectedBrandState,
  selectedContactId: number|null,
  selectedDamageIds: IdsState,
  selectedEquipmentIds: IdsState,
  setAvailableAt: Mutator<Date|null>,
  setFilledAttributes: Mutator<FilledAttributeState>,
  setImmediatePurchase: Mutator<SaveVehicleImmediatePurchaseDto>,
  setMedias: Mutator<StateVehicleMediaFile[]>,
  setSelectedAddressId: Mutator<number|null>,
  setSelectedBrand: Mutator<SelectedBrandState>,
  setSelectedContactId: Mutator<number|null>,
  setSelectedDamageIds: Mutator<IdsState>,
  setSelectedEquipmentIds: Mutator<IdsState>,
}

export const SaveableVehicleContext: Context<SaveableVehicleContextProps> = createContext<SaveableVehicleContextProps>({
  availableAt: null,
  filledAttributes: {},
  immediatePurchase: { enabled: false, isVatExcluded: false, price: 1 },
  medias: [],
  selectedAddressId: null,
  selectedBrand: { brandId: null, modelId: null },
  selectedContactId: null,
  selectedDamageIds: [],
  selectedEquipmentIds: [],
  setAvailableAt: () => {},
  setFilledAttributes: () => {},
  setImmediatePurchase: () => {},
  setMedias: () => {},
  setSelectedAddressId: () => {},
  setSelectedBrand: () => {},
  setSelectedContactId: () => {},
  setSelectedEquipmentIds: () => {},
  setSelectedDamageIds: () => {},
});
