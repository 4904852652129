import { Button, Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDeleteOneVehicleMediaMutation } from '../../../../../../graphql';
import { DroppedVehicleMediaFile } from '../../../../../../index';
import VStack from '../../../../../ui-kit/layout/VStack';
import HStack from '../../../../../ui-kit/layout/HStack';
import CloseButton from '../../../../../ui-kit/buttons/CloseButton.tsx';

interface Props {
  media: DroppedVehicleMediaFile,
  onClose: () => void,
  onDeleted: () => void,
}

const VehicleFormDeleteExistingMediaDialog = ({
  media, onClose, onDeleted,
}: Props) => {
  const { t } = useTranslation();
  const [ deleteVehicleMedia ] = useDeleteOneVehicleMediaMutation();

  const handleDelete = async () => {
    await deleteVehicleMedia({ variables: { mediaId: media.id! } });
    onDeleted();
  };

  return (
    <Dialog
      onClose={onClose}
      open
      // @ts-ignore
      maxWidth="md"
    >
      <VStack p={5}>
        <HStack justifyContent="space-between" alignItems="center">
          <h2>{ t('my-vehicle.delete-media') }</h2>
          <CloseButton onClose={onClose} />
        </HStack>

        {t('my-vehicle.are-you-sure-to-delete-media')}

        <HStack justifyContent="flex-end" mt={2}>
          <Button size="small" onClick={handleDelete}>{t('buttons.delete')}</Button>
        </HStack>
      </VStack>
    </Dialog>
  );
};

export default VehicleFormDeleteExistingMediaDialog;
