import { useMemo } from 'react';
import { Offer } from '../../graphql';

export default function useVehicleOffers(vehicleOffers: Offer[]) {
  const latestNormalOffer = useMemo(() => vehicleOffers.filter((offer) => offer.type === 'NORMAL').slice().pop(), [ vehicleOffers ]);
  const latestNormalOfferStatus = useMemo(() => (latestNormalOffer ? latestNormalOffer.statuses!.slice().pop() : null), [ latestNormalOffer ]);
  const latestImmediatePurchaseOffer = useMemo(() => vehicleOffers.filter((offer) => offer.type === 'IMMEDIATE_PURCHASE').slice().pop(), [ vehicleOffers ]);
  const hasOfferPending = useMemo(
    () => !latestImmediatePurchaseOffer && latestNormalOfferStatus?.type === 'WAITING',
    [ latestImmediatePurchaseOffer, latestNormalOfferStatus ],
  );
  const hasOfferAccepted = useMemo(
    () => latestNormalOfferStatus?.type === 'ACCEPTED',
    [ latestImmediatePurchaseOffer, latestNormalOfferStatus ],
  );
  const hasOfferWon = useMemo(
    () => latestImmediatePurchaseOffer || latestNormalOfferStatus?.type === 'WON',
    [ latestImmediatePurchaseOffer, latestNormalOfferStatus ],
  );
  const hasOfferDeclined = useMemo(
    () => !latestImmediatePurchaseOffer && latestNormalOfferStatus?.type === 'DECLINED',
    [ latestImmediatePurchaseOffer, latestNormalOfferStatus ],
  );
  const hasOfferCancelled = useMemo(
    () => !latestImmediatePurchaseOffer && latestNormalOfferStatus?.type === 'CANCELLED',
    [ latestImmediatePurchaseOffer, latestNormalOfferStatus ],
  );

  return {
    latestNormalOffer: latestNormalOffer?.statuses?.slice().pop()?.type !== 'CANCELLED' ? latestNormalOffer : null,
    latestImmediatePurchaseOffer,
    latestNormalOfferStatus,
    hasOfferAccepted,
    hasOfferWon,
    hasOfferDeclined,
    hasOfferPending,
    hasOfferCancelled,
  };
}
