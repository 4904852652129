import {
  FC, ReactNode, useEffect, useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { offlineRoutes } from '../../config/constants.ts';
import { loggedIn, userConnected } from '../../graphql/apollo/apollo';
import { useMeLazyQuery, User, useValidateTokenLazyQuery } from '../../graphql/generated';
import { inArray } from '../../helpers/array.ts';
import useAppInitialization from '../../hooks/useAppInitialization.ts';
import useLocalStorage from '../../hooks/useLocalStorage';
import PageLoader from '../ui-kit/utils/PageLoader.tsx';

interface Props {
  children?: ReactNode | null,
}

const AutoLoginProvider: FC<Props> = ({ children = null }) => {
  const { get } = useLocalStorage();
  const [ isAutoLogging, setAutoLogging ] = useState(get('bearer-token', null) !== null);
  const [ validateToken, { loading } ] = useValidateTokenLazyQuery();
  const [ getCurrentUser, { data: currentUser } ] = useMeLazyQuery();
  const { initializeApplication, isAppInitialized } = useAppInitialization();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    void (async () => {
      try {
        if (!get('bearer-token', null)) {
          setAutoLogging(false);

          return;
        }

        await validateToken({ variables: { token: get('bearer-token', '') } });

        await initializeApplication();

        if (inArray(location.pathname, offlineRoutes)) {
          navigate('/online/marketplace');
        }
      } catch (error) {
        setAutoLogging(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (currentUser) {
      setAutoLogging(false);
      userConnected(currentUser.getLoggedUser as User);
      loggedIn(true);
    }
  }, [ currentUser ]);

  useEffect(() => {
    if (isAppInitialized) {
      (async () => {
        const { data: user } = await getCurrentUser();

        if (!user) {
          setAutoLogging(false);
        }
      })();
    }
  }, [ isAppInitialized ]);

  if (isAutoLogging || loading) {
    return <PageLoader />;
  }

  return <>{ children }</>;
};

export default AutoLoginProvider;
