import { useReactiveVar } from '@apollo/client';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { Attribute, attributeCategoriesVar, attributesVar } from '../../../../../../graphql';
import useLang from '../../../../../../hooks/useLang.ts';
import VehicleFormAddressToCollectSelect from './VehicleFormAddressToCollectSelect.tsx';
import VehicleFormAttributeInput from './VehicleFormAttributeInput.tsx';
import VehicleFormAvailableDateInput from './VehicleFormAvailableDateInput.tsx';
import VehicleFormBrandSelect from './VehicleFormBrandSelect.tsx';
import VehicleFormContactSelect from './VehicleFormContactSelect.tsx';
import VehicleFormImmediatePurchase from './VehicleFormImmediatePurchase.tsx';

const VehicleFormTabAttributes = () => {
  const lang = useLang();
  const attributes = useReactiveVar(attributesVar);
  const attributeCategories = useReactiveVar(attributeCategoriesVar);

  return (
    <>
      {
        attributeCategories.map((category) => (
          <Accordion sx={{ maxWidth: 600 }} key={category.id} defaultExpanded={category.id === 1}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              { category.name[lang] }
            </AccordionSummary>
            <AccordionDetails>
              {
                category.id === 1 && <VehicleFormBrandSelect />
              }

              {
                attributes.filter((attribute) => attribute.categoryId === category.id).sort((a, b) => a.displayOrder - b.displayOrder).map((attribute) => (
                  <VehicleFormAttributeInput
                    attribute={attribute as Attribute}
                    key={attribute.id}
                  />
                ))
              }

              {
                category.id === 3 && (
                  <>
                    <VehicleFormAvailableDateInput />
                    <VehicleFormAddressToCollectSelect />
                    <VehicleFormContactSelect />
                    <VehicleFormImmediatePurchase />
                  </>
                )
              }
            </AccordionDetails>
          </Accordion>
        ))
      }
    </>
  );
};

export default VehicleFormTabAttributes;
