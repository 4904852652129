import { useReactiveVar } from '@apollo/client';
import { useMemo } from 'react';
import { Attribute, attributesVar } from '../../graphql';

export default function useReactiveAttributes() {
  const attributes = useReactiveVar(attributesVar);
  const requiredAttributes = useMemo(() => attributes.filter((attribute) => attribute.mandatory), [ attributes ]);
  const requiredAttributeIds = useMemo(() => requiredAttributes.map((attribute) => attribute.id), [ requiredAttributes ]);

  return {
    attributes,
    requiredAttributes,
    requiredAttributeIds,
    findAttributeById: (id: number) => attributes.find((attribute) => attribute.id === id),
    findAttributeValueById: (attribute: Attribute, valueId: number) => attribute.values?.find((value) => value.id === valueId),
    findAttributesByInternalCodes: (internalCodes: string[]) => attributes.filter((attribute) => internalCodes.includes(attribute.internalCode || '')),
  };
}
