import { Context, createContext } from 'react';

export interface OfflineDialogsContextProps {
  openLoginDialog: () => void,
  openForgottenPasswordDialog: () => void,
  openRegisterDialog: () => void,
}

export const OfflineDialogsContext: Context<OfflineDialogsContextProps> = createContext<OfflineDialogsContextProps>({
  openLoginDialog: () => {},
  openForgottenPasswordDialog: () => {},
  openRegisterDialog: () => {},
});
