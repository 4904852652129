import FavoriteIcon from '@mui/icons-material/Favorite';
import { Chip, Tooltip } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAddVehicleToFavoritesMutation, useDeleteVehicleFromFavoritesMutation, Vehicle } from '../../../../graphql';
import useReactiveUserConnected from '../../../../hooks/data/useReactiveUserConnected.ts';
import HStack from '../../../ui-kit/layout/HStack.tsx';

interface Props {
  vehicle: Vehicle,
  onSavedFavorite: (isFavorite: boolean) => void,
}

const VehicleFavoriteChip = ({ vehicle, onSavedFavorite }: Props) => {
  const { t } = useTranslation();
  const { user, refetchMe } = useReactiveUserConnected();
  const [ addFavorite, { loading: isAdding } ] = useAddVehicleToFavoritesMutation();
  const [ deleteFavorite, { loading: isDeleting } ] = useDeleteVehicleFromFavoritesMutation();
  const isInFavorite = useMemo(() => user!.vehicleIdsInFavorites!.includes(vehicle.id), [ vehicle, user!.vehicleIdsInFavorites ]);

  const handleClick = async () => {
    if (isAdding || isDeleting) {
      return;
    }

    const vehicleId = vehicle.id;

    if (isInFavorite) {
      await deleteFavorite({ variables: { vehicleId } });
      await refetchMe();

      onSavedFavorite(false);

      return;
    }

    await addFavorite({ variables: { vehicleId } });
    await refetchMe();

    onSavedFavorite(true);
  };

  if (user!.id === vehicle.addedByUserId) {
    return (<div />);
  }

  return (
    <Tooltip title={isInFavorite ? t('buttons.remove-from-my-favorites') : t('buttons.add-to-my-favorites')} placement="top">
      <Chip
        sx={{
          position: 'absolute',
          top: 10,
          right: 10,
          color: 'white',
        }}
        label={(
          <HStack alignItems="center" onClick={handleClick} width="100%" height="100" sx={{ cursor: 'pointer' }}>
            { isInFavorite ? <FavoriteIcon sx={{ color: 'secondary.main' }} /> : <FavoriteIcon /> }
          </HStack>
        )}
        variant="filled"
      />
    </Tooltip>
  );
};

export default VehicleFavoriteChip;
