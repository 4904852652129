import StarIcon from '@mui/icons-material/StarPurple500';
import {
  AppBar, Button, Rating, Typography,
} from '@mui/material';
import { useContext } from 'react';
import useReactiveUserConnected from '../../../hooks/data/useReactiveUserConnected.ts';
import HeaderLanguagesMenu from '../../pages/partials/menus/HeaderLanguagesMenu.tsx';
import HeaderMainMenu from '../../pages/partials/menus/HeaderMainMenu.tsx';
import HeaderNotificationMenu from '../../pages/partials/menus/HeaderNotificationMenu.tsx';
import { OfflineDialogsContext } from '../../providers/OfflineDialogsContext.ts';
import HStack from './HStack.tsx';
import '../../../styles/layout/header.scss';
import VStack from './VStack.tsx';

const Header = () => {
  const { user, dealer, isConnected } = useReactiveUserConnected();
  const { openLoginDialog } = useContext(OfflineDialogsContext);

  return (
    <AppBar
      className="header"
      position="static"
      color="inherit"
      sx={{
        width: '100%',
        backgroundColor: '#030b21',
        border: 0,
        height: 100,
        boxShadow: 'none',
        padding: '0 2rem',
        justifyContent: 'center',
        flexDirection: 'row',
        position: 'fixed',
        top: 0,
        zIndex: 1000,
      }}
    >
      <VStack className="logo" width="20%" height="100%" alignItems="center" justifyContent="center">
        <img src="/assets/images/logo.png" width={112} height={80} />
      </VStack>
      <HStack flex={1} height="100%" alignItems="center">
        {
          isConnected && (
            <VStack
              justifyContent="center"
              style={{
                color: 'white',
                flex: '1',
              }}
            >
              <Typography variant="h6">
                { `${user!.firstName} (${dealer?.name})` }
              </Typography>
              <HStack maxWidth={120}>
                <Rating
                  size="small"
                  value={user?.averageRatingScore ?? 0}
                  precision={0.5}
                  emptyIcon={<StarIcon style={{ opacity: 1, color: 'grey' }} fontSize="inherit" />}
                  onMouseOver={(event) => event.stopPropagation()}
                  readOnly
                />
              </HStack>
            </VStack>
          )
        }

        { !isConnected && <HStack flex={1} /> }

        <HStack alignItems="center">
          { !isConnected && <Button onClick={openLoginDialog} size="small" variant="contained">Se connecter</Button>}
          <HeaderLanguagesMenu />
          { isConnected && <HeaderNotificationMenu /> }
          { isConnected && <HeaderMainMenu /> }
        </HStack>
      </HStack>
    </AppBar>
  );
};

export default Header;
