import { Badge } from '@mui/material';
import dayjs from 'dayjs';
import { Notification } from '../../../../graphql';

interface Props {
  notification: Notification,
}

const NotificationBadge = ({ notification }: Props) => {
  if (notification.status === 'SENT' || (notification.readAt && dayjs().diff(notification.readAt, 'minutes') < 5)) {
    return <Badge color="secondary" variant="dot" />;
  }

  return <div />;
};

export default NotificationBadge;
