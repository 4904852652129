import { Context, createContext } from 'react';
import { User } from '../../../../../graphql';

export interface AdminUsersContextProps {
  selectedUser: User|null,
  openDialog: (dialog: 'subscriptions' | 'details' | 'delete', user: User) => void,
  loginUnderUser: (user: User) => void,
}

export const AdminUsersContext: Context<AdminUsersContextProps> = createContext<AdminUsersContextProps>({
  selectedUser: null,
  openDialog: () => {},
  loginUnderUser: () => {},
});
