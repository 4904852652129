import { IconButton, Menu, MenuItem } from '@mui/material';
import { MouseEvent, useState } from 'react';
import { Language, langVar, useChangeMyLanguageMutation } from '../../../../graphql';
import useReactiveUserConnected from '../../../../hooks/data/useReactiveUserConnected.ts';
import useLang from '../../../../hooks/useLang.ts';
import i18n from '../../../../i18n.ts';

const HeaderLanguagesMenu = () => {
  const lang = useLang();
  const { refetchMe } = useReactiveUserConnected();
  const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [ changeMyLanguage ] = useChangeMyLanguageMutation();

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectLanguage = async (language: Language) => {
    await i18n.changeLanguage(language.toLowerCase());

    langVar(language.toLowerCase());

    await changeMyLanguage({ variables: { language } });

    refetchMe();
  };

  return (
    <>
      <IconButton
        onClick={handleClick}
        size="small"
        sx={{ ml: 2 }}
        aria-controls={open ? 'languages-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <img src={`/assets/images/icons/languages/${lang}.png`} width={30} height={30} />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        id="languages-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => {
          handleSelectLanguage(Language.Fr);
          handleClose();
        }}
        >
          <img src="/assets/images/icons/languages/fr.png" width={20} height={20} />
        </MenuItem>
        <MenuItem onClick={() => {
          handleSelectLanguage(Language.En);
          handleClose();
        }}
        >
          <img src="/assets/images/icons/languages/en.png" width={20} height={20} />
        </MenuItem>
        <MenuItem onClick={() => {
          handleSelectLanguage(Language.Nl);
          handleClose();
        }}
        >
          <img src="/assets/images/icons/languages/nl.png" width={20} height={20} />
        </MenuItem>
        <MenuItem onClick={() => {
          handleSelectLanguage(Language.It);
          handleClose();
        }}
        >
          <img src="/assets/images/icons/languages/it.png" width={20} height={20} />
        </MenuItem>
        <MenuItem onClick={() => {
          handleSelectLanguage(Language.Es);
          handleClose();
        }}
        >
          <img src="/assets/images/icons/languages/es.png" width={20} height={20} />
        </MenuItem>
        <MenuItem onClick={() => {
          handleSelectLanguage(Language.De);
          handleClose();
        }}
        >
          <img src="/assets/images/icons/languages/de.png" width={20} height={20} />
        </MenuItem>
        <MenuItem onClick={() => {
          handleSelectLanguage(Language.El);
          handleClose();
        }}
        >
          <img src="/assets/images/icons/languages/el.png" width={20} height={20} />
        </MenuItem>
        <MenuItem onClick={() => {
          handleSelectLanguage(Language.Pl);
          handleClose();
        }}
        >
          <img src="/assets/images/icons/languages/pl.png" width={20} height={20} />
        </MenuItem>
      </Menu>
    </>
  );
};

export default HeaderLanguagesMenu;
