import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import { IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import { useState } from 'react';
import { SaveAttributeDto, useDeleteAttributeValueMutation, useSaveAttributeMutation } from '../../../../../../graphql';

interface Props {
  dto: SaveAttributeDto,
  index?: number,
  isAdding: boolean,
  onSaved: () => void,
}

const SaveAttributeValueInput = ({
  dto, index, isAdding, onSaved,
}: Props) => {
  const [ deleteAttributeValue ] = useDeleteAttributeValueMutation();
  const [ inputValue, setInputValue ] = useState('');
  const [ onFocusValueIndex, setOnFocusValueIndex ] = useState(-1);
  const [ saveDto ] = useSaveAttributeMutation();

  const handleAdd = async () => {
    if (inputValue === '') {
      return;
    }

    const changedDto = { ...dto, values: [ ...dto.values, { id: null, value: { fr: inputValue } } ] };

    setInputValue('');

    await saveDto({ variables: { dto: changedDto } });
    onSaved();
  };

  const handleDelete = async () => {
    if (dto.values[index!].id) {
      await deleteAttributeValue({ variables: { id: dto.values[index!].id! } });
    }

    onSaved();
  };

  const handleEdit = async () => {
    const values = dto.values.slice();

    values[index!].value.fr = inputValue;

    const changedDto = { ...dto, values };

    await saveDto({ variables: { dto: changedDto } });

    onSaved();
  };

  if (isAdding) {
    return (
      <OutlinedInput
        size="small"
        onChange={({ target: { value } }) => setInputValue(value)}
        value={inputValue}
        onKeyUp={(event) => {
          if (event.key === 'Enter') {
            handleAdd();
          }
        }}
        placeholder="Ajouter une valeur"
        endAdornment={(
          <InputAdornment position="end">
            <IconButton
              onClick={handleAdd}
              edge="end"
            >
              <AddIcon />
            </IconButton>
          </InputAdornment>
        )}
      />
    );
  }

  return (
    <OutlinedInput
      size="small"
      onChange={({ target: { value } }) => setInputValue(value)}
      value={onFocusValueIndex === index ? inputValue : dto.values[index!].value.fr}
      onKeyUp={(event) => {
        if (event.key === 'Enter') {
          handleEdit();
        }
      }}
      onFocus={() => {
        setInputValue(dto.values[index!].value.fr);
        setOnFocusValueIndex(index!);
      }}
      onBlur={() => {
        setInputValue('');
        setOnFocusValueIndex(-1);
      }}
      endAdornment={(
        <InputAdornment position="end">
          {
            onFocusValueIndex !== index && (
              <IconButton onClick={handleDelete} edge="end"><ClearIcon /></IconButton>
            )
          }
          {
            onFocusValueIndex === index && inputValue !== dto.values[index!].value.fr && (
              <IconButton onClick={handleEdit} edge="end"><DoneIcon /></IconButton>
            )
          }
        </InputAdornment>
      )}
    />
  );
};

export default SaveAttributeValueInput;
