import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import HStack from '../../../ui-kit/layout/HStack.tsx';
import Page from '../../../ui-kit/layout/Page.tsx';
import PageTitle from '../../../ui-kit/layout/PageTitle.tsx';
import VehiclesList from '../../partials/lists/vehicles/VehiclesList.tsx';

const Marketplace = () => {
  const { t } = useTranslation();
  const [ isLoading, setIsLoading ] = useState(true);
  const [ totalVehicles, setTotalVehicles ] = useState<number|null>(null);

  return (
    <Page id="vehicles-list-page">
      <PageTitle
        text={(
          <HStack gap={0.5}>
            {t('dictionary.marketplace')}
            <span style={{ fontSize: 16, fontWeight: 'bold' }}>{`${totalVehicles !== null ? ` (${totalVehicles})` : ''}`}</span>
          </HStack>
        )}
        loading={isLoading}
      />

      <VehiclesList listType="marketplace" onLoading={setIsLoading} dataFetchingDto={{}} onFetchedTotal={(total) => setTotalVehicles(total)} />
    </Page>
  );
};

export default Marketplace;
