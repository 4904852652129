import { useMemo } from 'react';
import { SaveDealerAddressDto } from '../../../graphql';
import { isNotEmpty } from '../../../helpers/validations.ts';

export default function useValidateSaveDealerAddressDto(dto: SaveDealerAddressDto|null) {
  return useMemo(
    () => dto
      && isNotEmpty(dto.street)
      && isNotEmpty(dto.number)
      && isNotEmpty(dto.zip)
      && isNotEmpty(dto.city)
      && isNotEmpty(dto.country),
    [ dto ],
  );
}
