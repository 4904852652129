import { useMemo } from 'react';
import { RegisterDto } from '../../../graphql';
import { isNotEmpty, isValidEmail } from '../../../helpers/validations.ts';

export default function useValidateRegisterDto(dto: RegisterDto|null) {
  return useMemo(
    () => dto
      && isNotEmpty(dto.email)
      && isValidEmail(dto.email)
      && isNotEmpty(dto.password)
      && isNotEmpty(dto.firstName)
      && isNotEmpty(dto.lastName)
      && isNotEmpty(dto.gender)
      && isNotEmpty(dto.phoneNumber)
      && dto.hasAcceptedTermsAndConditions
      && dto.hasAcceptedPrivacyPolicy,
    [ dto ],
  );
}
