import { ReactNode } from 'react';
import UiTabPanel from './UiTabPanel.tsx';

interface Props {
  panels: ReactNode[],
  value: number,
  padding?: number,
}

const UiTabPanels = ({ panels, value, padding = 3 }: Props) => (
  <>
    {
      panels.map((panel, index) => (
        <UiTabPanel value={value} index={index} key={String(index)} padding={padding}>
          { panel }
        </UiTabPanel>
      ))
    }
  </>
);

export default UiTabPanels;
