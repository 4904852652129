import { ReactNode, useMemo, useState } from 'react';
import { useGetOneVehicleLazyQuery, Vehicle } from '../../graphql';
import VehicleDetailsDialog from '../pages/partials/lists/vehicles/dialog-vehicle-details/VehicleDetailsDialog.tsx';
import { VehicleDetailsDialogContext, VehicleDetailsDialogContextProps } from './VehicleDetailsDialogContext.ts';

interface Props {
  children: ReactNode,
}

const VehicleDetailsDialogProvider = ({ children }: Props) => {
  const [ vehicle, setVehicle ] = useState<Vehicle | null>(null);
  const [ dialogOpen, setDialogOpen ] = useState(false);
  const [ fetchVehicle ] = useGetOneVehicleLazyQuery();

  const context: VehicleDetailsDialogContextProps = useMemo(() => ({
    openDialogByVehicle: (vehicleToOpen) => {
      setVehicle(vehicleToOpen);
      setDialogOpen(true);
    },
    openDialogByVehicleId: async (vehicleId: number) => {
      const { data } = await fetchVehicle({ variables: { id: vehicleId } });

      if (data) {
        setVehicle(data.vehicleById as Vehicle);
        setDialogOpen(true);
      }
    },
  }), []);

  return (
    <VehicleDetailsDialogContext.Provider value={context}>
      { children }
      { vehicle && dialogOpen && <VehicleDetailsDialog vehicle={vehicle} onClose={() => setDialogOpen(false)} /> }
    </VehicleDetailsDialogContext.Provider>
  );
};

export default VehicleDetailsDialogProvider;
