import {
  Box, Button, LinearProgress, LinearProgressProps, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useSaveVehicle from '../../../../../hooks/data/useSaveVehicle.ts';
import useUiToast from '../../../../../hooks/useUiToast.ts';
import VStack from '../../../../ui-kit/layout/VStack.tsx';

const LinearProgressWithLabel = (props: LinearProgressProps & { value: number, }) => {
  const { t } = useTranslation();

  return (
    <VStack sx={{
      display: 'flex', justifyContent: 'center', alignItems: 'center', width: 200,
    }}
    >
      <Box sx={{
        width: '100%', position: 'relative', textAlign: 'center',
      }}
      >
        <LinearProgress variant="buffer" {...props} />
        <Typography
          variant="body1"
          sx={{
            position: 'absolute', color: 'white', textAlign: 'center', width: '100%', fontSize: 12, top: 2,
          }}
        >
          {`${Math.round(
            props.value,
          )}%`}
        </Typography>
      </Box>
      <Typography variant="body1" fontSize={9} mt={0.5}>
        { props.value < 50 && t('my-vehicle.save-progress.saving-vehicle-data') }
        { props.value >= 50 && props.value <= 75 && t('my-vehicle.save-progress.uploading-medias') }
        { props.value === 80 && t('my-vehicle.save-progress.photos-optimizing') }
        { props.value > 80 && t('my-vehicle.save-progress.finalizing') }
      </Typography>
    </VStack>
  );
};

const VehicleFormSaveButton = () => {
  const { t } = useTranslation();
  const [ saveVehicle, { progression, canSave } ] = useSaveVehicle();
  const navigate = useNavigate();
  const { success } = useUiToast();

  const handleSave = async () => {
    await saveVehicle();

    navigate('/my-vehicles');

    success('Véhicule sauvegardé !');
  };

  if (progression > -1) {
    return (<LinearProgressWithLabel value={progression} sx={{ height: 20, borderRadius: 100 }} />);
  }

  return (
    <Button variant="contained" disabled={!canSave} onClick={handleSave}>{t('buttons.save')}</Button>
  );
};

export default VehicleFormSaveButton;
