import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import { IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  DamageLocation, SaveDamageLocationDto,
  useDeleteDamageLocationMutation,
  useSaveDamageLocationMutation,
} from '../../../../../../graphql';

function createSaveDamageLocationDto(damageLocation?: DamageLocation): SaveDamageLocationDto {
  return {
    id: damageLocation?.id || null,
    name: damageLocation?.name || { fr: '' },
  };
}

interface Props {
  damageLocation?: DamageLocation,
  isAdding: boolean,
  onSaved: () => void,
}

const SaveDamageLocationNameInput = ({
  damageLocation, isAdding, onSaved,
}: Props) => {
  const [ dto, setDto ] = useState(createSaveDamageLocationDto(damageLocation));
  const [ saveDto ] = useSaveDamageLocationMutation();
  const [ deleteDamageLocation ] = useDeleteDamageLocationMutation();

  const handleAdd = async () => {
    await saveDto({ variables: { dto } });

    onSaved();

    if (isAdding) {
      setDto(createSaveDamageLocationDto());
    }
  };

  const handleEdit = async () => {
    if (damageLocation && damageLocation.name.fr === dto.name.fr) {
      return;
    }

    await saveDto({ variables: { dto } });

    onSaved();
  };

  const handleDelete = async () => {
    await deleteDamageLocation({ variables: { id: damageLocation!.id } });

    onSaved();
  };

  useEffect(() => {
    setDto(createSaveDamageLocationDto(damageLocation));
  }, [ damageLocation ]);

  return (
    <OutlinedInput
      size="small"
      onChange={({ target: { value } }) => setDto({ ...dto, name: { fr: value } })}
      value={dto.name.fr}
      onKeyUp={(event) => {
        if (event.key !== 'Enter') {
          return;
        }

        if (isAdding) {
          handleAdd();
        }

        if (!isAdding) {
          handleEdit();
        }
      }}
      placeholder="Ajouter une valeur"
      endAdornment={(
        <InputAdornment position="end">
          { !damageLocation && <IconButton onClick={handleAdd} edge="end"><AddIcon /></IconButton> }
          {
            damageLocation && dto.name !== damageLocation.name && (
              <IconButton onClick={handleEdit} edge="end"><DoneIcon /></IconButton>
            )
          }
          {
            damageLocation && dto.name === damageLocation.name && (
              <IconButton onClick={handleDelete} edge="end"><ClearIcon /></IconButton>
            )
          }
        </InputAdornment>
      )}
    />
  );
};

export default SaveDamageLocationNameInput;
