import { useReactiveVar } from '@apollo/client';
import { MenuItem, Select } from '@mui/material';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { brandsVar, Model } from '../../../../../../graphql';
import HStack from '../../../../../ui-kit/layout/HStack.tsx';

interface Props {
  onBrandChange: (brandId: number, selected: boolean) => void,
  onModelChange: (modelId: number, selected: boolean) => void,
}

const BrandsAndModelsSelectFilters = ({ onBrandChange, onModelChange }: Props) => {
  const { t } = useTranslation();
  const brands = useReactiveVar(brandsVar);
  const sortedBrands = useMemo(() => (brands.length
    ? brands.slice().sort((a, b) => a.name.localeCompare(b.name))
    : []), [ brands ]);
  const [ selectedBrand, setSelectedBrand ] = useState<any>({ id: 0, models: [] });
  const [ selectedModel, setSelectedModel ] = useState<any>({ id: 0 });

  return (
    <HStack gap={1.5}>
      <Select
        displayEmpty
        size="small"
        value={selectedBrand.id}
        onChange={({ target: { value: id } }) => {
          if (!id) {
            setSelectedBrand({ id: 0, models: [] });
            setSelectedModel({ id: 0 });

            onBrandChange(id, false);
            return;
          }

          setSelectedBrand(sortedBrands.find((brand) => brand.id === Number(id)));
          setSelectedModel({ id: 0 });

          onBrandChange(id, true);
        }}
        MenuProps={{ sx: { maxHeight: 500 } }}
      >
        <MenuItem value={0}>{t('dictionary.brand')}</MenuItem>
        {
          sortedBrands.map((brand) => (
            <MenuItem value={brand.id} key={brand.id}>{ brand.name }</MenuItem>
          ))
        }
      </Select>
      <Select
        displayEmpty
        size="small"
        value={selectedModel.id}
        onChange={({ target: { value: id } }) => {
          if (!id) {
            setSelectedModel({ id: 0 });

            onModelChange(id, false);

            return;
          }

          setSelectedModel(selectedBrand.models.find((model: Model) => model.id === Number(id)));

          onModelChange(id, true);
        }}
        MenuProps={{ sx: { maxHeight: 500 } }}
      >
        <MenuItem value={0}>{t('dictionary.model')}</MenuItem>
        {
          (selectedBrand.models as Model[]).slice().sort((a, b) => a.name.localeCompare(b.name)).map((model: Model) => (
            <MenuItem value={model.id} key={model.id}>{ model.name }</MenuItem>
          ))
        }
      </Select>
    </HStack>
  );
};

export default BrandsAndModelsSelectFilters;
