import CarCrashIcon from '@mui/icons-material/CarCrash';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DrawIcon from '@mui/icons-material/Draw';
import GarageIcon from '@mui/icons-material/Garage';
import NoCrashIcon from '@mui/icons-material/NoCrash';
import { Chip } from '@mui/material';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { VehicleStatus } from '../../../../graphql';
import HStack from '../../../ui-kit/layout/HStack.tsx';

function getColor(status: VehicleStatus) {
  if (status === VehicleStatus.Published) {
    return { bgcolor: 'primary.main', color: 'white', border: 'thin solid limegreen' };
  }

  if (status === VehicleStatus.Deleted) {
    return { bgcolor: 'secondary.main', color: 'white', border: 'thin solid white' };
  }

  if (status === VehicleStatus.Expired) {
    return { bgcolor: 'primary.main', color: 'white', border: 'thin solid orange' };
  }

  if (status === VehicleStatus.Finished) {
    return { bgcolor: '#9bff9b', color: 'primary.main', border: 'thin solid darkgreen' };
  }

  if (status === VehicleStatus.Validated) {
    return { bgcolor: 'primary.main', color: 'white', border: 'thin solid #9bff9b' };
  }

  return { bgcolor: 'primary.main', color: 'white', border: 'thin solid white' };
}

function getLabel(status: VehicleStatus) {
  const { t } = useTranslation();

  return (
    <HStack alignItems="center" gap={0.5}>
      { status === VehicleStatus.Draft && <DrawIcon style={{ fontSize: 18 }} /> }
      { status === VehicleStatus.Published && <GarageIcon style={{ fontSize: 18 }} /> }
      { status === VehicleStatus.Expired && <CarCrashIcon style={{ fontSize: 18 }} /> }
      { status === VehicleStatus.Validated && <NoCrashIcon style={{ fontSize: 18 }} /> }
      { status === VehicleStatus.Finished && <CheckCircleIcon style={{ fontSize: 18 }} /> }
      { status === VehicleStatus.Deleted && <div /> }
      {t(`enums.${status}`)}
    </HStack>
  );
}

interface Props {
  floating?: boolean,
  status: VehicleStatus,
}

const VehicleStatusChip = ({ floating = false, status }: Props) => (
  <Chip
    sx={{
      ...(floating ? { position: 'absolute', top: 10, right: 10 } : {}),
      ...(getColor(status) as any),
    }}
    label={getLabel(status) as ReactNode}
    variant="filled"
  />
);

export default VehicleStatusChip;
