import PageOffline from '../../../ui-kit/layout/PageOffline.tsx';
import RegisterForm from './RegisterForm.tsx';

const Register = () => (
  <PageOffline id="register">
    <RegisterForm />
  </PageOffline>
);

export default Register;
