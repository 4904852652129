import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';
import { IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import { useState } from 'react';
import { Model, SaveModelDto, useSaveModelMutation } from '../../../../../../graphql';

function createSaveModelDto(brandId: number, id: number|null = null, name = ''): SaveModelDto {
  return {
    id, name, brandId,
  };
}

interface Props {
  model?: Model,
  brandId: number,
  index?: number,
  isAdding: boolean,
  onSaved: () => void,
}

const AdminBrandInputModelName = ({
  brandId, index, isAdding, model, onSaved,
}: Props) => {
  const [ dto ] = useState(
    model ? createSaveModelDto(brandId, model.id, model.name) : createSaveModelDto(brandId),
  );
  const [ inputValue, setInputValue ] = useState('');
  const [ onFocusValueIndex, setOnFocusValueIndex ] = useState(-1);
  const [ saveModel ] = useSaveModelMutation();

  const handleAdd = async () => {
    if (inputValue === '') {
      return;
    }

    await saveModel({ variables: { dto: { ...dto, name: inputValue } } });

    setInputValue('');

    onSaved();
  };

  const handleEdit = async () => {
    await saveModel({ variables: { dto } });

    onSaved();
  };

  if (isAdding) {
    return (
      <OutlinedInput
        size="small"
        onChange={({ target: { value } }) => setInputValue(value)}
        value={inputValue}
        onKeyUp={(event) => {
          if (event.key === 'Enter') {
            handleAdd();
          }
        }}
        endAdornment={(
          <InputAdornment position="end">
            <IconButton
              onClick={handleAdd}
              edge="end"
            >
              <AddIcon />
            </IconButton>
          </InputAdornment>
        )}
      />
    );
  }

  return (
    <OutlinedInput
      size="small"
      onChange={({ target: { value } }) => setInputValue(value)}
      value={onFocusValueIndex === index ? inputValue : dto.name}
      onKeyUp={(event) => {
        if (event.key === 'Enter') {
          handleEdit();
        }
      }}
      onFocus={() => {
        setInputValue(dto.name);
        setOnFocusValueIndex(index!);
      }}
      onBlur={() => {
        setInputValue('');
        setOnFocusValueIndex(-1);
      }}
      endAdornment={(
        <InputAdornment position="end">
          {
            onFocusValueIndex === index && (
              <IconButton onClick={handleEdit} edge="end"><DoneIcon /></IconButton>
            )
          }
        </InputAdornment>
      )}
    />
  );
};

export default AdminBrandInputModelName;
