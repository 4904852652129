import { Button, Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseButton from '../../../../../ui-kit/buttons/CloseButton.tsx';
import DialogTitle from '../../../../../ui-kit/dialog/DialogTitle.tsx';
import HStack from '../../../../../ui-kit/layout/HStack.tsx';
import VStack from '../../../../../ui-kit/layout/VStack.tsx';

interface Props {
  onClose: () => void,
  onConfirm: () => void,
}

const ConfirmVehiclePublishingDialog = ({ onClose, onConfirm }: Props) => {
  const { t } = useTranslation();

  return (
    <Dialog
      onClose={onClose}
      open
      // @ts-ignore
      maxWidth="md"
    >
      <VStack p={5}>
        <HStack justifyContent="space-between" alignItems="center">
          <DialogTitle text={t('dialogs.titles.confirmation')} />
          <CloseButton onClose={onClose} />
        </HStack>

        {t('dialogs.publishing-confirmation-text')}

        <HStack justifyContent="flex-end" mt={2}>
          <Button size="small" onClick={onConfirm}>{t('buttons.publish')}</Button>
        </HStack>
      </VStack>
    </Dialog>
  );
};

export default ConfirmVehiclePublishingDialog;
