import { createBrowserRouter } from 'react-router-dom';
import AdministrationGuard from '../components/guards/AdministrationGuard.tsx';
import ClientGuard from '../components/guards/ClientGuard.tsx';
import LoggedInGuard from '../components/guards/LoggedInGuard.tsx';
import MaintenanceGuard from '../components/guards/MaintenanceGuard.tsx';
import PublicGuard from '../components/guards/PublicGuard.tsx';
import SellerGuard from '../components/guards/SellerGuard.tsx';
import UserDataValidGuard from '../components/guards/UserDataValidGuard.tsx';
import PageNotFound from '../components/PageNotFound.tsx';
import MaintenancePage from '../components/pages/MaintenancePage.tsx';
import Register from '../components/pages/offline/register/Register.tsx';
import ValidateAccount from '../components/pages/offline/validate-account/ValidateAccount.tsx';
import AdministrationDashboardPage from '../components/pages/online/admin/AdministrationDashboardPage.tsx';
import AdminAttributesPage from '../components/pages/online/admin/attributes/AdminAttributesPage.tsx';
import AdminBrandsPage from '../components/pages/online/admin/brands/AdminBrandsPage.tsx';
import AdminDamagesPage from '../components/pages/online/admin/damages/AdminDamagesPage.tsx';
import AdminEquipmentsPage from '../components/pages/online/admin/equipments/AdminEquipmentsPage.tsx';
import AdminUsers from '../components/pages/online/admin/users/AdminUsers.tsx';
import DealerVehicles from '../components/pages/online/dealers/[dealerId]/DealerVehicles.tsx';
import Marketplace from '../components/pages/online/marketplace/Marketplace.tsx';
import MyFavorites from '../components/pages/online/my-favorites/MyFavorites.tsx';
import MyHistory from '../components/pages/online/my-history/MyHistory.tsx';
import MyOffers from '../components/pages/online/my-offers/MyOffers.tsx';
import VehicleForm from '../components/pages/online/my-vehicles/form/VehicleForm.tsx';
import MyVehicles from '../components/pages/online/my-vehicles/list/MyVehicles.tsx';
import MyParameters from '../components/pages/online/parameters/MyParameters.tsx';
import Route from '../components/Route.tsx';

export default createBrowserRouter([
  {
    path: '/',
    element: (
      <Route>
        <MaintenanceGuard>
          <PublicGuard>
            <Marketplace />
          </PublicGuard>
        </MaintenanceGuard>
      </Route>
    ),
  },
  {
    path: '/login',
    element: (
      <Route>
        <MaintenanceGuard>
          <PublicGuard>
            <Marketplace />
          </PublicGuard>
        </MaintenanceGuard>
      </Route>
    ),
  },
  {
    path: '/maintenance',
    element: <Route><MaintenancePage /></Route>,
  },
  {
    path: '/register',
    element: <Route><Register /></Route>,
  },
  {
    path: '/validate-account',
    element: <Route><ValidateAccount /></Route>,
  },
  {
    path: '/validate-account/:userId',
    element: <Route><ValidateAccount /></Route>,
  },
  {
    path: '/marketplace',
    element: (
      <Route>
        <MaintenanceGuard>
          <PublicGuard>
            <Marketplace />
          </PublicGuard>
        </MaintenanceGuard>
      </Route>
    ),
  },
  {
    path: '/vehicle/:vehicleId',
    element: (
      <Route>
        <MaintenanceGuard>
          <PublicGuard>
            <Marketplace />
          </PublicGuard>
        </MaintenanceGuard>
      </Route>
    ),
  },
  {
    path: '/dealers/:dealerId',
    element: (
      <Route>
        <MaintenanceGuard>
          <LoggedInGuard>
            <UserDataValidGuard>
              <DealerVehicles />
            </UserDataValidGuard>
          </LoggedInGuard>
        </MaintenanceGuard>
      </Route>
    ),
  },
  {
    path: '/offers',
    element: (
      <Route>
        <MaintenanceGuard>
          <LoggedInGuard>
            <UserDataValidGuard>
              <ClientGuard>
                <MyOffers />
              </ClientGuard>
            </UserDataValidGuard>
          </LoggedInGuard>
        </MaintenanceGuard>
      </Route>
    ),
  },
  {
    path: '/favorites',
    element: (
      <Route>
        <MaintenanceGuard>
          <LoggedInGuard>
            <UserDataValidGuard>
              <ClientGuard>
                <MyFavorites />
              </ClientGuard>
            </UserDataValidGuard>
          </LoggedInGuard>
        </MaintenanceGuard>
      </Route>
    ),
  },
  {
    path: '/history',
    element: (
      <Route>
        <MaintenanceGuard>
          <LoggedInGuard>
            <UserDataValidGuard>
              <ClientGuard>
                <MyHistory />
              </ClientGuard>
            </UserDataValidGuard>
          </LoggedInGuard>
        </MaintenanceGuard>
      </Route>
    ),
  },
  {
    path: '/my-vehicles',
    element: (
      <Route>
        <MaintenanceGuard>
          <LoggedInGuard>
            <UserDataValidGuard>
              <SellerGuard>
                <MyVehicles />
              </SellerGuard>
            </UserDataValidGuard>
          </LoggedInGuard>
        </MaintenanceGuard>
      </Route>
    ),
  },
  {
    path: '/my-vehicles/add',
    element: (
      <Route>
        <MaintenanceGuard>
          <LoggedInGuard>
            <UserDataValidGuard>
              <SellerGuard>
                <VehicleForm />
              </SellerGuard>
            </UserDataValidGuard>
          </LoggedInGuard>
        </MaintenanceGuard>
      </Route>
    ),
  },
  {
    path: '/my-vehicles/:id/edit',
    element: (
      <Route>
        <MaintenanceGuard>
          <LoggedInGuard>
            <UserDataValidGuard>
              <SellerGuard>
                <VehicleForm />
              </SellerGuard>
            </UserDataValidGuard>
          </LoggedInGuard>
        </MaintenanceGuard>
      </Route>
    ),
  },
  {
    path: '/parameters',
    element: (
      <Route>
        <MaintenanceGuard>
          <LoggedInGuard>
            <MyParameters />
          </LoggedInGuard>
        </MaintenanceGuard>
      </Route>
    ),
  },
  {
    path: '/parameters/profile',
    element: (
      <Route>
        <MaintenanceGuard>
          <LoggedInGuard>
            <MyParameters defaultOpenedTab={0} />
          </LoggedInGuard>
        </MaintenanceGuard>
      </Route>
    ),
  },
  {
    path: '/admin/',
    element: (
      <Route>
        <AdministrationGuard>
          <AdministrationDashboardPage />
        </AdministrationGuard>
      </Route>
    ),
  },
  {
    path: '/admin/attributes',
    element: (
      <Route>
        <AdministrationGuard>
          <AdminAttributesPage />
        </AdministrationGuard>
      </Route>
    ),
  },
  {
    path: '/admin/brands',
    element: (
      <Route>
        <AdministrationGuard>
          <AdminBrandsPage />
        </AdministrationGuard>
      </Route>
    ),
  },
  {
    path: '/admin/equipments',
    element: (
      <AdministrationGuard>
        <AdminEquipmentsPage />
      </AdministrationGuard>
    ),
  },
  {
    path: '/admin/damages',
    element: (
      <AdministrationGuard>
        <AdminDamagesPage />
      </AdministrationGuard>
    ),
  },
  {
    path: '/admin/users',
    element: (
      <AdministrationGuard>
        <AdminUsers />
      </AdministrationGuard>
    ),
  },
  {
    path: '*',
    element: <PageNotFound />,
  },
]);
