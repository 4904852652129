import { useReactiveVar } from '@apollo/client';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import {
  Box, Tab, Tabs, Typography,
} from '@mui/material';
import { ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Damage,
  DamageLocation,
  damageLocationsVar,
  damagesVar,
  useGetAllDamageLocationsLazyQuery,
  useGetAllDamagesLazyQuery,
} from '../../../../../graphql';
import HStack from '../../../../ui-kit/layout/HStack.tsx';
import Page from '../../../../ui-kit/layout/Page.tsx';
import VStack from '../../../../ui-kit/layout/VStack.tsx';
import SaveDamageInput from './partials/SaveDamageInput.tsx';
import SaveDamageLocationInput from './partials/SaveDamageLocationInput.tsx';

interface TabPanelProps {
  children?: ReactNode,
  index: number,
  value: number,
}

const CustomTabPanel = (props: TabPanelProps) => {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const AdminDamagesPage = () => {
  const damages = useReactiveVar(damagesVar);
  const damageLocations = useReactiveVar(damageLocationsVar);
  const [ selectedTab, setSelectedTab ] = useState(0);
  const [ fetchAllDamages ] = useGetAllDamagesLazyQuery();
  const [ fetchAllDamageLocations ] = useGetAllDamageLocationsLazyQuery();

  const reloadDamages = async () => {
    const { data: damagesData } = await fetchAllDamages();
    const { data: damageLocationsData } = await fetchAllDamageLocations();

    damagesVar(damagesData?.allDamages || []);
    damageLocationsVar(damageLocationsData?.allDamageLocations || []);
  };

  return (
    <Page>
      <HStack alignItems="center">
        <KeyboardArrowLeftIcon />
        <Link to="/admin" style={{ color: 'initial', textDecoration: 'none' }}>Revenir au dashboard</Link>
      </HStack>

      <HStack alignItems="center" gap={2} mb={1}>
        <h1>Gestion des dégats</h1>
      </HStack>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={selectedTab} onChange={(_e, value) => setSelectedTab(value)} aria-label="basic tabs example">
          <Tab label="Dégats" {...a11yProps(0)} />
          <Tab label="Emplacements" {...a11yProps(1)} />
        </Tabs>
      </Box>

      <CustomTabPanel value={selectedTab} index={0}>
        <VStack gap={1} maxWidth={400}>
          <SaveDamageInput onSaved={reloadDamages} isAdding />
          {
            damages.map((damage, index) => (
              <SaveDamageInput
                damage={damage as Damage}
                isAdding={false}
                onSaved={reloadDamages}
                key={`save-damage-${index}`}
              />
            ))
          }
          {
            damages.length > 10 && (
              <SaveDamageInput onSaved={reloadDamages} isAdding />
            )
          }
        </VStack>
      </CustomTabPanel>
      <CustomTabPanel value={selectedTab} index={1}>
        <VStack gap={1} maxWidth={400}>
          <SaveDamageLocationInput onSaved={reloadDamages} isAdding />
          {
            damageLocations.map((damageLocation, index) => (
              <SaveDamageLocationInput
                damageLocation={damageLocation as DamageLocation}
                isAdding={false}
                onSaved={reloadDamages}
                key={`save-damage-location-${index}`}
              />
            ))
          }
          {
            damageLocations.length > 10 && (
              <SaveDamageLocationInput onSaved={reloadDamages} isAdding />
            )
          }
        </VStack>
      </CustomTabPanel>
    </Page>
  );
};

export default AdminDamagesPage;
