import { useReactiveVar } from '@apollo/client';
import InfoIcon from '@mui/icons-material/Info';
import SearchIcon from '@mui/icons-material/Search';
import { Alert, Button, TextField } from '@mui/material';
import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '../../../../../../config/theming.ts';
import { Equipment, equipmentsVar } from '../../../../../../graphql';
import useLang from '../../../../../../hooks/useLang.ts';
import HStack from '../../../../../ui-kit/layout/HStack.tsx';
import { SaveableVehicleContext } from '../SaveableVehicleContext.ts';
import VehicleFormEquipmentsList from './VehicleFormEquipmentsList.tsx';

const VehicleFormTabEquipments = () => {
  const { selectedEquipmentIds, setSelectedEquipmentIds } = useContext(SaveableVehicleContext);
  const { t } = useTranslation();
  const lang = useLang();
  const [ searchQuery, setSearchQuery ] = useState('');
  const equipments = useReactiveVar(equipmentsVar);
  const filteredEquipments = useMemo(() => equipments.filter(
    (equipment) => equipment.name[lang].toLowerCase().includes(searchQuery),
  ), [ searchQuery, equipments ]);
  const areAllShownEquipmentsSelected = useMemo(
    () => filteredEquipments.filter((equipment) => !selectedEquipmentIds.includes(equipment.id)).length > 0,
    [ filteredEquipments, selectedEquipmentIds ],
  );

  const handleSelectAllShownEquipments = () => {
    const filteredEquipmentIds = filteredEquipments.map((equipment) => equipment.id);
    const filteredSelectedEquipmentIds = selectedEquipmentIds.filter((id) => !filteredEquipmentIds.includes(id));

    setSelectedEquipmentIds([ ...filteredSelectedEquipmentIds, ...filteredEquipmentIds ]);
  };

  const handleUnselectAllShownEquipments = () => {
    const filteredEquipmentIds = filteredEquipments.map((equipment) => equipment.id);
    const filteredSelectedEquipmentIds = selectedEquipmentIds.filter((id) => !filteredEquipmentIds.includes(id));

    setSelectedEquipmentIds(filteredSelectedEquipmentIds);
  };

  return (
    <>
      <HStack mb={2}>
        <Alert color={'primary' as any} icon={<InfoIcon />} sx={{ maxWidth: 710, width: '100%' }}>{ t('my-vehicle.select-equipment-alert') }</Alert>
      </HStack>
      <HStack
        alignItems="center"
        style={{
          height: '50px',
          background: colors.primary.main,
          color: 'white',
          padding: '0 1rem',
          flex: '1',
          borderRadius: 5,
          maxWidth: 710,
          width: '100%',
        }}
        mb={2}
      >
        <SearchIcon />
        <TextField
          placeholder={t('my-vehicle.select-equipment-search')}
          style={{
            outline: 0, border: 0, flex: 1,
          }}
          inputProps={{
            style: {
              color: 'white',
            },
          }}
          onChange={({ target: { value } }) => setSearchQuery(value.toLowerCase())}
          autoComplete="off"
          className="search-bar"
        />
      </HStack>
      <HStack mb={2}>
        { areAllShownEquipmentsSelected && <Button variant="outlined" onClick={handleSelectAllShownEquipments}>{ t('my-vehicle.select-equipment-btn') }</Button> }
        { !areAllShownEquipmentsSelected && <Button variant="outlined" onClick={handleUnselectAllShownEquipments}>{ t('my-vehicle.unselect-equipment-btn') }</Button> }
      </HStack>
      <HStack flexWrap="wrap" gap={1} maxWidth={710}>
        <VehicleFormEquipmentsList equipments={filteredEquipments as Equipment[]} />
      </HStack>
    </>
  );
};

export default VehicleFormTabEquipments;
