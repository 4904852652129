import { Tooltip, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { colors } from '../../../../../config/theming.ts';
import {
  Attribute, AttributeType, Offer, Vehicle, VehicleStatus,
} from '../../../../../graphql';
import useReactiveUserConnected from '../../../../../hooks/data/useReactiveUserConnected.ts';
import useLang from '../../../../../hooks/useLang.ts';
import { VehicleActions, VehicleListTypes } from '../../../../../index';
import HStack from '../../../../ui-kit/layout/HStack.tsx';
import VStack from '../../../../ui-kit/layout/VStack.tsx';
import VehicleFavoriteChip from '../../chips/VehicleFavoriteChip.tsx';
import VehicleOffersCountChip from '../../chips/VehicleOffersCountChip.tsx';
import VehicleStatusChip from '../../chips/VehicleStatusChip.tsx';
import VehiclesListActionButtons from './VehiclesListActionButtons.tsx';

interface Props {
  attributesShownWithinList: Attribute[],
  listType: VehicleListTypes,
  offers: Offer[],
  vehicle: Vehicle,
  onClick: (action: VehicleActions, vehicle: Vehicle) => void,
  onSavedFavorite: (isFavorite: boolean) => void,
}

const VehiclesListItem = ({
  attributesShownWithinList, listType, offers, vehicle, onClick, onSavedFavorite,
}: Props) => {
  const lang = useLang();
  const navigate = useNavigate();
  const { isConnected } = useReactiveUserConnected();
  const { t } = useTranslation();

  return (
    <HStack
      className="vehicle-list-item"
      gap={5}
      sx={{
        width: '100%',
        borderBottom: 'thin solid #d7d7d7',
        paddingBottom: '1rem',
        marginBottom: '1rem',
        alignItems: 'flex-start',
      }}
    >
      <VStack position="relative">
        {
          vehicle.frontSideThumbnailMedia?.file?.uri && (
            <HStack
              className="background-position-center"
              width={280}
              height={160}
              style={{
                borderRadius: 10, overflow: 'visible', background: `url(${vehicle.frontSideThumbnailMedia?.file?.uri})`, cursor: 'pointer', backgroundSize: 'cover',
              }}
              onClick={() => onClick('openVehicleDetails', vehicle)}
            />
          )
        }

        {
          listType === 'my-vehicles' && (
            <>
              { vehicle.status !== VehicleStatus.Draft && <VehicleOffersCountChip count={vehicle.numberOfOffers} /> }
              <VehicleStatusChip status={vehicle.status} floating />
            </>
          )
        }
        { isConnected && <VehicleFavoriteChip vehicle={vehicle as Vehicle} onSavedFavorite={onSavedFavorite} /> }
        <VehiclesListActionButtons listType={listType} vehicle={vehicle} offers={offers} onClick={onClick} />
      </VStack>
      <VStack flex={1}>
        <HStack className="vehicle-data-h-stack" flex={1}>
          <VStack className="vehicle-info" width={250}>
            { isConnected && <Typography color={colors.red} fontSize={12} sx={{ cursor: 'pointer' }} onClick={() => navigate(`/dealers/${vehicle.dealer!.id}`)}>{vehicle.dealer!.name}</Typography> }
            <Typography fontWeight="bolder">
              {`${vehicle.brand!.name} ${vehicle.model!.name}`}
              { ' ' }
              <Typography variant="caption" fontWeight="bold" color="#989898" fontSize={14}>
                (ID
                {' '}
                { ' ' }
                {vehicle.id}
                )
              </Typography>
            </Typography>
            <HStack gap={1} alignItems="center">
              <img src={`/assets/images/icons/countries/${vehicle.addressToCollect!.country.toLowerCase()}.png`} width={15} height={15} alt="country flag" />
              <Typography color="#989898" fontSize={13}>{vehicle.addressToCollect!.city}</Typography>
            </HStack>
          </VStack>
          <HStack className="vehicle-attributes" flexWrap="wrap" flex={1} gap={2} justifyContent="space-around">
            <VStack className="vehicle-single-attribute">
              <Typography color="transparent" fontSize={12}>-</Typography>
              <Tooltip title={t('dictionary.availability-date')}>
                <div style={{ cursor: 'default' }}>
                  <HStack alignItems="center" gap={1}>
                    <img
                      src="/assets/images/icons/attributes/availableAt.png"
                      width={25}
                      height={25}
                    />
                    <Typography color="#989898" fontSize={14}>
                      { `${dayjs(vehicle.availableAt) > dayjs() ? dayjs(vehicle.availableAt).format('DD/MM/YYYY') : t('dictionary.available')}` }
                    </Typography>
                  </HStack>
                </div>
              </Tooltip>
            </VStack>

            {
              attributesShownWithinList.map((attribute) => {
                const vehicleAttribute = vehicle.attributes?.find((item) => item.attributeId === attribute.id)!;
                let value = vehicleAttribute && vehicleAttribute.value ? vehicleAttribute.value : 'Non défini';

                if (attribute.type === AttributeType.Select && vehicleAttribute && vehicleAttribute.attributeValueId) {
                  value = (attribute.values!.find((attributeValue) => attributeValue.id === vehicleAttribute.attributeValueId)!.value || {})?.[lang];
                }

                if (attribute.type === AttributeType.Date) {
                  value = value && dayjs(value).isValid() ? dayjs(value).format('YYYY') : 'Non définie';
                }

                return (
                  <VStack className="vehicle-single-attribute" key={attribute.id}>
                    <Typography color="transparent" fontSize={12}>-</Typography>
                    <Tooltip title={attribute.name[lang]}>
                      <div style={{ cursor: 'default' }}>
                        <HStack alignItems="center" gap={1}>
                          <img
                            src={`/assets/images/icons/attributes/${attribute.internalCode}.png`}
                            width={25}
                            height={25}
                          />
                          <Typography color="#989898" fontSize={14}>{value}</Typography>
                        </HStack>
                      </div>
                    </Tooltip>
                  </VStack>
                );
              })
            }

            {/* { */}
            {/*   vehicle.status === 'PUBLISHED' && ( */}
            {/*     <VStack className="vehicle-single-attribute"> */}
            {/*       <Typography color="transparent" fontSize={12}>-</Typography> */}
            {/*       <RemainingTimer vehicle={vehicle} /> */}
            {/*     </VStack> */}
            {/*   ) */}
            {/* } */}
          </HStack>
        </HStack>
      </VStack>
    </HStack>
  );
};

export default VehiclesListItem;
