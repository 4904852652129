import { ReactNode, useEffect, useState } from 'react';
import useReactiveUserConnected from '../../hooks/data/useReactiveUserConnected.ts';
import useValidateUserData from '../../hooks/data/useValidateUserData.ts';
import MyParameters from '../pages/online/parameters/MyParameters.tsx';
import PageLoader from '../ui-kit/utils/PageLoader.tsx';

interface Props {
  children: ReactNode,
}

const UserDataValidGuard = ({ children }: Props) => {
  const { isConnected, user } = useReactiveUserConnected();
  const validateUserData = useValidateUserData();
  const [ isValid, setIsValid ] = useState(-1);

  useEffect(() => {
    if (!user) {
      return () => {};
    }

    if (validateUserData(user)) {
      setIsValid(1);

      return () => {};
    }

    setIsValid(0);

    return () => {};
  }, [ isConnected, user ]);

  if (!isConnected || (user && !user.validatedAt) || isValid === -1) {
    return <PageLoader />;
  }

  if (isValid === 0) {
    return <MyParameters defaultOpenedTab={0} />;
  }

  return (
    <>
      { children }
    </>
  );
};

export default UserDataValidGuard;
