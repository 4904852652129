import useUiToast from './useUiToast.ts';

export default function useFileUpload() {
  const { error: showError } = useUiToast();

  return async (file: File, path = '', width: number|undefined = undefined, height: number|undefined = undefined) => {
    try {
      const formData = new FormData();
      const token = localStorage.getItem(`${import.meta.env.VITE_WEBAPP_LOCAL_STORAGE_KEYNAME}:bearer-token`);

      if (!token || token === '') {
        throw new Error('Token is missing');
      }

      formData.append('file', file);
      formData.append('path', path);

      if (width) {
        formData.append('width', String(width));
      }

      if (height) {
        formData.append('height', String(height));
      }

      const myHeaders = new Headers();

      myHeaders.append('Authorization', `Bearer ${token}`);

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formData,
      };

      const response = await fetch(`${import.meta.env.VITE_API_BASE_URL}/file/upload`, requestOptions);

      return response.json();
    } catch (error) {
      console.error(error);

      showError((error as Error).message);

      return false;
    }
  };
}
