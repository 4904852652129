import { User } from '../../graphql';

export default function useValidateUserData() {
  return (user: User) => user.lastName.length
      && user.firstName.length
      && user.email.length
      && user.phoneNumber.length
      && user.dealer
      && user.dealer.name.length
      && user.dealer.email.length
      && user.dealer.vatNumber.length
      && user.dealer.phoneNumber.length
      && user.dealer.iban.length
      && user.dealer.addresses
      && user.dealer.addresses.length >= 1;
}
