import { VehicleMediaTag } from '../graphql';

export const offlineRoutes = [
  '/',
  '/login',
  '/register',
  '/forgot-my-password',
];

export const uniqueVehicleMediaTags = [ VehicleMediaTag.FrontSide,
  VehicleMediaTag.BackSide,
  VehicleMediaTag.RightSide,
  VehicleMediaTag.LeftSide,
  VehicleMediaTag.RightFrontCorner,
  VehicleMediaTag.LeftFrontCorner,
  VehicleMediaTag.RightBackCorner,
  VehicleMediaTag.LeftBackCorner,
];

export const requiredVehicleMediaByTag = [ ...uniqueVehicleMediaTags ];

export const acceptedPhotoTypes = [ 'jpg', 'png', 'jpeg', 'webp' ];

export const acceptedVideoTypes = [ 'mp4', 'mov' ];
