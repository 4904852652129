import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Notification } from '../../../../graphql';
import { VehicleDetailsDialogContext } from '../../../providers/VehicleDetailsDialogContext.ts';
import HStack from '../../../ui-kit/layout/HStack.tsx';
import VStack from '../../../ui-kit/layout/VStack.tsx';
import NotificationBadge from './NotificationBadge.tsx';

interface Props {
  notification: Notification,
}

const DeclinedOfferNotification = ({ notification }: Props) => {
  const { t } = useTranslation();
  const { openDialogByVehicleId } = useContext(VehicleDetailsDialogContext);
  const { payload, createdAt } = notification;

  return (
    <HStack
      gap={1}
      py={2}
      px={1}
      sx={{ '&:hover': { backgroundColor: '#f1f1f18c' }, '*': { cursor: 'pointer' }, cursor: 'pointer' }}
      onClick={() => openDialogByVehicleId(payload.vehicleId)}
    >
      <img src={payload.pictureUri} alt={payload.dealerName} width={60} height={60} style={{ borderRadius: '100%' }} />
      <VStack flex={1}>
        <Typography variant="body2">
          <strong>{payload.dealerName}</strong>
          { ` ${t('notifications.declined-offer.your-offer-is-declined')} ` }
          <strong>
            {payload.amount}
            €
          </strong>
        </Typography>
        <Typography color="grey" fontSize={12} mt={0.5}>
          {dayjs(createdAt).format('DD MMM à HH:mm')}
        </Typography>
      </VStack>
      <HStack alignItems="center" justifyContent="center" width={25}>
        <NotificationBadge notification={notification} />
      </HStack>
    </HStack>
  );
};

export default DeclinedOfferNotification;
