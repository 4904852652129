import {
  Alert, Button, TextField, Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { colors } from '../../../../../config/theming.ts';
import {
  loggedIn, useMeLazyQuery, User, userConnected,
} from '../../../../../graphql';
import useAppInitialization from '../../../../../hooks/useAppInitialization.ts';
import useLogin from '../../../../../hooks/useLogin.tsx';
import useUiToast from '../../../../../hooks/useUiToast.ts';
import HStack from '../../../../ui-kit/layout/HStack.tsx';
import WheelLoader from '../../../../ui-kit/utils/WheelLoader.tsx';

interface Props {
  onLoggedIn: () => void,
  onForgotPasswordClick: () => void,
}

const LoginForm = ({ onLoggedIn, onForgotPasswordClick }: Props) => {
  const { t } = useTranslation();
  const [ dto, setDto ] = useState({ email: '', password: '' });
  const [ isFetchingData, setIsFetchingData ] = useState(false);
  const [ fetchCurrentUser ] = useMeLazyQuery();
  const {
    login, hasNoAccount, wrongPassword, isLogging,
  } = useLogin();
  const { initializeApplication, isAppInitialized } = useAppInitialization(true);
  const { error: errorToast } = useUiToast();
  const navigate = useNavigate();

  const handleConnect = async () => {
    await login(dto.email, dto.password);

    const { data: fetchedCurrentUser } = await fetchCurrentUser();

    if (
      fetchedCurrentUser
      && (!fetchedCurrentUser.getLoggedUser.latestSellerSubscription || fetchedCurrentUser.getLoggedUser.latestSellerSubscription.status !== 'ACTIVE')
      && (!fetchedCurrentUser.getLoggedUser.latestClientSubscription || fetchedCurrentUser.getLoggedUser.latestClientSubscription.status !== 'ACTIVE')
    ) {
      errorToast(t('errors.you-do-not-have-any-subscription'));
    }

    if (fetchedCurrentUser) {
      setIsFetchingData(true);

      userConnected(fetchedCurrentUser.getLoggedUser as User);

      await initializeApplication();

      loggedIn(true);

      onLoggedIn();
    }
  };

  useEffect(() => {
    if (isAppInitialized) {
      setIsFetchingData(false);
    }
  }, [ isAppInitialized ]);

  useEffect(() => {
    if (wrongPassword) {
      errorToast(t('toasts.wrong-password'));
    }
  }, [ wrongPassword ]);

  if (isFetchingData) {
    return (<HStack justifyContent="center"><WheelLoader /></HStack>);
  }

  return (
    <>
      <HStack justifyContent="center" mb={4}>
        <Typography variant="h2" fontWeight="bold">{t('buttons.login')}</Typography>
      </HStack>

      {
        hasNoAccount && (
          <Alert severity="error" sx={{ width: '100%', maxWidth: 600, mb: 5 }}>
            <strong>Oops.</strong>
            {' '}
            { t('offline.login.errors.no-account') }
          </Alert>
        )
      }

      <TextField
        label={t('form.labels.email')}
        variant="outlined"
        sx={{ mb: 2, width: '100%', maxWidth: 600 }}
        onChange={({ target: { value: email } }) => setDto({ ...dto, email: email.trim() })}
      />

      <TextField
        label={t('form.labels.password')}
        type="password"
        variant="outlined"
        sx={{ mb: 2, width: '100%', maxWidth: 600 }}
        onChange={({ target: { value: password } }) => setDto({ ...dto, password })}
      />

      <HStack width="100%" maxWidth={600} justifyContent="flex-end" mb={5}>
        <a
          href="#"
          style={{ color: colors.primary.main, textDecoration: 'none' }}
          title="mot de passe oublié"
          onClick={onForgotPasswordClick}
        >
          { t('offline.login.forgotten-password') }
        </a>
      </HStack>

      <HStack justifyContent="flex-end" gap={2}>
        <Button variant="contained" disabled={isLogging || isFetchingData} onClick={handleConnect}>{ t('buttons.login') }</Button>
        <Button variant="contained" color="secondary" onClick={() => navigate('/register')}>{ t('buttons.register') }</Button>
      </HStack>
    </>
  );
};

export default LoginForm;
