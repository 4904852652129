import { FormControl, TextField } from '@mui/material';
import { useState } from 'react';
import { SaveAttributeDto } from '../../../../../../../../graphql';

interface Props {
  dto: SaveAttributeDto,
  onChange: (dto: SaveAttributeDto) => void,
}

const SaveAttributeModalInputName = ({ dto, onChange }: Props) => {
  const [ name, setName ] = useState(dto.name.fr);

  return (
    <FormControl fullWidth sx={{ mb: 2 }}>
      <TextField
        defaultValue={name}
        size="small"
        label="Nom de l'attribut"
        onChange={({ target: { value } }) => setName(value)}
        onBlur={() => onChange({ ...dto, name: { fr: name } })}
      />
    </FormControl>
  );
};

export default SaveAttributeModalInputName;
