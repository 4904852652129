import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StarIcon from '@mui/icons-material/StarPurple500';
import {
  Accordion, AccordionDetails, AccordionSummary, Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAskToBecomeSellerMutation, UserSubscriptionStatus } from '../../../../../graphql';
import useReactiveUserConnected from '../../../../../hooks/data/useReactiveUserConnected.ts';
import useUiToast from '../../../../../hooks/useUiToast.ts';
import HStack from '../../../../ui-kit/layout/HStack.tsx';

interface Props {
  onLoading: (pushing: boolean) => void,
}

const MyProfileBecomeSeller = ({ onLoading }: Props) => {
  const { t } = useTranslation();
  const { sellerSubscription, refetchMe } = useReactiveUserConnected();
  const [ askToBecomeSeller ] = useAskToBecomeSellerMutation();
  const { error: showError } = useUiToast();

  const handleClick = async () => {
    onLoading?.(true);

    try {
      const { data } = await askToBecomeSeller();

      if (data) {
        await refetchMe();
      }
    } catch (error) {
      showError((error as Error).message);
    } finally {
      onLoading?.(false);
    }
  };

  return (
    <>
      <Accordion sx={{ maxWidth: 800 }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <HStack gap={1} alignItems="center">
            <StarIcon sx={{ color: 'gold' }} />
            {t('my-parameters.my-profile.become-seller-title')}
          </HStack>
        </AccordionSummary>
        <AccordionDetails>
          {
            (!sellerSubscription || sellerSubscription.status === UserSubscriptionStatus.Disabled) && (
            <>
              {/* eslint-disable-next-line react/no-danger */}
              <div dangerouslySetInnerHTML={{ __html: t('my-parameters.my-profile.become-seller-text') }} />
              <HStack justifyContent="flex-end">
                <Button color="secondary" variant="outlined" onClick={handleClick}>{t('my-parameters.my-profile.become-seller-button')}</Button>
              </HStack>
            </>
            )
          }

          {
            // eslint-disable-next-line react/no-danger
            sellerSubscription && sellerSubscription.status === UserSubscriptionStatus.Waiting && <div dangerouslySetInnerHTML={{ __html: t('my-parameters.my-profile.your-pending-request') }} />
          }
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default MyProfileBecomeSeller;
