import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
  onClose: () => void,
}

const CloseButton = ({ onClose }: Props) => {
  const { t } = useTranslation();

  return (
    <Button size="small" onClick={onClose}>
      <CloseIcon fontSize="small" sx={{ top: -1, position: 'relative' }} />
      {' '}
      { t('buttons.close') }
    </Button>
  );
};

export default CloseButton;
