import { MenuItem, TextField } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import useReactiveUserConnected from '../../../../../../hooks/data/useReactiveUserConnected.ts';
import HStack from '../../../../../ui-kit/layout/HStack.tsx';
import VStack from '../../../../../ui-kit/layout/VStack.tsx';
import { SaveableVehicleContext } from '../SaveableVehicleContext.ts';

const VehicleFormAddressToCollectSelect = () => {
  const { dealer } = useReactiveUserConnected();
  const { t } = useTranslation();
  const { selectedAddressId, setSelectedAddressId } = useContext(SaveableVehicleContext);

  return (
    <VStack mb={2}>
      <HStack>
        {t('my-vehicle.address-to-collect')}
        <span style={{ color: 'red' }}>*</span>
      </HStack>
      <TextField
        select
        variant="outlined"
        size="small"
        sx={{ maxWidth: 320 }}
        value={selectedAddressId ?? ''}
        onChange={({ target: { value } }) => setSelectedAddressId(Number(value))}
      >
        {
          dealer!.addresses!.map((address) => (
            <MenuItem value={address.id} key={address.id}>{ `${address.street} ${address.number}, ${address.city} ${address.zip}, ${address.country}` }</MenuItem>
          ))
        }
      </TextField>
    </VStack>
  );
};

export default VehicleFormAddressToCollectSelect;
