import './i18n';
import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as Sentry from '@sentry/react';
import App from './components/App.tsx';

Sentry.init({
  enabled: import.meta.env.VITE_SENTRY === 'true',
  dsn: 'https://181689459893d582bb475f1080b9d5ef@o4506779102740480.ingest.sentry.io/4506779104182272',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [ 'localhost', /^https:\/\/yourserver\.io\/api/ ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
