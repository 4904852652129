import { Visibility, VisibilityOff } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyIcon from '@mui/icons-material/Key';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChangeMyPasswordDto, useChangeMyPasswordMutation } from '../../../../../graphql';
import { generatePassword } from '../../../../../helpers/password-generator.ts';
import useUiToast from '../../../../../hooks/useUiToast.ts';
import HStack from '../../../../ui-kit/layout/HStack.tsx';
import VStack from '../../../../ui-kit/layout/VStack.tsx';

const ChangePasswordTab = () => {
  const { t } = useTranslation();
  const [ dto, setDto ] = useState<ChangeMyPasswordDto>({ newPassword: '', oldPassword: '' });
  const [ showOldPassword, setShowOldPassword ] = useState(false);
  const [ showNewPassword, setShowNewPassword ] = useState(false);
  const canSave = useMemo(() => dto.newPassword.length && dto.oldPassword.length, [ dto ]);
  const [ changeMyPassword ] = useChangeMyPasswordMutation();
  const { success } = useUiToast();

  const handleClickShowNewPassword = () => setShowNewPassword(!showNewPassword);
  const handleClickShowOldPassword = () => setShowOldPassword(!showOldPassword);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleSave = async () => {
    if (!canSave) {
      return;
    }

    await changeMyPassword({ variables: { dto } });

    success(t('toasts.new-password-saved'));

    setDto({ oldPassword: '', newPassword: '' });
  };

  const handleGeneratePassword = () => {
    setDto({ ...dto, newPassword: generatePassword(12) });
  };

  return (
    <Accordion expanded sx={{ maxWidth: 800 }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <HStack gap={1} alignItems="center">
          <KeyIcon />
          {t('my-parameters.change-password.title')}
        </HStack>
      </AccordionSummary>
      <AccordionDetails>
        <VStack flex={1}>
          <Typography fontWeight="bolder">
            {t('my-parameters.change-password.old-password')}
            <span style={{ color: 'red' }}>*</span>
          </Typography>

          <OutlinedInput
            id="old-password"
            name="old-password"
            autoComplete="false"
            size="small"
            value={dto.oldPassword}
            type={showOldPassword ? 'text' : 'password'}
            onChange={({ target: { value: oldPassword } }) => setDto({ ...dto, oldPassword })}
            endAdornment={(
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowOldPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showOldPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )}
          />
        </VStack>

        <VStack flex={1} mt={2}>
          <Typography fontWeight="bolder">
            {t('my-parameters.change-password.new-password')}
            <span style={{ color: 'red' }}>*</span>
          </Typography>

          <OutlinedInput
            id="new-password"
            name="new-password"
            autoComplete="false"
            size="small"
            value={dto.newPassword}
            type={showNewPassword ? 'text' : 'password'}
            onChange={({ target: { value: newPassword } }) => setDto({ ...dto, newPassword })}
            endAdornment={(
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowNewPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showNewPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )}
          />

          <HStack justifyContent="flex-end" mt={1}>
            <Button onClick={handleGeneratePassword} size="small">{t('buttons.generate-password')}</Button>
          </HStack>
        </VStack>

        <HStack justifyContent="flex-end" mt={2}>
          <Button variant="outlined" onClick={handleSave} disabled={!canSave}>{t('buttons.save')}</Button>
        </HStack>
      </AccordionDetails>
    </Accordion>
  );
};

export default ChangePasswordTab;
