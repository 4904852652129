import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DrawIcon from '@mui/icons-material/Draw';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import { useRef } from 'react';
import { colors } from '../../../../../../config/theming.ts';
import { VehicleMediaTag, VehicleMediaType } from '../../../../../../graphql';
import { inArray } from '../../../../../../helpers/array.ts';
import { DroppedVehicleMediaFile, StateVehicleMediaFile } from '../../../../../../index';
import HStack from '../../../../../ui-kit/layout/HStack.tsx';
import VStack from '../../../../../ui-kit/layout/VStack.tsx';
import './VehicleFormPhotoDropzone.scss';

interface Props {
  acceptedTypes: string[],
  mediaFile?: StateVehicleMediaFile,
  mediaType?: VehicleMediaType,
  label?: string,
  index?: null|number,
  tag?: VehicleMediaTag,
  onDropFile?: (droppedFile: DroppedVehicleMediaFile) => void,
  onDelete?: (index: number) => void,
}

const VehicleFormMediaDropdzone = ({
  acceptedTypes, mediaFile, mediaType = VehicleMediaType.Image, label, tag = VehicleMediaTag.None, index = null, onDropFile, onDelete,
}: Props) => {
  const inputRef = useRef<any>();

  const handleDrop = (event: any) => {
    if (
      event.target.files
      && event.target.files.length
      && inArray(event.target.files[0].name.split('.').pop().toLowerCase(), acceptedTypes)
      && event.target.files[0].size <= 8 * 1024 * 1024
    ) {
      onDropFile?.({
        tag,
        index,
        type: mediaType,
        file: event.target.files[0],
        uri: null,
        damageId: mediaFile?.damageId || null,
        equipmentId: mediaFile?.equipmentId || null,
      });
      inputRef.current.value = null;
    }
  };

  return (
    <VStack
      className="my-vehicle-photo-dropzone"
      style={{
        width: 150,
        height: 150,
        border: `2px dashed ${colors.primary.main}`,
        cursor: !mediaFile ? 'pointer' : 'default',
        position: 'relative',
        overflow: 'hidden',
      }}
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      onClick={() => {
        if (!mediaFile) {
          inputRef.current.click();
        }
      }}
    >
      {
        !mediaFile && (
          <img src={`/assets/images/cars-figures/${tag}.svg`} />
        )
      }

      <input
        type="file"
        style={{
          width: 0,
          height: 0,
        }}
        ref={inputRef}
        onChange={handleDrop}
      />

      {
        mediaType === VehicleMediaType.Image && mediaFile && mediaFile.uri && (
          <>
            <img src={mediaFile.uri} style={{ maxWidth: '100%' }} alt={label} />
            <HStack
              alignItems="center"
              gap={1}
              sx={{
                position: 'absolute', top: -2, right: -2, bgcolor: 'primary.main', color: 'white', padding: 0.8, borderBottomLeftRadius: 10,
              }}
            >
              <DrawIcon
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  if (mediaFile) {
                    onDropFile?.({
                      ...mediaFile,
                      index,
                      uri: null,
                    });
                  }
                }}
              />
              <DeleteForeverIcon
                sx={{ cursor: 'pointer' }}
                onClick={(event) => {
                  event.stopPropagation();
                  onDelete?.(index!);
                }}
              />
            </HStack>
          </>
        )
      }

      {
        mediaType === VehicleMediaType.Video && mediaFile && mediaFile.uri && (
          <>
            <video src={mediaFile.uri} style={{ maxWidth: '100%' }} controls />
            <HStack
              alignItems="center"
              gap={1}
              sx={{
                position: 'absolute', top: -2, right: -2, bgcolor: 'primary.main', color: 'white', padding: 0.8, borderBottomLeftRadius: 10,
              }}
            >
              <OndemandVideoIcon
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  if (mediaFile) {
                    onDropFile?.({
                      ...mediaFile,
                      index,
                      uri: null,
                    });
                  }
                }}
              />
              <DeleteForeverIcon
                sx={{ cursor: 'pointer' }}
                onClick={(event) => {
                  event.stopPropagation();
                  onDelete?.(index!);
                }}
              />
            </HStack>
          </>
        )
      }

      {
        !mediaFile && (
          <VStack position="absolute" alignItems="center" bgcolor="#ffffffb5" width="100%" height="100%" justifyContent="center">
            <AddCircleOutlineIcon />
            {label}
          </VStack>
        )
      }
    </VStack>
  );
};

export default VehicleFormMediaDropdzone;
