import { useReactiveVar } from '@apollo/client';
import InfoIcon from '@mui/icons-material/Info';
import SearchIcon from '@mui/icons-material/Search';
import { Alert, Button, TextField } from '@mui/material';
import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '../../../../../../config/theming.ts';
import { Damage, damagesVar } from '../../../../../../graphql';
import useLang from '../../../../../../hooks/useLang.ts';
import HStack from '../../../../../ui-kit/layout/HStack.tsx';
import { SaveableVehicleContext } from '../SaveableVehicleContext.ts';
import VehicleFormDamagesList from './VehicleFormDamagesList.tsx';

const VehicleFormTabDamages = () => {
  const { selectedDamageIds, setSelectedDamageIds } = useContext(SaveableVehicleContext);
  const { t } = useTranslation();
  const lang = useLang();
  const [ searchQuery, setSearchQuery ] = useState('');
  const damages = useReactiveVar(damagesVar);
  const filteredDamages = useMemo(() => damages.filter(
    (damage) => damage.name[lang].toLowerCase().includes(searchQuery),
  ), [ searchQuery, damages ]);
  const areAllShownDamagesSelected = useMemo(
    () => filteredDamages.filter((equipment) => !selectedDamageIds.includes(equipment.id)).length > 0,
    [ filteredDamages, selectedDamageIds ],
  );

  const handleSelectAllShownDamages = () => {
    const filteredDamageIds = filteredDamages.map((damage) => damage.id);
    const filteredSelectedDamageIds = selectedDamageIds.filter((id) => !filteredDamageIds.includes(id));

    setSelectedDamageIds([ ...filteredSelectedDamageIds, ...filteredDamageIds ]);
  };

  const handleUnselectAllShownDamages = () => {
    const filteredDamageIds = filteredDamages.map((equipment) => equipment.id);
    const filteredSelectedDamageIds = selectedDamageIds.filter((id) => !filteredDamageIds.includes(id));

    setSelectedDamageIds(filteredSelectedDamageIds);
  };

  return (
    <>
      <HStack mb={2}>
        <Alert color={'primary' as any} icon={<InfoIcon />} sx={{ maxWidth: 710, width: '100%' }}>{ t('my-vehicle.select-damage-alert') }</Alert>
      </HStack>
      <HStack
        alignItems="center"
        style={{
          height: '50px',
          background: colors.primary.main,
          color: 'white',
          padding: '0 1rem',
          flex: '1',
          borderRadius: 5,
          maxWidth: 710,
          width: '100%',
        }}
        mb={2}
      >
        <SearchIcon />
        <TextField
          placeholder={t('my-vehicle.select-damage-search')}
          style={{
            outline: 0, border: 0, flex: 1,
          }}
          inputProps={{
            style: {
              color: 'white',
            },
          }}
          onChange={({ target: { value } }) => setSearchQuery(value.toLowerCase())}
          autoComplete="off"
          className="search-bar"
        />
      </HStack>
      <HStack mb={2}>
        { areAllShownDamagesSelected && <Button variant="outlined" onClick={handleSelectAllShownDamages}>{ t('my-vehicle.select-damage-btn') }</Button> }
        { !areAllShownDamagesSelected && <Button variant="outlined" onClick={handleUnselectAllShownDamages}>{ t('my-vehicle.unselect-damage-btn') }</Button> }
      </HStack>
      <HStack flexWrap="wrap" gap={1} maxWidth={710}>
        <VehicleFormDamagesList damages={filteredDamages as Damage[]} />
      </HStack>
    </>
  );
};

export default VehicleFormTabDamages;
