import LockIcon from '@mui/icons-material/Lock';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { Box } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import HStack from '../../../ui-kit/layout/HStack.tsx';
import Page from '../../../ui-kit/layout/Page.tsx';
import PageTitle from '../../../ui-kit/layout/PageTitle.tsx';
import UiTabPanels from '../../../ui-kit/navigation/tabs/UiTabPanels.tsx';
import UiTabs from '../../../ui-kit/navigation/tabs/UiTabs.tsx';
import ChangePasswordTab from './tab-change-password/ChangePasswordTab.tsx';
import MyProfile from './tab-profile/MyProfile.tsx';

interface Props {
  defaultOpenedTab?: number,
}

const MyParameters = ({ defaultOpenedTab = 0 }: Props) => {
  const { t } = useTranslation();
  const [ tabIndex, setTabIndex ] = useState(defaultOpenedTab);
  const [ isLoading, setIsLoading ] = useState(false);

  return (
    <Page id="parameters">
      <PageTitle
        text={(
          <HStack gap={0.5}>
            {t('my-parameters.title')}
          </HStack>
        )}
        loading={isLoading}
      />
      <Box sx={{
        borderBottom: 1,
        borderColor: 'divider',
      }}
      >
        <UiTabs
          onChangeTab={setTabIndex}
          defaultOpenedTab={defaultOpenedTab}
          tabs={[
            (
              <HStack alignItems="center" gap={1}>
                <AccountBoxIcon />
                { t('my-parameters.my-profile.title') }
              </HStack>
            ),
            (
              <HStack alignItems="center" gap={1}>
                <LockIcon />
                { t('my-parameters.security.title') }
              </HStack>
            ),
          ]}
        />
      </Box>

      <UiTabPanels
        value={tabIndex}
        panels={[
          <MyProfile onLoading={setIsLoading} />,
          <ChangePasswordTab />,
        ]}
      />
    </Page>
  );
};

export default MyParameters;
