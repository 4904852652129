import {
  MenuItem, Switch, TextField, Typography,
} from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Attribute, AttributeType } from '../../../../../../graphql';
import { inArray } from '../../../../../../helpers/array.ts';
import useLang from '../../../../../../hooks/useLang.ts';
import HStack from '../../../../../ui-kit/layout/HStack.tsx';
import VStack from '../../../../../ui-kit/layout/VStack.tsx';
import { SaveableVehicleContext } from '../SaveableVehicleContext.ts';

interface Props {
  attribute: Attribute,
}

const VehicleFormAttributeInput = ({ attribute }: Props) => {
  const lang = useLang();
  const { t } = useTranslation();
  const { filledAttributes, setFilledAttributes } = useContext(SaveableVehicleContext);

  const handleChangeValue = (attributeId: number, value: unknown) => setFilledAttributes({
    ...filledAttributes, [attributeId]: attribute.type === 'NUMBER' ? Number(value) : value,
  });

  return (
    <VStack>
      <HStack>
        { attribute.name[lang] }
        {' '}
        { attribute.mandatory && <span style={{ color: 'red' }}>*</span> }
      </HStack>
      {
        inArray(attribute.type, [ AttributeType.Text, AttributeType.Number, AttributeType.Date, AttributeType.Select ]) && (
          <TextField
            type={String(attribute.type).toLowerCase()}
            select={attribute.type === AttributeType.Select}
            variant="outlined"
            size="small"
            sx={{ mb: 2 }}
            value={filledAttributes[attribute.id] ?? ''}
            onChange={({ target: { value } }) => handleChangeValue(attribute.id, value)}
            placeholder={attribute.config?.form.placeholder[lang] ?? ''}
          >
            {
              attribute.values?.map((attributeValue) => (
                <MenuItem key={attributeValue.value[lang]} value={attributeValue.id}>{ attributeValue.value[lang] }</MenuItem>
              ))
            }
          </TextField>
        )
      }
      {
        attribute.type === AttributeType.Boolean && (
          <HStack gap={1} alignItems="center">
            <Typography>{t('form.labels.no')}</Typography>
            <Switch
              checked={attribute.id in filledAttributes ? filledAttributes[attribute.id] as boolean : false}
              onChange={(_event, checked) => handleChangeValue(attribute.id, checked)}
            />
            <Typography>{t('form.labels.yes')}</Typography>
          </HStack>
        )
      }
    </VStack>
  );
};

export default VehicleFormAttributeInput;
