import { useReactiveVar } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import {
  Autocomplete, Button, TextField, Tooltip,
} from '@mui/material';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Brand, brandsVar, Model, SaveBrandDto, useGetAllBrandsLazyQuery,
} from '../../../../../graphql';
import HStack from '../../../../ui-kit/layout/HStack.tsx';
import Page from '../../../../ui-kit/layout/Page.tsx';
import VStack from '../../../../ui-kit/layout/VStack.tsx';
import AdminBrandInputModelName from './partials/AdminBrandInputModelName.tsx';
import SaveBrandModal from './partials/modals/save-brand/SaveBrandModal.tsx';

function createSaveBrandDto(): SaveBrandDto {
  return {
    id: null, name: '', models: [],
  };
}

const AdminBrandsPage = () => {
  const brands = useReactiveVar(brandsVar);
  const [ fetchAllBrands ] = useGetAllBrandsLazyQuery();
  const [ isModalOpen, setIsModalOpen ] = useState(false);
  const [ saveableBrand, setSaveableBrand ] = useState(createSaveBrandDto());
  const sortedBrands = useMemo(() => (brands.length
    ? brands.slice().sort((a, b) => a.name.localeCompare(b.name))
    : []), [ brands ]);
  const [ selectedBrand, setSelectedBrand ] = useState<Brand|null>(null);
  const sortedModelsOfSelectedBrand = useMemo(() => (selectedBrand && selectedBrand.models!.length ? selectedBrand.models!.slice().sort((a, b) => a.name.localeCompare(b.name)) : []), [ selectedBrand ]);

  const refetchBrands = async () => {
    const { data } = await fetchAllBrands();

    brandsVar(data?.allBrands);

    if (selectedBrand && data) {
      setSelectedBrand(data.allBrands.find((brand) => brand.id! === selectedBrand.id!) as Brand);
    }
  };

  const handleClose = async () => {
    setIsModalOpen(false);
    setSaveableBrand(createSaveBrandDto);
    await refetchBrands();
  };

  return (
    <Page>
      <HStack alignItems="center">
        <KeyboardArrowLeftIcon />
        <Link to="/admin" style={{ color: 'initial', textDecoration: 'none' }}>Revenir au dashboard</Link>
      </HStack>
      <HStack alignItems="center" gap={2}>
        <h1>Gestion des marques</h1>

        <Tooltip title="Ajouter une marque">
          <Button
            variant="outlined"
            size="small"
            color="primary"
            sx={{ width: 30, minWidth: 'unset', height: 30 }}
            onClick={() => setIsModalOpen(true)}
          >
            <AddIcon />
          </Button>
        </Tooltip>
      </HStack>

      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={sortedBrands.map((brand) => ({ label: brand.name, brand }))}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Sélectionner une marque" />}
        onChange={(_event, value) => setSelectedBrand(value ? value.brand as Brand : null)}
      />

      {
        selectedBrand && (
          <VStack gap={1} mt={2} maxWidth={400}>
            <AdminBrandInputModelName
              brandId={selectedBrand.id}
              isAdding
              onSaved={refetchBrands}
            />

            {
              sortedModelsOfSelectedBrand.map((model, index) => (
                <AdminBrandInputModelName
                  brandId={selectedBrand.id}
                  index={index}
                  model={model as Model}
                  isAdding={false}
                  onSaved={refetchBrands}
                  key={`brand${selectedBrand.id}-model${model.id}`}
                />
              ))
            }
          </VStack>
        )
      }

      {
        isModalOpen && (
          <SaveBrandModal defaultDto={saveableBrand} onClose={handleClose} />
        )
      }
    </Page>
  );
};

export default AdminBrandsPage;
