import { useState } from 'react';

export default function useVideoLoader(stateEnabled: boolean = true) {
  const [ videoHtmlElement, setVideoHtmlElement ] = useState<HTMLVideoElement|null>(null);
  const [ videoFile, setVideoFile ] = useState<File|null>(null);

  function loadVideoFromFile(file: File) {
    const fileReader = new FileReader();

    fileReader.onload = function (event) {
      const htmlVideoElement = document.createElement('video');

      htmlVideoElement.oncanplaythrough = (videoEvent: any) => {
        if (stateEnabled) {
          setVideoHtmlElement(videoEvent.target as HTMLVideoElement);
          setVideoFile(file);
        }
      };

      htmlVideoElement.src = event.target!.result as string;
    };

    fileReader.readAsDataURL(file);
  }

  async function loadVideoFromUri(fileUri: string): Promise<{ element: HTMLVideoElement, file: File, }> {
    const htmlVideoElement = document.createElement('video');
    const blob = await (await fetch(fileUri, {})).blob();

    return new Promise((resolve) => {
      htmlVideoElement.oncanplaythrough = async (videoEvent: any) => {
        const element = videoEvent.target as HTMLVideoElement;
        const file = new File([ blob ], fileUri);

        if (stateEnabled) {
          setVideoHtmlElement(videoEvent.target as HTMLVideoElement);
          setVideoFile(new File([ blob ], fileUri));
        }

        resolve({ element, file });
      };

      htmlVideoElement.src = fileUri;
    });
  }

  return {
    videoHtmlElement,
    videoFile,
    loadVideoFromFile,
    loadVideoFromUri,
  };
}
