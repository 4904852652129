import { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import VStack from '../layout/VStack.tsx';
import '../../../styles/utils/WheelLoader.scss';

interface Props {
  bgColor?: string,
  width?: number,
  height?: number,
  showText?: boolean,
  style?: CSSProperties,
}

const WheelLoader = ({
  bgColor = 'transparent', width = 250, height = 250, showText = true, style = {},
}: Props) => {
  const { t } = useTranslation();

  return (
    <VStack alignItems="center" justifyContent="center" bgcolor={bgColor} width={width} height={height} borderRadius={width} style={style}>
      <svg height="150" viewBox="0 0 128 128" width="150" xmlns="http://www.w3.org/2000/svg" className="loader-rotating">
        <g>
          <path
            d="m64 33.981a30.036 30.036 0 0 0 -7.618.978l3.94 5.583-1.971 14.579h11.3l-1.973-14.579 3.94-5.583a30.036 30.036 0 0 0 -7.618-.978z"
            fill="#c5d3dd"
          />
          <path
            d="m64 6.307a57.693 57.693 0 1 0 57.693 57.693 57.693 57.693 0 0 0 -57.693-57.693zm0 95.093a37.4 37.4 0 1 1 37.4-37.4 37.4 37.4 0 0 1 -37.4 37.4z"
            fill="#6e6e6e"
          />
          <g fill="#c5d3dd">
            <path
              d="m35.45 54.724a30.005 30.005 0 0 0 -1.424 7.547l6.527-2.022 13.257 6.38 3.49-10.745-14.474-2.631-4.092-5.473a30.105 30.105 0 0 0 -3.284 6.944z"
            />
            <path
              d="m46.355 88.286a30.127 30.127 0 0 0 6.738 3.687l.094-6.833 10.164-10.64-9.14-6.641-6.975 12.957-6.469 2.2a30.034 30.034 0 0 0 5.588 5.27z"
            />
            <path
              d="m81.645 88.286a30.034 30.034 0 0 0 5.588-5.269l-6.469-2.2-6.975-12.954-9.14 6.637 10.163 10.64.095 6.833a30.127 30.127 0 0 0 6.738-3.687z"
            />
            <path
              d="m92.55 54.724a30.105 30.105 0 0 0 -3.284-6.944l-4.093 5.473-14.473 2.631 3.49 10.745 13.257-6.38 6.527 2.022a30.1 30.1 0 0 0 -1.424-7.547z"
            />
          </g>
          <circle cx="64" cy="64" fill="#d9e7ec" r="10.525" transform="matrix(.3 -.954 .954 .3 -16.259 105.839)" />
          <path
            d="m64 26.6a37.4 37.4 0 1 0 37.4 37.4 37.4 37.4 0 0 0 -37.4-37.4zm0 67.419a30.019 30.019 0 1 1 30.019-30.019 30.019 30.019 0 0 1 -30.019 30.019z"
            fill="#d9e7ec"
          />
          <g>
            <path
              d="m64 4.557a59.37 59.37 0 0 0 -52.532 31.6 1.75 1.75 0 0 0 3.091 1.643 56.221 56.221 0 0 1 9.289-12.765l8.116.867a1.7 1.7 0 0 0 .188.01 1.75 1.75 0 0 0 .184-3.49l-5.157-.551a55.837 55.837 0 0 1 36-13.8l5.112 6.336a1.75 1.75 0 0 0 2.725-2.2l-3.24-4.007a55.751 55.751 0 0 1 35.184 15.708l-.861 8.056a1.749 1.749 0 0 0 1.554 1.926 1.692 1.692 0 0 0 .188.01 1.75 1.75 0 0 0 1.738-1.564l.544-5.089a55.687 55.687 0 0 1 13.8 35.934l-6.328 5.106a1.75 1.75 0 1 0 2.2 2.724l4.011-3.237a55.755 55.755 0 0 1 -15.71 35.186l-8.056-.861a1.75 1.75 0 1 0 -.371 3.48l5.088.544a55.686 55.686 0 0 1 -35.933 13.8l-5.107-6.329a1.75 1.75 0 1 0 -2.724 2.2l3.236 4.01a55.752 55.752 0 0 1 -35.189-15.712l.861-8.056a1.75 1.75 0 0 0 -3.48-.372l-.544 5.088a55.684 55.684 0 0 1 -13.799-35.932l6.329-5.107a1.75 1.75 0 0 0 -2.2-2.724l-4.015 3.24a55.605 55.605 0 0 1 3.095-15.009 1.75 1.75 0 1 0 -3.3-1.175 59.453 59.453 0 1 0 56.013-39.488z"
            />
            <path
              d="m100.525 72.267a1.745 1.745 0 0 0 -2.152 1.222 35.6 35.6 0 0 1 -1.324 3.911 1.749 1.749 0 0 0 .963 2.279 1.731 1.731 0 0 0 .657.129 1.749 1.749 0 0 0 1.622-1.092 38.92 38.92 0 0 0 1.457-4.293 1.749 1.749 0 0 0 -1.223-2.156z"
            />
            <path
              d="m103.149 64a39.141 39.141 0 1 0 -7.441 22.968 1.75 1.75 0 1 0 -2.832-2.056 35.655 35.655 0 1 1 6.773-20.912 1.75 1.75 0 0 0 3.5 0z"
            />
            <path
              d="m64 32.231a31.769 31.769 0 1 0 31.769 31.769 31.8 31.8 0 0 0 -31.769-31.769zm23.107 15.514-2.922 3.909-12.921 2.346-1.759-13.011 2.825-4a28.373 28.373 0 0 1 14.777 10.756zm4.851 12.069-3.993-1.236a1.753 1.753 0 0 0 -1.277.094l-10.428 5.019a12.2 12.2 0 0 0 -2.16-6.647l11.384-2.07a1.749 1.749 0 0 0 1.088-.674l2.5-3.337a28.04 28.04 0 0 1 2.886 8.851zm-27.958 12.961a8.775 8.775 0 1 1 8.775-8.775 8.784 8.784 0 0 1 -8.775 8.775zm-25.071-21.812 2.5 3.337a1.752 1.752 0 0 0 1.089.674l11.382 2.069a12.2 12.2 0 0 0 -2.157 6.648l-10.431-5.019a1.746 1.746 0 0 0 -1.276-.094l-3.993 1.236a28.039 28.039 0 0 1 2.886-8.851zm29.727-14.842-2.407 3.411a1.758 1.758 0 0 0 -.3 1.244l1.55 11.462a12.152 12.152 0 0 0 -6.987 0l1.549-11.462a1.753 1.753 0 0 0 -.3-1.244l-2.407-3.411a27.988 27.988 0 0 1 9.312 0zm-12.986.864 2.826 4-1.76 13.015-12.92-2.348-2.923-3.909a28.376 28.376 0 0 1 14.777-10.758zm-19.928 26.587 4.672-1.448 11.833 5.7-6.226 11.557-4.621 1.574a28.124 28.124 0 0 1 -5.669-16.955c0-.144.009-.285.011-.428zm8.119 20.241 3.938-1.34a1.752 1.752 0 0 0 .978-.828l5.49-10.195a12.3 12.3 0 0 0 5.652 4.113l-8 8.368a1.757 1.757 0 0 0 -.485 1.185l-.057 4.169a28.406 28.406 0 0 1 -7.516-5.472zm11 6.931.068-4.892 9.071-9.494 9.072 9.494.068 4.892a28.153 28.153 0 0 1 -18.28 0zm21.76-1.459-.058-4.169a1.747 1.747 0 0 0 -.484-1.185l-8-8.368a12.3 12.3 0 0 0 5.652-4.113l5.491 10.195a1.75 1.75 0 0 0 .977.828l3.939 1.34a28.427 28.427 0 0 1 -7.518 5.472zm9.985-8.33-4.626-1.574-6.227-11.562 11.833-5.695 4.673 1.448c0 .143.01.284.01.428a28.118 28.118 0 0 1 -5.664 16.955z"
            />
          </g>
        </g>
      </svg>
      { showText && t('loading') }
    </VStack>
  );
};

export default WheelLoader;
