import { Offer } from '../../../../../../../graphql';
import VStack from '../../../../../../ui-kit/layout/VStack.tsx';
import OfferCard from '../../../../cards/OfferCard.tsx';

interface Props {
  offers: Offer[],
  onSaved?: () => void,
}

const OffersListTab = ({ offers, onSaved }: Props) => (
  <VStack mt={2}>
    {
      offers.map((offer) => <OfferCard offer={offer} onSaved={onSaved} key={offer.id} />)
    }
  </VStack>
);

export default OffersListTab;
