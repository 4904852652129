export const filters = {
  kilometers: [
    { label: String(10000), value: 10000 },
    { label: String(20000), value: 20000 },
    { label: String(30000), value: 30000 },
    { label: String(40000), value: 40000 },
    { label: String(50000), value: 50000 },
    { label: String(60000), value: 60000 },
    { label: String(70000), value: 70000 },
    { label: String(80000), value: 80000 },
    { label: String(120000), value: 120000 },
    { label: String(150000), value: 150000 },
    { label: String(200000), value: 200000 },
    { label: String(250000), value: 250000 },
  ],
  power: [
    { label: String(40), value: 40 },
    { label: String(50), value: 50 },
    { label: String(60), value: 60 },
    { label: String(70), value: 70 },
    { label: String(80), value: 80 },
    { label: String(90), value: 90 },
    { label: String(100), value: 100 },
    { label: String(110), value: 110 },
    { label: String(120), value: 120 },
    { label: String(130), value: 130 },
    { label: String(140), value: 140 },
    { label: String(150), value: 150 },
    { label: String(200), value: 200 },
    { label: String(250), value: 250 },
    { label: String(300), value: 300 },
    { label: String(400), value: 400 },
  ],
  engineCapacity: [
    { label: '1.0 l', value: 1000 },
    { label: '1.3 l', value: 1300 },
    { label: '1.6 l', value: 1600 },
    { label: '1.8 l', value: 1800 },
    { label: '2.0 l', value: 2000 },
    { label: '2.3 l', value: 2300 },
    { label: '3.0 l', value: 3000 },
    { label: '4.0 l', value: 4000 },
    { label: '5.0 l', value: 5000 },
  ],
};
