import dayjs from 'dayjs';
import { ReactNode, useEffect, useState } from 'react';
import { Notification, notificationsFeedVar, useNotificationFeedSubscription } from '../../graphql';

function showCountOfUnreadNotifications(unreadNotifications: Notification[]) {
  if (!unreadNotifications.length) {
    return '';
  }

  return unreadNotifications.length > 99 ? '(+99) ' : `(${unreadNotifications.length}) `;
}

interface Props {
  children: ReactNode,
}

const NotificationSubscriptionProvider = ({ children }: Props) => {
  const [ lastTimeSoundRan, setLastTimeSoundRan ] = useState<Date | null>(null);
  const [ initialized, setInitialized ] = useState(false);
  const { data } = useNotificationFeedSubscription();

  useEffect(() => {
    if (data) {
      notificationsFeedVar(data.notificationFeed);

      const unreadNotifications = data.notificationFeed.results.filter((notification) => notification.status === 'SENT');

      document.querySelector('title')!.innerHTML = `${showCountOfUnreadNotifications(unreadNotifications)}AutoConnect Solutions`;

      if (initialized && unreadNotifications.length && (!lastTimeSoundRan || dayjs().diff(lastTimeSoundRan.toISOString(), 'minutes') > 1)) {
        const audio = new Audio('/assets/sounds/notification.mp3');

        audio.volume = 0.3;
        audio.play();

        setLastTimeSoundRan(new Date());
      }

      if (unreadNotifications.length) {
        (document.querySelector('link[type="image/x-icon"]')! as HTMLLinkElement).href = '/assets/images/favicon-up.png';
      }

      setInitialized(true);
    }
  }, [ data ]);

  return <>{ children }</>;
};

export default NotificationSubscriptionProvider;
