import { useMemo } from 'react';
import { SaveDealerDto } from '../../../graphql';
import { isNotEmpty, isValidEmail } from '../../../helpers/validations.ts';

export default function useValidateSaveDealerDto(dto: SaveDealerDto|null) {
  return useMemo(
    () => dto
      && isNotEmpty(dto.name)
      && isNotEmpty(dto.email)
      && isValidEmail(dto.email)
      && isNotEmpty(dto.phoneNumber)
      && isNotEmpty(dto.vatNumber)
      && isNotEmpty(dto.iban),
    [ dto ],
  );
}
