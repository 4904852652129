import { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useReactiveUserConnected from '../../hooks/data/useReactiveUserConnected.ts';
import PageNotFound from '../PageNotFound.tsx';
import Unauthorized from '../Unauthorized.tsx';

interface Props {
  children: ReactNode,
}

const SellerGuard = ({ children }: Props) => {
  const { isConnected, isSeller } = useReactiveUserConnected();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isSeller) {
      navigate('/parameters/profile');
    }
  }, [ isSeller ]);

  if (!isConnected) {
    return <PageNotFound />;
  }

  if (!isSeller) {
    return <Unauthorized />;
  }

  return (
    <>
      { children }
    </>
  );
};

export default SellerGuard;
