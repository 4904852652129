import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion, AccordionDetails, AccordionSummary, Button, TextField, Typography,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dealer, SaveDealerDto, useSaveDealerLogoMutation, useSaveDealerMutation,
} from '../../../../../../graphql';
import useReactiveUserConnected from '../../../../../../hooks/data/useReactiveUserConnected.ts';
import useValidateSaveDealerDto from '../../../../../../hooks/data/validations/useValidateSaveDealerDto.ts';
import useFileUpload from '../../../../../../hooks/useFileUpload.ts';
import HStack from '../../../../../ui-kit/layout/HStack.tsx';
import VStack from '../../../../../ui-kit/layout/VStack.tsx';
import DroppableImageInput from '../../../../../ui-kit/utils/DroppableImageInput.tsx';

function createDto(dealer?: Dealer): SaveDealerDto {
  return {
    id: dealer?.id || null,
    name: dealer?.name || '',
    email: dealer?.email || '',
    phoneNumber: dealer?.phoneNumber || '',
    vatNumber: dealer?.vatNumber || '',
    iban: dealer?.iban || '',
    shorten: false,
  };
}

interface Props {
  onLoading: (loading: boolean) => void,
}

const DealerInformationAccordion = ({ onLoading }: Props) => {
  const { t } = useTranslation();
  const { dealer, refetchMe } = useReactiveUserConnected();
  const [ dto, setDto ] = useState<SaveDealerDto>(createDto());
  const [ logoToUpload, setLogoUpload ] = useState<File|null>(null);
  const [ isPushing, setIsPushing ] = useState(false);
  const isDtoValid = useValidateSaveDealerDto(dto);
  const canSave = useMemo(
    () => !isPushing && isDtoValid,
    [ isPushing, isDtoValid ],
  );
  const uploadFile = useFileUpload();
  const [ saveDealer ] = useSaveDealerMutation();
  const [ saveDealerLogo ] = useSaveDealerLogoMutation();

  const handleSave = async () => {
    setIsPushing(true);

    await saveDealer({ variables: { dto } });

    if (logoToUpload) {
      const file = await uploadFile(logoToUpload, `/dealers/logos/${dealer!.id}`, 150, 150);

      if (file) {
        await saveDealerLogo({ variables: { dto: { id: dealer!.id, logoFileId: file.id } } });
      }
    }

    await refetchMe();

    setIsPushing(false);
  };

  useEffect(() => {
    setDto(dealer ? createDto(dealer) : createDto());
  }, [ dealer ]);

  useEffect(() => {
    onLoading(isPushing);
  }, [ isPushing ]);

  return (
    <Accordion sx={{ maxWidth: 800 }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <HStack gap={1} alignItems="center">
          <EmojiTransportationIcon />
          {t('my-parameters.my-profile.my-company-information')}
        </HStack>
      </AccordionSummary>
      <AccordionDetails>
        <DroppableImageInput uri={dealer && dealer.logo ? dealer.logo.uri : null} onFileDropped={setLogoUpload} />

        <HStack className="form-container" gap={2} mt={5}>
          <VStack className="input-container" width="100%">
            <Typography fontWeight="bolder">
              {t('form.labels.company-name')}
              <span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              sx={{ width: '100%', mb: 2 }}
              value={dto.name}
              onChange={({ target: { value: name } }) => setDto({ ...dto, name })}
            />
          </VStack>
        </HStack>

        <HStack className="form-container" gap={2}>
          <VStack className="input-container" width="100%">
            <Typography fontWeight="bolder">
              {t('form.labels.company-email')}
              <span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              sx={{ width: '100%', mb: 2 }}
              value={dto.email}
              onChange={({ target: { value: email } }) => setDto({ ...dto, email: email.trim() })}
            />
          </VStack>
        </HStack>

        <HStack className="form-container" gap={2}>
          <VStack className="input-container" flex={1}>
            <Typography fontWeight="bolder">
              {t('form.labels.phone-number')}
              <span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              sx={{ width: '100%', mb: 2 }}
              value={dto.phoneNumber}
              onChange={({ target: { value: phoneNumber } }) => setDto({ ...dto, phoneNumber: phoneNumber.trim() })}
            />
          </VStack>
          <HStack flex={1} />
        </HStack>

        <HStack className="form-container" gap={2}>
          <VStack className="input-container" flex={1}>
            <Typography fontWeight="bolder">
              {t('form.labels.company-vat-number')}
              <span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              sx={{ width: '100%', mb: 2 }}
              value={dto.vatNumber}
              onChange={({ target: { value: vatNumber } }) => setDto({ ...dto, vatNumber: vatNumber.trim() })}
              placeholder={t('form.placeholders.vat-number')}
            />
          </VStack>
          <HStack flex={1} />
        </HStack>

        <HStack className="form-container" gap={2}>
          <VStack className="input-container" flex={1}>
            <Typography fontWeight="bolder">
              {t('form.labels.company-iban')}
              <span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              sx={{ width: '100%', mb: 2 }}
              value={dto.iban}
              onChange={({ target: { value: iban } }) => setDto({ ...dto, iban: iban.trim() })}
            />
          </VStack>
          <HStack flex={1} />
        </HStack>

        <HStack justifyContent="flex-end">
          <Button color="primary" variant="outlined" onClick={handleSave} disabled={!canSave}>{t('buttons.save')}</Button>
        </HStack>
      </AccordionDetails>
    </Accordion>
  );
};

export default DealerInformationAccordion;
