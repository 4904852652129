import { useEffect, useRef, useState } from 'react';
import {
  GetMyPaginatedVehiclesDto,
  GetPaginatedOpenedVehiclesDto,
  GetPaginatedPublishedVehiclesByDealerDto,
  GetPaginatedPublishedVehiclesDto,
  GetPaginatedVehiclesFromMyFavoritesDto,
  GetPaginatedVehiclesFromMyOffersDto,
  SortOrder,
  useGetMyOffersForVehiclesLazyQuery, useGetMyPaginatedVehiclesLazyQuery,
  useGetPaginatedOpenedVehiclesLazyQuery,
  useGetPaginatedPublishedVehiclesByDealerLazyQuery,
  useGetPaginatedPublishedVehiclesLazyQuery,
  useGetPaginatedVehiclesFromMyFavoritesLazyQuery,
  useGetPaginatedVehiclesFromMyOffersLazyQuery,
  Vehicle,
  VehicleFilterDto,
} from '../../graphql';
import { VehicleListTypes } from '../../index';
import usePagination from '../usePagination.ts';

export default function usePaginatedVehicles(listType: VehicleListTypes, size = 10) {
  const {
    total, setTotal, currentPage, setCurrentPage, numberPerPage,
  } = usePagination(size);
  const [ fetchPaginatedPublishedVehicles ] = useGetPaginatedPublishedVehiclesLazyQuery();
  const [ fetchPaginatedPublishedVehiclesByDealer ] = useGetPaginatedPublishedVehiclesByDealerLazyQuery();
  const [ fetchPaginatedVehiclesFromMyOffers ] = useGetPaginatedVehiclesFromMyOffersLazyQuery();
  const [ fetchPaginatedVehiclesFromMyFavorites ] = useGetPaginatedVehiclesFromMyFavoritesLazyQuery();
  const [ fetchPaginatedOpenedVehicles ] = useGetPaginatedOpenedVehiclesLazyQuery();
  const [ fetchMyOffersForVehicles, { data: offersData } ] = useGetMyOffersForVehiclesLazyQuery();
  const [ fetchMyPaginatedVehicles ] = useGetMyPaginatedVehiclesLazyQuery();
  const [ isLoading, setIsLoading ] = useState(true);
  const previousDto = useRef<Record<any, any>>();
  const [ dto, setDto ] = useState<Record<any, any>>({});
  const [ paginatedVehicles, setPaginatedVehicles ] = useState<Vehicle[]>([]);

  const fetchVehiclesFromPage = async (page: number, filters?: VehicleFilterDto[]) => {
    setIsLoading(true);

    let dataFetched: any = null;

    if (listType === 'marketplace') {
      const query = await fetchPaginatedPublishedVehicles({
        variables: {
          dto: {
            from: page * size, size, orderBy: { publishedAt: SortOrder.Desc }, ...(dto as GetPaginatedPublishedVehiclesDto), filters: filters ?? [],
          },
        },
      });

      dataFetched = query.data?.paginatedPublishedVehicles;
    }

    if (listType === 'my-offers') {
      const query = await fetchPaginatedVehiclesFromMyOffers({
        variables: {
          dto: {
            from: page * size, size, ...(dto as GetPaginatedVehiclesFromMyOffersDto), filters: filters ?? [],
          },
        },
      });

      dataFetched = query.data?.paginatedVehiclesFromMyOffers;
    }

    if (listType === 'my-favorites') {
      const query = await fetchPaginatedVehiclesFromMyFavorites({
        variables: {
          dto: {
            from: page * size, size, ...(dto as GetPaginatedVehiclesFromMyFavoritesDto), filters: filters ?? [],
          },
        },
      });

      dataFetched = query.data?.paginatedVehiclesFromMyFavorites;
    }

    if (listType === 'my-history') {
      const query = await fetchPaginatedOpenedVehicles({
        variables: {
          dto: {
            from: page * size, size, ...(dto as GetPaginatedOpenedVehiclesDto), filters: filters ?? [],
          },
        },
      });

      dataFetched = query.data?.paginatedOpenedVehicles;
    }

    if (listType === 'dealer') {
      const query = await fetchPaginatedPublishedVehiclesByDealer({
        variables: {
          dto: {
            from: page * size, size, ...(dto as GetPaginatedPublishedVehiclesByDealerDto), filters: filters ?? [],
          },
        },
      });

      dataFetched = query.data?.paginatedPublishedVehiclesByDealer;
    }

    if (listType === 'my-vehicles') {
      const query = await fetchMyPaginatedVehicles({
        variables: {
          dto: {
            from: page * size, size, ...(dto as GetMyPaginatedVehiclesDto), filters: filters ?? [],
          },
        },
      });

      dataFetched = query.data?.myPaginatedVehicles;
    }

    if (dataFetched) {
      setTotal(dataFetched.total);

      await fetchMyOffersForVehicles({
        variables: {
          vehicleIds: dataFetched.results.map((vehicle: Vehicle) => vehicle.id),
        },
      });

      setPaginatedVehicles(dataFetched.results);
    }

    setCurrentPage(page);
    setIsLoading(false);

    return dataFetched;
  };

  useEffect(() => {
    if (!previousDto.current) {
      previousDto.current = dto;
    }

    (async () => {
      await fetchVehiclesFromPage?.(currentPage);

      previousDto.current = dto;
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (JSON.stringify(dto) === JSON.stringify(previousDto.current)) {
        return;
      }

      await fetchVehiclesFromPage?.(currentPage);

      previousDto.current = dto;
    })();
  }, [ dto ]);

  return {
    paginatedVehicles,
    offers: (offersData?.myOffersForVehicles || []),
    loading: isLoading,
    currentPage,
    totalVehicles: total,
    totalPages: Math.ceil(total / numberPerPage),
    fetchVehiclesFromPage,
    fetchMyOffersForVehicles,
    setDto,
  };
}
