export default class CanvaPhotoEditor {
  isDrawing = false;

  private ctx: CanvasRenderingContext2D | null;

  private startX: number;

  private startY: number;

  private img: HTMLImageElement;

  private angle = 0;

  private lines: Array<{ startX: number, startY: number, endX: number, endY: number, }> = [];

  constructor(private readonly canva: HTMLCanvasElement, private readonly rotationButton: HTMLElement) {
    this.img = new Image();
    this.ctx = this.canva.getContext('2d');
    this.startX = 0;
    this.startY = 0;

    // Bind methods
    this.handleRotationButtonClick = this.handleRotationButtonClick.bind(this);
    this.handleCanvaMouseDown = this.handleCanvaMouseDown.bind(this);
    this.handleCanvaMouseMove = this.handleCanvaMouseMove.bind(this);
    this.handleCanvaMouseUp = this.handleCanvaMouseUp.bind(this);
    this.handleCanvaTouchStart = this.handleCanvaTouchStart.bind(this);
    this.handleCanvaTouchMove = this.handleCanvaTouchMove.bind(this);
    this.handleCanvaTouchEnd = this.handleCanvaTouchEnd.bind(this);
  }

  initialize(photoUrl: string) {
    this.ctx = this.canva.getContext('2d');

    this.rotationButton.addEventListener('click', this.handleRotationButtonClick);

    this.canva.addEventListener('mousedown', this.handleCanvaMouseDown);

    this.canva.addEventListener('mousemove', this.handleCanvaMouseMove);

    this.canva.addEventListener('mouseup', this.handleCanvaMouseUp);

    this.canva.addEventListener('touchstart', this.handleCanvaTouchStart);

    this.canva.addEventListener('touchmove', this.handleCanvaTouchMove);

    this.canva.addEventListener('touchend', this.handleCanvaTouchEnd);

    this.img.src = photoUrl;

    this.img.onload = () => {
      this.resizeCanvas();
      this.drawImage();
    };
  }

  unset() {
    this.rotationButton.removeEventListener('click', this.handleRotationButtonClick);

    this.canva.removeEventListener('mousedown', this.handleCanvaMouseDown);

    this.canva.removeEventListener('mousemove', this.handleCanvaMouseMove);

    this.canva.removeEventListener('mouseup', this.handleCanvaMouseUp);

    this.canva.removeEventListener('touchstart', this.handleCanvaTouchStart);

    this.canva.removeEventListener('touchmove', this.handleCanvaTouchMove);

    this.canva.removeEventListener('touchend', this.handleCanvaTouchEnd);
  }

  private drawLine(x: number, y: number) {
    const rect = this.canva.getBoundingClientRect();
    const scaleX = this.img.width / rect.width;
    const scaleY = this.img.height / rect.height;

    const startXOriginal = this.startX * scaleX;
    const startYOriginal = this.startY * scaleY;
    const endXOriginal = x * scaleX;
    const endYOriginal = y * scaleY;

    this.ctx!.beginPath();
    this.ctx!.moveTo(this.startX, this.startY);
    this.ctx!.lineTo(x, y);
    this.ctx!.strokeStyle = 'red';
    this.ctx!.lineWidth = 5;
    this.ctx!.stroke();

    this.lines.push({
      startX: startXOriginal,
      startY: startYOriginal,
      endX: endXOriginal,
      endY: endYOriginal,
    });

    this.startX = x;
    this.startY = y;
  }

  private resizeCanvas() {
    const scale = Math.min(this.canva.parentElement!.parentElement!.parentElement!.offsetWidth / this.img.width, window.innerHeight / this.img.height);
    const newWidth = this.img.width * scale;
    const newHeight = this.img.height * scale;

    this.canva.width = newWidth;
    this.canva.height = newHeight;
  }

  private drawImage() {
    this.ctx!.clearRect(0, 0, this.canva.width, this.canva.height);
    this.ctx!.save();
    this.ctx!.translate(this.canva.width / 2, this.canva.height / 2);
    this.ctx!.rotate((this.angle * Math.PI) / 180);

    const scale = Math.min(this.canva.width / this.img.width, this.canva.height / this.img.height);
    const newWidth = this.img.width * scale;
    const newHeight = this.img.height * scale;

    this.ctx!.drawImage(this.img, -newWidth / 2, -newHeight / 2, newWidth, newHeight);
    this.ctx!.restore();

    this.redrawLines();
  }

  private redrawLines() {
    this.ctx!.strokeStyle = 'red';
    this.ctx!.lineWidth = 5;

    // eslint-disable-next-line no-restricted-syntax
    for (const line of this.lines) {
      this.ctx!.beginPath();
      this.ctx!.moveTo(line.startX / (this.img.width * this.canva.width), line.startY / (this.img.height * this.canva.height));
      this.ctx!.lineTo(line.endX / (this.img.width * this.canva.width), line.endY / (this.img.height * this.canva.height));
      this.ctx!.stroke();
    }
  }

  private handleRotationButtonClick() {
    this.angle += 90;
    this.drawImage();
  }

  private handleCanvaMouseDown(e: MouseEvent) {
    this.isDrawing = true;
    this.startX = e.offsetX;
    this.startY = e.offsetY;
  }

  private handleCanvaMouseMove(e: MouseEvent) {
    if (!this.isDrawing) {
      return;
    }

    this.drawLine(e.offsetX, e.offsetY);
  }

  private handleCanvaMouseUp() {
    this.isDrawing = false;
  }

  private handleCanvaTouchStart(e: TouchEvent) {
    e.preventDefault();
    this.isDrawing = true;
    const touch = e.touches[0];
    const rect = this.canva.getBoundingClientRect();
    this.startX = touch.clientX - rect.left;
    this.startY = touch.clientY - rect.top;
  }

  private handleCanvaTouchMove(e: TouchEvent) {
    if (!this.isDrawing) {
      return;
    }

    e.preventDefault();
    const touch = e.touches[0];
    const rect = this.canva.getBoundingClientRect();
    const x = touch.clientX - rect.left;
    const y = touch.clientY - rect.top;
    this.drawLine(x, y);
  }

  private handleCanvaTouchEnd() {
    this.isDrawing = false;
  }

  exportImage(): Promise<Blob> {
    return new Promise((resolve, reject) => {
      const originalWidth = this.img.width;
      const originalHeight = this.img.height;
      const maxWidth = 1920;
      const scale = originalWidth > maxWidth ? maxWidth / originalWidth : 1;
      const newWidth = originalWidth * scale;
      const newHeight = originalHeight * scale;

      const exportCanvas = document.createElement('canvas');
      exportCanvas.width = newWidth;
      exportCanvas.height = newHeight;
      const exportCtx = exportCanvas.getContext('2d');

      if (!exportCtx) {
        reject(new Error('Failed to create export context'));
        return;
      }

      exportCtx.clearRect(0, 0, newWidth, newHeight);
      exportCtx.save();
      exportCtx.translate(newWidth / 2, newHeight / 2);
      exportCtx.rotate((this.angle * Math.PI) / 180);

      const exportScale = Math.min(newWidth / this.img.width, newHeight / this.img.height);
      const exportNewWidth = this.img.width * exportScale;
      const exportNewHeight = this.img.height * exportScale;

      exportCtx.drawImage(this.img, -exportNewWidth / 2, -exportNewHeight / 2, exportNewWidth, exportNewHeight);
      exportCtx.restore();

      exportCtx.strokeStyle = 'red';
      exportCtx.lineWidth = 5 * Math.min(newWidth / this.canva.width, newHeight / this.canva.height);

      // eslint-disable-next-line no-restricted-syntax
      for (const line of this.lines) {
        exportCtx.beginPath();
        exportCtx.moveTo(line.startX * exportScale, line.startY * exportScale);
        exportCtx.lineTo(line.endX * exportScale, line.endY * exportScale);
        exportCtx.stroke();
      }

      exportCanvas.toBlob((blob) => {
        if (blob) {
          resolve(blob);
        } else {
          reject(new Error('Failed to create blob'));
        }
      }, 'image/png');
    });
  }
}
