import { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useLocalStorage from '../../../hooks/useLocalStorage.ts';
import useLocationQueryParameters from '../../../hooks/useLocationQueryParameters.ts';
import i18n from '../../../i18n.ts';
import HStack from './HStack.tsx';
import VStack from './VStack.tsx';

interface Props {
  id?: string,
  children: ReactNode,
}

const PageOffline = ({ id, children }: Props) => {
  const { t } = useTranslation();
  const queryParameters = useLocationQueryParameters();
  const { get, set } = useLocalStorage();

  useEffect(() => {
    const lang = get('language', null);

    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, []);

  useEffect(() => {
    const lang = queryParameters.getQueryParamByKey('lang');

    if (lang) {
      i18n.changeLanguage(lang);
      set('language', lang);
    }
  }, [ queryParameters.parameters ]);

  return (
    <HStack id={id} position="relative" justifyContent="center" alignItems="center" height="100vh" width="100%">
      <VStack
        position="relative"
        flex={1}
        overflow="hidden"
        justifyContent="space-between"
        alignItems="flex-end"
        height="100%"
        className="left-side"
        zIndex={3}
        style={{ background: 'url(/assets/images/home.webp)', backgroundSize: 'cover', backgroundPositionX: '-930px' }}
      >
        <VStack position="absolute" zIndex={3} width="100%" alignItems="center" pt={10}>
          <h1
            className="chakra-pecht"
            style={{
              color: 'white',
              fontFamily: '"Chakra Petch", sans-serif; !important',
              fontSize: '5rem',
              margin: 0,
            }}
          >
            AUTOCONNECT
          </h1>
          <h2
            className="chakra-pecht"
            style={{
              color: 'white',
              fontFamily: '"Chakra Petch", sans-serif; !important',
              fontSize: '1.2rem',
              margin: 0,
            }}
          >
            {t('offline.h2')}
          </h2>
        </VStack>

        <div style={{
          zIndex: 2, width: '100%', height: '100%', background: 'black', opacity: 0.6,
        }}
        />
      </VStack>
      <VStack className="container" alignItems="center" justifyContent="center" p="2rem" flex={1} style={{ backgroundColor: 'white' }} height="100%">
        { children }
      </VStack>
    </HStack>
  );
};

export default PageOffline;
