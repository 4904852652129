import {
  Button, Dialog, TextField, Typography,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DealerContact, SaveDealerContactDto, useSaveDealerContactMutation } from '../../../../../../graphql';
import useReactiveUserConnected from '../../../../../../hooks/data/useReactiveUserConnected.ts';
import CloseButton from '../../../../../ui-kit/buttons/CloseButton.tsx';
import DialogTitle from '../../../../../ui-kit/dialog/DialogTitle.tsx';
import HStack from '../../../../../ui-kit/layout/HStack.tsx';
import VStack from '../../../../../ui-kit/layout/VStack.tsx';

function createDto(dealerContact?: DealerContact): SaveDealerContactDto {
  return {
    id: dealerContact?.id || null,
    email: dealerContact?.email || '',
    firstName: dealerContact?.firstName || '',
    lastName: dealerContact?.lastName || '',
    phoneNumber: dealerContact?.phoneNumber || '',
  };
}

interface Props {
  dealerContact?: DealerContact,
  onClose: () => void,
  onSaved: () => void,
}

const SaveDealerContactDialog = ({ dealerContact, onClose, onSaved }: Props) => {
  const { t } = useTranslation();
  const { refetchMe } = useReactiveUserConnected();
  const [ dto, setDto ] = useState<SaveDealerContactDto>(createDto());
  const [ isPushing, setIsPushing ] = useState(false);
  const canSave = useMemo(
    () => !isPushing
      && dto.email !== ''
      && dto.firstName !== ''
      && dto.lastName !== ''
      && dto.phoneNumber !== '',
    [ dto, isPushing ],
  );
  const [ saveDealerContact ] = useSaveDealerContactMutation();

  const handleSave = async () => {
    if (!canSave) {
      return;
    }

    setIsPushing(true);

    await saveDealerContact({ variables: { dto } });
    await refetchMe();

    setIsPushing(false);

    onSaved();
  };

  useEffect(() => {
    setDto(dealerContact ? createDto(dealerContact) : createDto());
  }, [ dealerContact ]);

  return (
    <Dialog
      id="save-dealer-contact-dialog"
      onClose={onClose}
      open
      // @ts-ignore
      maxWidth="md"
    >
      <VStack p={5} width={425}>
        <HStack justifyContent="space-between" alignItems="center">
          <DialogTitle text={t('my-parameters.my-profile.add-dealer-contact')} loading={false} />
          <CloseButton onClose={onClose} />
        </HStack>

        <HStack className="form-container" gap={2} mt={2}>
          <VStack flex={1}>
            <Typography fontWeight="bolder">
              {t('form.labels.lastname')}
              <span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              sx={{ width: '100%' }}
              value={dto.lastName}
              onChange={({ target: { value: lastName } }) => setDto({ ...dto, lastName })}
            />
          </VStack>
          <VStack flex={1}>
            <Typography fontWeight="bolder">
              {t('form.labels.firstname')}
              <span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              sx={{ width: '100%' }}
              value={dto.firstName}
              onChange={({ target: { value: firstName } }) => setDto({ ...dto, firstName })}
            />
          </VStack>
        </HStack>

        <HStack className="form-container" gap={2} mt={2}>
          <VStack flex={1}>
            <Typography fontWeight="bolder">
              {t('form.labels.email')}
              <span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              sx={{ width: '100%' }}
              value={dto.email}
              onChange={({ target: { value: email } }) => setDto({ ...dto, email: email.trim() })}
            />
          </VStack>
        </HStack>

        <HStack className="form-container" gap={2} mt={2}>
          <VStack flex={1}>
            <Typography fontWeight="bolder">
              {t('form.labels.phone-number')}
              <span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              sx={{ width: '100%' }}
              value={dto.phoneNumber}
              onChange={({ target: { value: phoneNumber } }) => setDto({ ...dto, phoneNumber: phoneNumber.trim() })}
            />
          </VStack>
          <VStack flex={1} />
        </HStack>

        <HStack justifyContent="flex-end">
          <Button color="primary" variant="outlined" onClick={handleSave} disabled={!canSave}>{t('buttons.save')}</Button>
        </HStack>
      </VStack>
    </Dialog>
  );
};

export default SaveDealerContactDialog;
