import DoneIcon from '@mui/icons-material/Done';
import LoopIcon from '@mui/icons-material/Loop';
import { Button } from '@mui/material';
import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import CanvaPhotoEditor from '../../../../../../helpers/canva-photo-editor.ts';
import HStack from '../../../../../ui-kit/layout/HStack.tsx';
import VStack from '../../../../../ui-kit/layout/VStack.tsx';

interface Props {
  index: number|null,
  photoURL: string,
  originalWidth: number,
  originalHeight: number,
  shouldValidate?: boolean,
  disableValidate: boolean,
  onValidate: (blob: Blob, uri: string) => void,
}

const PhotoEditor = ({
  index, photoURL, originalWidth, originalHeight, shouldValidate, disableValidate = false, onValidate,
}: Props) => {
  const { t } = useTranslation();
  const [ hasDrawn, setHasDrawn ] = useState(false);
  const [ hasRotatedImage, setHasRotatedImage ] = useState(false);
  const canvasRef = useRef<any>(null);
  const rotationButton = useRef<any>(false);
  const [ canvaPhotoEditor, setCanvaPhotoEditor ] = useState<CanvaPhotoEditor|null>(null);

  useEffect(() => {
    const editor = new CanvaPhotoEditor(canvasRef.current, rotationButton.current);

    editor.initialize(photoURL);

    setCanvaPhotoEditor(editor);

    const handleOnCanvaMouseMove = () => {
      if (editor.isDrawing) {
        setHasDrawn(true);
      }
    };

    canvasRef.current.addEventListener('mousemove', handleOnCanvaMouseMove);

    return () => {
      editor.unset();
    };
  }, []);

  const validate = useCallback(async () => {
    if (!canvaPhotoEditor) {
      return;
    }

    const image = await canvaPhotoEditor.exportImage();

    onValidate(image, URL.createObjectURL(image));
  }, [ canvaPhotoEditor ]);

  return (
    <VStack>
      <div style={{
        position: 'relative',
        width: '100%',
        maxWidth: '600px',
        maxHeight: `${originalHeight / (originalWidth / 600)}px`,
      }}
      >
        <Button
          ref={rotationButton}
          sx={{
            position: 'absolute',
            top: 5,
            right: 5,
            zIndex: 5000,
          }}
          variant="contained"
          color="secondary"
          size="small"
          onClick={(event) => {
            event.stopPropagation();
            setHasRotatedImage(true);
          }}
        >
          <LoopIcon />
        </Button>

        <canvas
          ref={canvasRef}
          style={{
            width: '100%',
            height: '100%',
          }}
        />
      </div>
      <HStack justifyContent="flex-end" mt={2}>
        {
          (shouldValidate === true || index === null || index === -1 || hasDrawn || hasRotatedImage) && !disableValidate && (
            <Button onClick={validate}>
              <DoneIcon />
              {' '}
              { t('buttons.validate-photo') }
            </Button>
          )
        }
      </HStack>
    </VStack>
  );
};

export default PhotoEditor;
