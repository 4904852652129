import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  Attribute,
  Offer,
  OfferType,
  useFinalizeVehicleSaleMutation, useGetVehicleSaleOrderFormLazyQuery,
  usePublishVehicleMutation,
  useRepublishVehicleMutation,
  Vehicle,
} from '../../../../../graphql';
import usePaginatedVehicles from '../../../../../hooks/data/usePaginatedVehicles.ts';
import useReactiveAttributes from '../../../../../hooks/data/useReactiveAttributes.ts';
import useReactiveUserConnected from '../../../../../hooks/data/useReactiveUserConnected.ts';
import useLocalStorage from '../../../../../hooks/useLocalStorage.ts';
import useUiToast from '../../../../../hooks/useUiToast.ts';
import { VehicleListTypes } from '../../../../../index';
import VStack from '../../../../ui-kit/layout/VStack.tsx';
import CancelSaleDialog from './dialog-cancel-sale/CancelSaleDialog.tsx';
import FinalizeSaleDialog
  from './dialog-finalize-sale/FinalizeSaleDialog.tsx';
import ManagerOffersDialog from './dialog-manage-offers/ManagerOffersDialog.tsx';
import ConfirmVehiclePublishingDialog
  from './dialog-publishing-confirmation/ConfirmVehiclePublishingDialog.tsx';
import Filters from './filtering/Filters.tsx';
import Pagination from '../Pagination.tsx';
import MakeOfferDialog from './dialog-make-offer/MakeOfferDialog.tsx';
import VehicleDetailsDialog from './dialog-vehicle-details/VehicleDetailsDialog.tsx';
import VehiclesListItem from './VehiclesListItem.tsx';

interface Props {
  listType: VehicleListTypes,
  dataFetchingDto: Record<any, any>,
  filterFetchedData?: (vehicles: Vehicle[]) => Vehicle[],
  onLoading: (loading: boolean) => void,
  onFetchedTotal?: (total: number) => void,
}

const VehiclesList = ({
  listType, dataFetchingDto, onLoading, onFetchedTotal = undefined, filterFetchedData = (vehicles: Vehicle[]) => vehicles,
}: Props) => {
  const { t } = useTranslation();
  const { vehicleId } = useParams<{ vehicleId: string|undefined, }>();
  const [ isMakeOfferDialogOpened, setIsMakeOfferDialogOpened ] = useState(false);
  const [ isVehicleDetailsDialogOpened, setIsVehicleDetailsDialogOpened ] = useState(false);
  const [ isPublishingConfirmationDialogOpened, setIsPublishingConfirmationDialogOpened ] = useState(false);
  const [ isOffersDialogOpened, setIsOffersDialogOpened ] = useState(false);
  const [ isRatingDialogOpened, setIsRatingDialogOpened ] = useState(false);
  const [ isCancelVehicleDialogOpened, setIsCancelVehicleDialogOpened ] = useState(false);
  const [ isFinalizeVehicleSaleDialogOpened, setIsFinalizeVehicleSaleDialogOpened ] = useState(false);
  const [ selectedVehicleForAction, setSelectedVehicleForAction ] = useState<Vehicle|null>(null);
  const [ selectedOfferType, setSelectedOfferType ] = useState<OfferType|null>(null);
  const [ filters, setFilters ] = useState<any[]>([]);
  const { refetchMe } = useReactiveUserConnected();
  const { success } = useUiToast();
  const { getObject } = useLocalStorage();
  const { attributes, findAttributesByInternalCodes } = useReactiveAttributes();
  const {
    paginatedVehicles, fetchVehiclesFromPage, loading, offers, currentPage, totalPages, totalVehicles, fetchMyOffersForVehicles, setDto,
  } = usePaginatedVehicles(listType);
  const attributesShownWithinList = useMemo(
    () => {
      const internalCodes = [ 'fuelType', 'firstRegistration', 'kilometers', 'transmission' ];
      const filteredAttributes = findAttributesByInternalCodes(internalCodes);

      return internalCodes.map((internalCode) => filteredAttributes.find((attribute) => attribute.internalCode === internalCode));
    },
    [ attributes ],
  );
  const shouldShowFinalizeVehicleSaleDialog = useMemo(
    () => (getObject('preferences').showFinalizeVehicleSaleDialog === undefined || getObject('preferences').showFinalizeVehicleSaleDialog === true),
    [ attributes, isFinalizeVehicleSaleDialogOpened ],
  );
  const vehicles = useMemo(() => filterFetchedData(paginatedVehicles), [ paginatedVehicles ]);
  const [ finalizeVehicle ] = useFinalizeVehicleSaleMutation();
  const [ publishVehicle ] = usePublishVehicleMutation();
  const [ republishVehicle ] = useRepublishVehicleMutation();
  const [ fetchOrderFormUrl ] = useGetVehicleSaleOrderFormLazyQuery();
  // const [ fetchOrderFormUrl ] = useGetVehicBy();

  const finalizeSale = async (vehicle: Vehicle) => {
    await finalizeVehicle({ variables: { vehicleId: vehicle.id } });

    fetchVehiclesFromPage(currentPage);
    refetchMe();

    success(t('toasts.sale-finalized'));
  };

  const handlePublish = async () => {
    if (!selectedVehicleForAction) {
      return;
    }

    if (selectedVehicleForAction.status === 'EXPIRED') {
      await republishVehicle({ variables: { id: Number(selectedVehicleForAction.id) } });
    } else {
      await publishVehicle({ variables: { id: Number(selectedVehicleForAction.id) } });
    }

    setSelectedVehicleForAction(null);
    setIsPublishingConfirmationDialogOpened(false);

    success(t('toasts.your-vehicle-has-been-published'));

    await fetchVehiclesFromPage(currentPage);
    await refetchMe();
  };

  useEffect(() => {
    if (vehicleId) {
      setIsVehicleDetailsDialogOpened(true);
      setSelectedVehicleForAction({ id: Number(vehicleId) } as Vehicle);
    }
  }, [ vehicleId ]);

  useEffect(() => {
    setDto(dataFetchingDto);
  }, [ dataFetchingDto ]);

  useEffect(() => {
    onLoading(loading);
  }, [ loading ]);

  useEffect(() => {
    onFetchedTotal?.(totalVehicles);
  }, [ totalVehicles ]);

  return (
    <div id="vehicles-list">
      <Filters onFiltersChange={(changedFilters) => {
        fetchVehiclesFromPage(0, changedFilters);
        setFilters(changedFilters);
      }}
      />

      {
        !loading && totalVehicles > 0 && (
          <VStack mt={5}>
            {
              vehicles.map((vehicle) => (
                <VehiclesListItem
                  attributesShownWithinList={attributesShownWithinList as Attribute[]}
                  listType={listType}
                  vehicle={vehicle as Vehicle}
                  offers={offers as Offer[]}
                  onClick={async (action, selectedVehicle) => {
                    if (action === 'makeOffer' || action === 'purchase') {
                      setIsMakeOfferDialogOpened(true);
                      setSelectedOfferType(action === 'makeOffer' ? OfferType.Normal : OfferType.ImmediatePurchase);
                    }

                    if (action === 'openVehicleDetails') {
                      setIsVehicleDetailsDialogOpened(true);
                    }

                    if (action === 'publish' || action === 'republish') {
                      setIsPublishingConfirmationDialogOpened(true);
                    }

                    if (action === 'manageOffers') {
                      setIsOffersDialogOpened(true);
                    }

                    if (action === 'rate') {
                      setIsRatingDialogOpened(true);
                    }

                    if (action === 'cancelSale') {
                      setIsCancelVehicleDialogOpened(true);
                    }

                    if (action === 'finalizeSale') {
                      if (shouldShowFinalizeVehicleSaleDialog) {
                        setIsFinalizeVehicleSaleDialogOpened(true);
                      }

                      if (!shouldShowFinalizeVehicleSaleDialog) {
                        finalizeSale(selectedVehicle);
                      }
                    }

                    if (action === 'openOrderForm') {
                      const { data } = await fetchOrderFormUrl({ variables: { id: vehicle.id } });

                      if (data) {
                        const link = document.createElement('a');

                        link.id = 'test';
                        link.target = '_blank';
                        link.href = data.vehicleSaleOrderForm;

                        // @warning this is used as trick for safari
                        setTimeout(() => {
                          link.click();
                          link.remove();
                        }, 0);
                      }

                      return;
                    }

                    setSelectedVehicleForAction(selectedVehicle);
                  }}
                  onSavedFavorite={() => fetchVehiclesFromPage(currentPage)}
                  key={vehicle.id}
                />
              ))
            }

            { vehicles.length > 0 && <Pagination currentPage={(currentPage + 1)} totalPages={totalPages} onNavigate={(page) => fetchVehiclesFromPage(page - 1, filters)} /> }
          </VStack>
        )
      }

      {
        isMakeOfferDialogOpened && selectedVehicleForAction && selectedOfferType && (
          <MakeOfferDialog
            vehicle={selectedVehicleForAction}
            type={selectedOfferType}
            onClose={() => {
              setIsMakeOfferDialogOpened(false);
              setSelectedVehicleForAction(null);
              setSelectedOfferType(null);
            }}
            onSaved={async () => {
              const type = selectedOfferType;

              setIsMakeOfferDialogOpened(false);
              setSelectedVehicleForAction(null);
              setSelectedOfferType(null);

              if (type === OfferType.ImmediatePurchase) {
                await fetchVehiclesFromPage(currentPage);
              }

              await fetchMyOffersForVehicles({
                variables: {
                  vehicleIds: paginatedVehicles.map((vehicle) => vehicle.id),
                },
              });

              success(type === OfferType.ImmediatePurchase ? 'Félicitations, vous venez d\'acheter un véhicule !' : 'Votre offre a bien été enregistrée');

              refetchMe();
            }}
          />
        )
      }

      {
        isVehicleDetailsDialogOpened && selectedVehicleForAction && (
          <VehicleDetailsDialog vehicle={selectedVehicleForAction} onClose={() => setIsVehicleDetailsDialogOpened(false)} />
        )
      }

      {
        isPublishingConfirmationDialogOpened && (
          <ConfirmVehiclePublishingDialog
            onClose={() => setIsPublishingConfirmationDialogOpened(false)}
            onConfirm={handlePublish}
          />
        )
      }

      {
        (isOffersDialogOpened || isRatingDialogOpened) && selectedVehicleForAction && (
          <ManagerOffersDialog
            ratingEnabled={isRatingDialogOpened}
            vehicle={selectedVehicleForAction}
            onClose={() => {
              setIsOffersDialogOpened(false);
              setIsRatingDialogOpened(false);
            }}
            onSaved={() => {
              fetchVehiclesFromPage(currentPage);
              refetchMe();
            }}
          />
        )
      }

      {
        isFinalizeVehicleSaleDialogOpened
        && selectedVehicleForAction
        && shouldShowFinalizeVehicleSaleDialog
        && (
          <FinalizeSaleDialog
            vehicle={selectedVehicleForAction}
            onClose={() => setIsFinalizeVehicleSaleDialogOpened(false)}
            onSaved={() => {
              fetchVehiclesFromPage(currentPage);
              refetchMe();
            }}
          />
        )
      }

      {
        isCancelVehicleDialogOpened && selectedVehicleForAction && (
          <CancelSaleDialog
            vehicle={selectedVehicleForAction}
            onClose={() => setIsCancelVehicleDialogOpened(false)}
            onSaved={() => {
              fetchVehiclesFromPage(currentPage);
              refetchMe();
            }}
          />
        )
      }
    </div>
  );
};

export default VehiclesList;
